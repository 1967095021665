import { setup } from '@gothub-team/got-react';
import { store as reduxStore } from '../redux/store';
import { BASE_STATE } from './consts';
import { API_DOMAIN } from '../common/config';

export const {
    useGraph: createGraph,
    store: gotStore,
    api: gotApi,
} = setup({
    host: `https://${API_DOMAIN}`,
    reduxStore,
    baseState: BASE_STATE,
});
