import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { GotView } from '../Common/GotView';
import { propComponents } from '../ViewComponents/PropComponents';
import { listComponents } from '../ViewComponents/ListComponents';
import { fileComponents } from '../ViewComponents/FileComponents';
import { viewLinkComponents } from '../ViewComponents/ViewLinkComponent';
import { MAIN, VIEW_SCREEN, VIEW_STANDALONE } from '../../got/consts';
import { createGraph } from '../../got/hooks.config';
import { isGraphEmpty, useSelectGraph } from '../../got/got-adjunct';

const createViewNodeView = viewNodeId => viewNodeId
    ? {
        [viewNodeId]: {
            as: 'viewNode',
            include: { node: true },
        },
    }
    : {};

const components = {
    ...propComponents,
    ...listComponents,
    ...fileComponents,
    ...viewLinkComponents,
};

const getComponent = type => R.propOr(null, type, components);

export const GotViewScreenStandalone = () => {
    const { viewNodeId, rootNodeId } = useParams();

    if (!viewNodeId || !rootNodeId) {
        // TODO navigate or display error screen
        return null;
    }

    const graphName = `${VIEW_STANDALONE}-${viewNodeId}-${rootNodeId}`;

    return (
        <GotViewScreen
            stack={[MAIN, graphName]}
            viewNodeId={viewNodeId}
            rootNodeId={rootNodeId}
        />
    );
};

export const GotViewScreen = ({
    stack: parentStack = [MAIN], viewNodeId, rootNodeId,
}) => {
    const stack = R.append(VIEW_SCREEN, parentStack);
    const { pull, useView, push } = createGraph(...stack);
    const viewNodeView = createViewNodeView(viewNodeId);
    const rootNodeView = createViewNodeView(rootNodeId);
    useEffect(() => {
        viewNodeId && pull(viewNodeView);
        rootNodeId && pull(rootNodeView);
    }, [viewNodeId]);
    const { viewNode } = useView(viewNodeView);

    const view = R.pathOr({}, ['node', 'view'], viewNode);

    const diffGraph = useSelectGraph(...R.tail(stack));
    const hasChanges = !isGraphEmpty(diffGraph);

    return (
        <div className="got-view-screen">
            {rootNodeId && (
                <>
                    {hasChanges && (
                        <button
                            tooltip="save"
                            flow="down"
                            className="save-button"
                            onClick={async () => {
                                const { uploads } = await push();
                                await uploads.start();
                            }}
                        >
                            save
                            <ion-icon className="flyout-icons" name="save-outline" />
                        </button>

                    )}
                    <GotView
                        stack={stack}
                        view={view}
                        viewId="gotView"
                        nodeId={rootNodeId}
                        getComponent={getComponent}
                    />
                    <div className="bottom-spacer" />
                </>
            )}
        </div>
    );
};
