import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { gotApi, gotStore } from '../got/hooks.config';
import { initUserNodes } from '../hooks/useUserNode';

export const useAuth = () => {
    const [initializing, setInitializing] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const asyncFn = async () => {
            setLoggedIn(!!gotApi.getCurrentUser());
            await gotApi.refreshSession()
                .then(async () => {
                    await initUserNodes();
                    setLoggedIn(true);
                    setInitializing(false);
                })
                .catch(() => {
                    setLoggedIn(false);
                    setInitializing(false);
                });
        };

        asyncFn();
    }, []);

    const login = async (email, password) => gotApi.login({ email, password }).then(async () => {
        gotStore.clearAll();
        console.log('initializing user nodes');
        await initUserNodes();
        setLoggedIn(true);
        navigate('/graph');
    });

    const register = (email, password) => gotApi.registerInit({ email, password });
    const activate = async (email, verificationCode) => gotApi.registerVerify({ email, verificationCode });
    const resendActivationCode = email => gotApi.registerVerifyResend({ email });

    const forgotPassword = email => gotApi.resetPasswordInit({ email });
    const confirmPassword = (email, verificationCode, password) => gotApi.resetPasswordVerify({ email, password, verificationCode });

    const logout = async () => {
        setLoggedIn(false);
        gotApi.logout();
        gotStore.clearAll();
    };

    const authActions = {
        login,
        logout,
        register,
        activate,
        resendActivationCode,
        forgotPassword,
        confirmPassword,
    };

    return [initializing, loggedIn, authActions];
};
