import React from 'react';
import { Icon } from '../Common/Icon';

export const UploadVideo = ({ url, setFile }) => (
    <div className="view-file fileupload">
        {url
            ? (
                <>
                    <Icon>attach_file</Icon>
                    There already is a video uploaded
                </>
            )
            : (
                <input
                    type="file"
                    accept="video/mp4"
                    onChange={e => {
                        if (e.target.files.length > 0) {
                            const file = e.target.files[0];
                            file && setFile(file.name, file);
                        }
                    }}
                />
            )}
    </div>
);

export const Video = ({ url }) => (
    <div className="view-file video">
        {url && (
            <video style={{ backgroundColor: 'black' }} width="100%" controls autoPlay={false} playsInline>
                <source src={url} />
                Your browser does not support the video tag.
            </video>
        )}
    </div>
);

export const fileComponents = {
    video: Video,
    uploadVideo: UploadVideo,
};
