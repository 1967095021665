import {
    createStore,
    combineReducers,
    // applyMiddleware,
} from 'redux';
// import logger from 'redux-logger';
import { gotReducer } from '@gothub-team/got-react';
import { BASE_STATE } from '../got/consts';
import { modalsReducer } from './modals';
import { toastsReducer } from './toasts';

const rootReducer = combineReducers({
    [BASE_STATE]: gotReducer,
    modals: modalsReducer,
    toasts: toastsReducer,
});

export const store = createStore(
    rootReducer,
    // applyMiddleware(
    //     logger,
    // ),
);
