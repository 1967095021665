import React, { useEffect, useRef } from 'react';
import { BookmarkPills } from './BookmarkPills';
import { LayerSelection } from './LayerSelection';
import { HotBar } from './HotBar';
import { SearchBar } from './SearchBar';
import { BookmarkModal } from './BookmarkModal';

export const GraphControls = ({ stack, settings, setSettings, options }) => {
    const _settings = useRef();
    _settings.current = settings;

    useEffect(() => {
        const listener = e => {
            if (e.key === 'Escape' && _settings.current.action) {
                setSettings({ ..._settings.current, action: null });
            }
        };

        document.addEventListener('keyup', listener);

        return () => {
            document.removeEventListener('keyup', listener);
        };
    }, []);

    return (
        <div id="graph-controls-container" className="flex text-sm">
            <SearchBar
                stack={stack}
                settings={settings}
                setSettings={setSettings}
                options={options}
            />
            <BookmarkPills />
            <LayerSelection settings={settings} setSettings={setSettings} />
            <HotBar settings={settings} setSettings={setSettings} edgeTypes={options.edgeTypes} />
            <BookmarkModal />
        </div>
    );
};
