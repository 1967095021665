import React, { useCallback } from 'react';
import * as R from 'ramda';
import { initComponent } from './common';
import { useAtom } from './hooks';
import { AddIcon, CancelIcon, EditModeIcon } from './Icons';
import { ComponentDragging, EditMode, EditPath, ViewState } from './state';
import { newId } from '../../../util/util';
import { useOpenModal } from '../../../hooks/useModal';
import { BOOKMARK_MODAL } from '../../../got/consts';
import { useViewFns } from '../../../hooks/useViews';

export const SidebarItem = ({ children, onClick, onDragStart, onDragEnd }) => (
    <div
        onClick={onClick}
        draggable={!!onDragStart}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        className="aspect-w-1 aspect-h-1 w-full cursor-pointer"
    >
        <div className="flex items-center justify-center">{children}</div>
    </div>
);

export const Tile = ({ children, className }) => (
    <div
        className={`border-1 flex h-full w-full flex-col items-center justify-center rounded-2xl border border-got-highlight-second-shaded-intense text-center text-xs dark:text-got-highlight-second-light ${className}`}
    >
        {children}
    </div>
);

const Divider = ({ children }) => (
    <div className="flex w-full flex-row items-center">
        <div className="my-auto ml-1 h-[1px] w-full bg-got-highlight-second-light" />
        <div className="mx-1 text-sm">{children}</div>
        <div className="my-auto mr-1 h-[1px] w-full bg-got-highlight-second-light" />
    </div>
);

export const SideBar = ({ editMode, components = [] }) => {
    const propItems = components.filter(component => !component.init.edge);
    const listItems = components.filter(component => component.init.edge);

    const editPath = useAtom(EditPath) || [];
    const activeType = useAtom(ViewState, useCallback(R.path([...editPath, 'type']), [editPath]));

    const renderRow = ({ label, init, Icon }) => (
        <SidebarItem
            key={init.type}
            onDragStart={e => {
                ComponentDragging.set(true);
                const data = JSON.stringify(initComponent(init));
                e.dataTransfer.setData('component', data);
            }}
            onDragEnd={() => ComponentDragging.set(false)}
        >
            <Tile className={activeType === init.type ? 'bg-got-highlight-second-shaded' : ''}>
                {Icon && <Icon className="h-8 w-8 stroke-[1] opacity-30" />}
                <div>{label}</div>
            </Tile>
        </SidebarItem>
    );

    const openBookmarkModal = useOpenModal(BOOKMARK_MODAL);
    const { updateView } = useViewFns();

    if (editMode) {
        return (
            <div className="scrollbar flex w-24 flex-col gap-3 overflow-y-scroll p-2 dark:text-got-highlight-second-light">
                <SidebarItem>
                    <CancelIcon
                        onClick={() => {
                            EditMode.set(false);
                            updateView();
                            console.log('update view');
                        }}
                    />
                </SidebarItem>
                <Divider>Fields</Divider>
                {propItems.map(renderRow)}
                <Divider>Lists</Divider>
                {listItems.map(renderRow)}
            </div>
        );
    }

    return (
        <div className="w-24">
            <SidebarItem>
                <EditModeIcon onClick={() => EditMode.set(true)} />
            </SidebarItem>
            <SidebarItem>
                <AddIcon
                    onClick={() => {
                        openBookmarkModal({
                            type: 'view',
                            data: newId(),
                            title: 'Add View',
                            placeholder: 'View name',
                        });
                    }}
                />
            </SidebarItem>
        </div>
    );
};
