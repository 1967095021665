import React from 'react';
import { Link } from 'react-router-dom';

export const FootNotes = ({
    loggedIn, login, logout, userEmail,
}) => (
    <header className="foot-notes">
        <Link className="logo" to="/">
            Powered by GotHub
        </Link>
        {!loggedIn && login && (
        <div className="user-info">
            <button onClick={login}>
                <span className="material-icons-outlined">
                    login
                </span>
            </button>
        </div>
        )}
        {userEmail && loggedIn && logout && (
        <div className="user-info">
            <button onClick={() => { logout && logout(); }}>
                {`Logout ${userEmail}`}
            </button>
        </div>
        )}
    </header>
);
