import React, { useState } from 'react';
import * as R from 'ramda';
import { sortEnvelopesBy } from '../../util/util';
import { Icon } from '../Common/Icon';

const sortNodes = (nodes, view) => R.ifElse(
    R.always(R.has('sortBy', view)),
    sortEnvelopesBy(R.prop('sortBy', view)),
    R.always(nodes),
)(nodes);

export const List = ({
    view, nodes, addNode, deleteNode, Item,
}) => (
    <div className="view-list edit-list">
        {view.label && <h2 className="list-label">{view.label}</h2>}
        <div className="list" style={{ ...R.propOr({}, 'listStyle', view) }}>
            {R.map(nodeBag => (
                <div
                    className="list-item"
                    style={{ ...R.propOr({}, 'listItemStyle', view) }}
                    key={nodeBag.nodeId}
                >
                    <Item node={nodeBag.node} />
                    <button
                        className="delete-button error"
                        tooltip="Delete Node"
                        flow="down"
                        onClick={() => deleteNode(nodeBag.node)}
                    >
                        <ion-icon class="flyout-icons" name="trash-outline" />

                    </button>
                </div>
            ))(sortNodes(nodes, view))}
            <button
                className="add-button success"
                tooltip="Add Node"
                flow="down"
                onClick={() => addNode()}
            >
                <ion-icon className="flyout-icons" name="add-circle-outline" />
            </button>
        </div>
    </div>
);

export const ViewOnlyList = ({
    view, nodes, Item,
}) => (
    <div className="view-list view-only">
        {view.label && <h1 className="list-label">{view.label}</h1>}
        <div className="list" style={{ ...R.propOr({}, 'listStyle', view) }}>
            {R.map(nodeBag => (
                <ViewOnlyListComponent
                    key={nodeBag.nodeId}
                    view={view}
                    nodeBag={nodeBag}
                    Item={Item}
                />
            ))(sortNodes(nodes, view))}
        </div>
    </div>
);

const ViewOnlyListComponent = ({ view, nodeBag, Item }) => {
    const collapsable = R.length(R.pathOr([], ['items', 'components'], view)) > 1;
    const [collapsed, setCollapsed] = useState(collapsable);
    return (
        <div
            className="list-item"
            style={{ ...R.propOr({}, 'listItemStyle', view) }}
        >
            {collapsable && (
            <button className={`collapse-button ${collapsed ? 'collapsed' : 'open'}`} onClick={() => setCollapsed(!collapsed)}>
                <Icon>{ collapsed ? 'unfold_more' : 'unfold_less'}</Icon>
            </button>
            )}
            <Item node={nodeBag.node} collapsed={collapsed} />
        </div>
    );
};

// export const UnorderedList = ({
//     view, nodes, addNode, deleteNode, Item,
// }) => (
//     <div className="view-list list">
//         <button
//             className="add-button"
//             onClick={() => addNode()}
//         >
//             <Icon>add</Icon>
//         </button>
//         <ul className="list" style={{ ...R.propOr({}, 'listStyle', view) }}>
//             {R.map(nodeBag => (
//                 <li
//                     className="list-item"
//                     style={{ ...R.propOr({}, 'listItemStyle', view) }}
//                     key={nodeBag.body.id}
//                 >
//                     <Item node={nodeBag.body} />
//                     <button
//                         className="delete-button"
//                         onClick={() => deleteNode(nodeBag.body)}
//                     >
//                         <Icon>delete_forever</Icon>
//                     </button>
//                 </li>
//             ))(sortNodes(nodes, view))}
//         </ul>
//     </div>
// );

// export const OrderedList = ({
//     view, nodes, addNode, deleteNode, Item,
// }) => (
//     <div className="view-list list">
//         <button
//             className="add-button"
//             onClick={() => addNode()}
//         >
//             <Icon>add</Icon>
//         </button>
//         <ol className="list" style={{ ...R.propOr({}, 'listStyle', view) }}>
//             {R.map(nodeBag => (
//                 <li
//                     className="list-item"
//                     style={{ ...R.propOr({}, 'listItemStyle', view) }}
//                     key={nodeBag.body.id}
//                 >
//                     <Item node={nodeBag.body} />
//                     <button
//                         className="delete-button"
//                         onClick={() => deleteNode(nodeBag.body)}
//                     >
//                         <Icon>delete_forever</Icon>
//                     </button>
//                 </li>
//             ))(sortNodes(nodes, view))}
//         </ol>
//     </div>
// );

export const listComponents = {
    list: List,
    // simplelist: List,
    viewList: ViewOnlyList,
};
