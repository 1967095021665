/* eslint-disable no-use-before-define,no-param-reassign, no-prototype-builtins */
import * as R from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { ForceGraph3D } from 'react-force-graph';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import * as uuid from 'uuid';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import '../../Graph.css';
import SpriteText from 'three-spritetext';
import { CSS2DRenderer } from '../../util/GraphRenderer';
import { defaultNodeGeometry } from '../../util/ThreeUtil';
import { useGraphData } from '../../hooks/useGraphData';
import { ActiveScope, HoveredNode } from '../Screens/ViewScreen/state';
import { useAtom } from '../Screens/ViewScreen/hooks';
import { renderNodeCard } from './NodeCard';
import { getUserScopesId } from '../../hooks/useUserNodeId';

function animate(callback) {
    function loop(time) {
        callback(time);
        requestAnimationFrame(loop);
    }
    requestAnimationFrame(loop);
}
animate(time => {
    TWEEN.update(time);
});
const animateCamera = (
    camera,
    controls,
    toPosition,
    lookAtPosition,
    easing = TWEEN.Easing.Quadratic.InOut,
) => {
    const coords = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
    const currentTargetCoords = {
        x: controls.target.x,
        y: controls.target.y,
        z: controls.target.z,
    };
    const targetCoords = { x: lookAtPosition.x, y: lookAtPosition.y, z: lookAtPosition.z };
    new TWEEN.Tween(currentTargetCoords)
        .to(targetCoords)
        .easing(easing)
        .onUpdate(() => {
            controls.target.set(
                currentTargetCoords.x,
                currentTargetCoords.y,
                currentTargetCoords.z,
            );
        })
        .start();
    new TWEEN.Tween(coords)
        .to({ x: toPosition.x, y: toPosition.y, z: toPosition.z })
        .easing(easing)
        .onUpdate(() => {
            camera.position.set(coords.x, coords.y, coords.z);
        })
        .start();
};

export const GraphView3d = ({
    graph,
    colorMap,
    settings = {},
    setSettings,
    nodeProps,
    sourceNode,
    setSourceNode,
    deleteNode,
    addNode,
    assoc,
    height,
    width,
    viewOnly = false,
    defaultCameraPosition = { x: 0, y: 0, z: 150 },
    defaultLookAtPosition = { x: 0, y: 0, z: 0 },
}) => {
    const graphData = useGraphData(graph);

    const [displayHeight] = useState(window.innerHeight);
    const [displayWidth] = useState(window.innerWidth);

    // states for highlighting
    const [focusNodeIds, setFocusNodeIds] = useState(new Set());
    const hoveredNode = useAtom(HoveredNode);

    const twoDRenderer = new CSS2DRenderer();
    const extraRenderers = [twoDRenderer];
    const fgRef = useRef();

    // states for bloom pass
    const [bloomPass, setBloomPass] = useState(null);
    const addBloomPass = () => {
        if (!bloomPass) {
            const _bloomPass = new UnrealBloomPass();
            _bloomPass.strength = 0.3;
            _bloomPass.radius = 0.001;
            _bloomPass.threshold = 0.15;
            _bloomPass.exposure = 2;
            // add bloom pass
            fgRef.current.postProcessingComposer().addPass(_bloomPass);
            setBloomPass(_bloomPass);
        }
    };
    const removeBloomPass = () => {
        if (bloomPass) {
            fgRef.current.postProcessingComposer().removePass(bloomPass);
            setBloomPass(null);
        }
    };

    useEffect(() => {
        settings.enableBloom ? addBloomPass() : removeBloomPass();
    }, [settings.enableBloom]);

    const resetCamera = () => {
        if (!fgRef.current) return;
        animateCamera(
            fgRef.current.camera(),
            fgRef.current.controls(),
            defaultCameraPosition,
            defaultLookAtPosition,
        );
    };

    useEffect(() => {
        if (settings.fitGraph) {
            setSettings({ ...settings, fitGraph: false });
            resetCamera();
        }
    }, [settings.fitGraph]);

    // run on startup
    useEffect(() => {
        fgRef.current.d3Force('link').distance(20);
        if (viewOnly) {
            fgRef.current.controls().noZoom = true;
        }
        resetCamera();

        // const keyListener = e => {
        //     if (e.key === 'r') {
        //         resetCamera();
        //     }
        //     if (e.key === 'c') {
        //         // log sth
        //     }
        // };

        // document.addEventListener('keyup', keyListener);

        return () => {
            // document.removeEventListener('keyup', keyListener);
        };
    }, []);

    const generateLinkObject = link => {
        if (settings.showLinkLabels) {
            const group = new THREE.Mesh();
            const labelSprite = text => {
                const sprite = new SpriteText(text);
                sprite.color = 'rgb(107 114 128)'; // gray-200
                sprite.textHeight = 1;
                return sprite;
            };
            const labelMesh = new THREE.Mesh();
            labelMesh.add(labelSprite(`${link.fromType} > ${link.toType}`));
            labelMesh.add(labelSprite(`${link.toType} < ${link.fromType}`));
            group.addEventListener('removed', removeChildren);
            link.labelMesh = labelMesh;
            rotateLabels({ start: link.source, end: link.target }, labelMesh.children);
            positionLabels({ start: link.source, end: link.target }, labelMesh);
            group.add(labelMesh);
            return group;
        }
        return null;
    };

    const handleLinkClick = (link, event) => {
        if (event.target.localName !== 'canvas') return;

        let newSelectedNode;
        if (focusNodeIds.has(link.target.id)) {
            newSelectedNode = link.source;
        } else {
            newSelectedNode = link.target;
        }
        const selectedNodes = focusNodeIds;
        selectedNodes.clear();
        selectedNodes.add(newSelectedNode.id);
        setFocusNodeIds(selectedNodes);
        zoomToNode(newSelectedNode);
        fgRef.current.refresh();
    };

    const rotateLabel = (label, dx, dy) => {
        const { material } = label;
        label.material.rotation = Math.atan2(dy, dx);
        Object.assign(label.material, material);
        label.visible = true;
    };

    const rotateLabels = ({ start, end }, [parentChildLabel, childParentLabel]) => {
        if (!fgRef.current || !parentChildLabel || !childParentLabel) return;
        const startTwoD = fgRef.current.graph2ScreenCoords(start.x, start.y, start.z);
        const endTwoD = fgRef.current.graph2ScreenCoords(end.x, end.y, end.z);
        const deltaX = endTwoD.x - startTwoD.x;
        const deltaY = endTwoD.y - startTwoD.y;
        if (deltaX > 0) {
            rotateLabel(parentChildLabel, deltaX, -deltaY);
            childParentLabel.visible = false;
        } else {
            rotateLabel(childParentLabel, -deltaX, deltaY);
            parentChildLabel.visible = false;
        }
    };

    const positionLabels = ({ start, end }, labelMesh) => {
        const middlePos = Object.assign(
            ...['x', 'y', 'z'].map(c => ({
                [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
            })),
        );

        Object.assign(labelMesh.position, middlePos);
    };

    const labelTicking = useRef(false);
    const linkData = useRef(graphData.links);
    useEffect(() => {
        linkData.current = graphData.links;
        if (!linkData.current || labelTicking.current) return;
        labelTicking.current = true;
        animate(() => handleAnimationFrame());
    }, [graphData]);
    const handleAnimationFrame = () => {
        linkData.current.forEach(({ labelMesh, source: start, target: end }) => {
            if (!labelMesh || !labelMesh.children) return;

            rotateLabels({ start, end }, labelMesh.children);
            positionLabels({ start, end }, labelMesh);
        });
    };
    /* #endregion Link */

    /* #region Node */

    const generateNodeObject = node => {
        // TODO move default mesh with colors to external file
        const container = new THREE.Mesh();
        let color = node.id === hoveredNode ? '#309eff' : R.propOr('#1553B7', node.id, colorMap);
        if (sourceNode && sourceNode.id === node.id) color = '#fde863';

        const nodeSphere = new THREE.Mesh(
            defaultNodeGeometry,
            new THREE.MeshStandardMaterial({ color }),
        );
        container.add(nodeSphere);

        if (focusNodeIds.has(node.id)) {
            const nodeDisplayKeys =
                settings.nodeDisplayKeys && settings.nodeDisplayKeys.length > 0
                    ? settings.nodeDisplayKeys
                    : nodeProps;
            container.add(
                renderNodeCard(node, nodeDisplayKeys, {
                    unselect: () => {
                        const selectedNodes = focusNodeIds;
                        selectedNodes.delete(node.id);
                        setFocusNodeIds(selectedNodes);
                        zoomFromNode(node);
                        fgRef.current.refresh();
                    },
                    delete: () => {
                        const selectedNodes = focusNodeIds;
                        selectedNodes.delete(node.id);
                        setFocusNodeIds(selectedNodes);
                        zoomFromNode(node);
                        fgRef.current.refresh();
                        deleteNode && deleteNode(node);
                    },
                }),
            );
        }

        return container;
    };

    const handleNodeClick = (node, event) => {
        if (event.srcElement.localName !== 'canvas') return;
        console.log(event);
        if (settings.action === 'add') {
            const id = settings.nodeId || uuid.v4();

            const edge = event.altKey
                ? {
                      fromType: settings.fromType,
                      fromId: id,
                      toType: settings.toType,
                      toId: node.id,
                  }
                : {
                      fromType: settings.fromType,
                      fromId: node.id,
                      toType: settings.toType,
                      toId: id,
                  };

            if (id && id !== ActiveScope.get()) {
                addNode({ id }, edge);
                setSettings({ ...settings, action: null });
            }
        } else if (settings.action === 'connect') {
            if (!sourceNode) {
                // selecting sourceNode
                setSourceNode(node);
            } else if (sourceNode === node) {
                setSourceNode(undefined);
            } else {
                setSourceNode(undefined);
                assoc({
                    fromType: settings.fromType,
                    fromId: sourceNode.id,
                    toType: settings.toType,
                    toId: node.id,
                });
                setSettings({ ...settings, action: null });
            }
        } else if (settings.action === 'scope') {
            const scopeId = ActiveScope.get();
            const userScopeId = getUserScopesId();

            if (!scopeId || !userScopeId) return;

            addNode({ id: scopeId }, { fromType: 'scopes', fromId: userScopeId, toType: 'scope' });
            setSettings({ ...settings, action: null });
        } else {
            const selectedNodes = focusNodeIds;
            if (event.ctrlKey || event.shiftKey || event.altKey) {
                selectedNodes.has(node.id)
                    ? selectedNodes.delete(node.id)
                    : selectedNodes.add(node.id);
                setFocusNodeIds(selectedNodes);
            } else {
                if (!selectedNodes.has(node.id)) {
                    selectedNodes.clear();
                    selectedNodes.add(node.id);
                    setFocusNodeIds(selectedNodes);
                }
                zoomToNode(node);
            }

            fgRef.current.refresh();
        }
    };

    const zoomToNode = node => {
        animateCamera(
            fgRef.current.camera(),
            fgRef.current.controls(),
            { x: node.x, y: node.y, z: node.z + 15 },
            { x: node.x, y: node.y, z: node.z },
            TWEEN.Easing.Quadratic.Out,
        );
    };

    const zoomFromNode = node => {
        animateCamera(
            fgRef.current.camera(),
            fgRef.current.controls(),
            { x: node.x, y: node.y, z: node.z + 100 },
            { x: node.x, y: node.y, z: node.z },
            TWEEN.Easing.Quadratic.Out,
        );
    };
    /* #endregion */

    /* #region helpers  */

    // removes all children from threejs mesh
    const removeChildren = event => {
        const object = event.target;
        // eslint-disable-next-line no-restricted-syntax
        for (const children of object.children) {
            object.remove(children);
        }
    };

    const getNodeLabel = ({ id }) => {
        const node = graph.nodes[id];
        if (settings.labelKey && node && node[settings.labelKey]) {
            return `${settings.labelKey}: ${node[settings.labelKey]}`;
        }

        return `${settings.labelKey} undefined - id: ${id}`;
    };
    /* #endregion */

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <ForceGraph3D
            controlType="trackball"
            height={height || displayHeight}
            width={width || displayWidth}
            ref={fgRef}
            graphData={graphData}
            extraRenderers={extraRenderers}
            backgroundColor="#171b21"
            cooldownTicks={settings.enableForce ? Infinity : 0}
            nodeLabel={getNodeLabel}
            // link
            linkWidth={0}
            linkDirectionalParticleWidth={settings.enableParticles ? 0.5 : 0}
            linkDirectionalParticles={settings.enableParticles ? 4 : 0}
            linkDirectionalParticleSpeed={settings.enableParticles ? 0.002 : 0}
            linkDirectionalParticleColor="#212121"
            linkThreeObjectExtend
            linkThreeObject={link => generateLinkObject(link)}
            onLinkClick={
                !viewOnly
                    ? (link, { source, target }) => handleLinkClick(link, { source, target })
                    : null
            }
            // node
            nodeThreeObject={node => generateNodeObject(node)}
            onNodeClick={!viewOnly ? (node, event) => handleNodeClick(node, event) : null}
            // onEngineStop={fgRef.current ? fgRef.current.zoomToFit(400) : console.log('no ref')}
            showNavInfo={!viewOnly}
            enableNodeDrag={!viewOnly}
        />
    );
};
