import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export const UserInfo = ({ loggedIn, logout }) => {
    const location = useLocation().pathname;
    return (
        <div className="user-info">
            <Link to="/home" className={location === '/home' ? 'underline' : ''}>
                Home
            </Link>
            <Link to="/developer" className={location === '/developer' ? 'underline' : ''}>
                Developer
            </Link>
            {!loggedIn && (
            <Link to="/signin">
                <ion-icon name="log-in-outline" />
            </Link>
            )}
            {loggedIn && (
                <>
                    <Link to="/graph">
                        Graph
                    </Link>
                    <button onClick={logout}>
                        <ion-icon name="log-out-outline" />
                    </button>
                </>
            )}
        </div>
    );
};
