import React from 'react';

const Icon =
    SvgContent =>
    ({ className, onClick, size = '44' }) =>
        (
            <svg
                onClick={onClick}
                xmlns="http://www.w3.org/2000/svg"
                className={`icon ${className}`}
                width={size}
                height={size}
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <SvgContent />
            </svg>
        );

const EditSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
        <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
    </>
);

const AddSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 12l6 0" />
        <path d="M12 9l0 6" />
        <path d="M4 6v-1a1 1 0 0 1 1 -1h1m5 0h2m5 0h1a1 1 0 0 1 1 1v1m0 5v2m0 5v1a1 1 0 0 1 -1 1h-1m-5 0h-2m-5 0h-1a1 1 0 0 1 -1 -1v-1m0 -5v-2m0 -5" />
    </>
);

const ReadSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M8 4h-2l-3 10v2.5" />
        <path d="M16 4h2l3 10v2.5" />
        <line x1="10" y1="16" x2="14" y2="16" />
        <circle cx="17.5" cy="16.5" r="3.5" />
        <circle cx="6.5" cy="16.5" r="3.5" />
    </>
);

const CancelSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
    </>
);

const EditModeSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
        <line x1="16" y1="5" x2="19" y2="8" />
    </>
);

const DeleteSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="4" y1="7" x2="20" y2="7" />
        <line x1="10" y1="11" x2="10" y2="17" />
        <line x1="14" y1="11" x2="14" y2="17" />
        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
    </>
);

const CollapseSvg = () => (
    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
);

const ArrowRightSvg = () => (
    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5" />
);

const AddCircleSvg = () => (
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
);

const RemoveCircleSvg = () => (
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
    />
);

const PaperclipSvg = () => (
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
    />
);

const DownloadSvg = () => (
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
    />
);

const ListSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M13 5h8" />
        <path d="M13 9h5" />
        <path d="M13 15h8" />
        <path d="M13 19h5" />
        <rect x="3" y="4" width="6" height="6" rx="1" />
        <rect x="3" y="14" width="6" height="6" rx="1" />
    </>
);

const UploadSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
        <path d="M12 11v6" />
        <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
    </>
);

const ToggleSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="8" cy="12" r="2" />
        <rect x="2" y="6" width="20" height="12" rx="6" />
    </>
);

const NumberSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 10l2 -2v8" />
        <path d="M9 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3" />
        <path d="M17 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5" />
    </>
);

const TextAreaSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="4" y1="6" x2="20" y2="6" />
        <line x1="4" y1="12" x2="20" y2="12" />
        <line x1="4" y1="18" x2="16" y2="18" />
    </>
);

const TextSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="6" y1="4" x2="18" y2="4" />
        <line x1="12" y1="4" x2="12" y2="20" />
    </>
);

const HeadingSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M7 12h10" />
        <path d="M7 5v14" />
        <path d="M17 5v14" />
        <path d="M15 19h4" />
        <path d="M15 5h4" />
        <path d="M5 19h4" />
        <path d="M5 5h4" />
    </>
);

const BookmarkSvg = () => (
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z"
    />
);

const SettingsSvg = () => (
    <>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
        />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </>
);

const SearchSvg = () => (
    <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
    />
);

const EyeSvg = () => (
    <>
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
        />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </>
);

const NodeSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r=".5" fill="currentColor" />
        <circle cx="12" cy="12" r="9" />
    </>
);

const CheckSvg = () => (
    <>
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 12l5 5l10 -10" />
    </>
);

export const EditIcon = Icon(EditSvg);
export const AddIcon = Icon(AddSvg);
export const ReadIcon = Icon(ReadSvg);
export const CancelIcon = Icon(CancelSvg);
export const EditModeIcon = Icon(EditModeSvg);
export const DeleteIcon = Icon(DeleteSvg);
export const CollapseIcon = Icon(CollapseSvg);
export const ArrowRightIcon = Icon(ArrowRightSvg);
export const AddCircleIcon = Icon(AddCircleSvg);
export const RemoveCircleIcon = Icon(RemoveCircleSvg);
export const PaperclipIcon = Icon(PaperclipSvg);
export const DownloadIcon = Icon(DownloadSvg);
export const ListIcon = Icon(ListSvg);
export const UploadIcon = Icon(UploadSvg);
export const ToggleIcon = Icon(ToggleSvg);
export const NumberIcon = Icon(NumberSvg);
export const TextAreaIcon = Icon(TextAreaSvg);
export const TextIcon = Icon(TextSvg);
export const HeadingIcon = Icon(HeadingSvg);
export const BookmarkIcon = Icon(BookmarkSvg);
export const SettingsIcon = Icon(SettingsSvg);
export const SearchIcon = Icon(SearchSvg);
export const EyeIcon = Icon(EyeSvg);
export const NodeIcon = Icon(NodeSvg);
export const CheckIcon = Icon(CheckSvg);
