import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import hljs from 'highlight.js';
import { useLocalValue } from '../../hooks/useLocalValue';

/* #region input */

export const InputField = ({ value, setValue, view }) => {
    const [localValue, setLocalValue] = useLocalValue(value);
    return (
        <input
            className="view-prop inputfield"
            value={localValue || ''}
            onChange={e => setLocalValue(e.target.value)}
            onBlur={() => setValue(localValue)}
            placeholder={R.propOr('', 'label', view)}
        />
    );
};

export const TextArea = ({ value, setValue, view }) => {
    const [localValue, setLocalValue] = useLocalValue(value);
    return (
        <textarea
            className="view-prop textarea"
            value={localValue || ''}
            onChange={e => setLocalValue(e.target.value)}
            onBlur={() => setValue(localValue)}
            placeholder={R.propOr('', 'label', view)}
        />
    );
};

export const NumberField = ({ value, setValue, view }) => {
    const [localValue, setLocalValue] = useLocalValue(value);
    return (
        <input
            id="numberfield"
            className="view-prop numberfield"
            type="number"
            value={localValue || 0}
            onChange={e => setLocalValue(e.target.value)}
            onBlur={() => setValue(localValue)}
            placeholder={R.propOr('', 'label', view)}
        />
    );
};

export const Checkbox = ({ value, setValue, view }) => (
    <div className="view-prop checkbox">
        {view.label && <label>{view.label}</label>}
        <input
            type="checkbox"
            checked={RA.isTruthy(value)}
            onChange={e => setValue(e.target.checked)}
        />
    </div>
);
/* #endregion */

/* #region display */

export const Header = ({ value, view }) => {
    const header = headerType => {
        switch (headerType) {
            case 2:
            case '2':
                return <h3 className="view-prop header">{value}</h3>;
            case 3:
            case '3':
                return <h4 className="view-prop header">{value}</h4>;
            case 4:
            case '4':
                return <h5 className="view-prop header">{value}</h5>;
            case 5:
            case '5':
                return <h6 className="view-prop header">{value}</h6>;
            case 6:
            case '6':
                return <h5 className="view-prop header">{value}</h5>;
            case 1:
            case '1':
            default:
                return <h2 className="view-prop header">{value}</h2>;
        }
    };

    if (value) {
        return header(view.headerType);
    }

    return null;
};

export const Text = ({ value }) => {
    if (value) {
        return <div className="view-prop text">{value}</div>;
    }

    return null;
};

export const JsonText = ({ value }) => {
    // const [id] = useState(newId);
    const codeId = 'json-code';
    const [codeRef, setCodeRef] = useState(null);
    useEffect(() => {
        setCodeRef(document.getElementById(codeId));
    }, []);

    if (codeRef) {
        hljs.highlightElement(codeRef);
    }

    if (value && RA.isObj(value)) {
        return (
            <pre className="view-prop json">
                <code className="language-json" id={codeId}>
                    {JSON.stringify(value, null, 2)}
                </code>
            </pre>
        );
    }

    return null;
};

export const Image = ({ value }) => {
    if (value) {
        return <img className="view-prop image" src={value} alt="" />;
    }

    return null;
};

/* #endregion */

export const propComponents = {
    // prop inputs
    input: InputField,
    textArea: TextArea,
    numberField: NumberField,
    checkbox: Checkbox,
    // prop displays
    header: Header,
    text: Text,
    image: Image,
    json: JsonText,
};
