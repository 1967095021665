export const exampleGraph = {
    edges: {
        'recipeApp': {
            'recipeAppNode': {
                'recipe': {
                    '6c686d17-6b2c-48c0-9bae-9fd14f1600da': true,
                    '18b30d03-1f3c-4746-9f74-3d7bc2548c8c': true,
                    '483299d8-4f44-4152-931f-91cae83b080d': true,
                    '28beac9a-4e8d-4edc-9b11-ce78160c81bf': true,
                    'c81b20eb-a5ca-4530-88b1-b6cbfc3c5038': true,
                    '9e8a8d11-3201-41eb-82e5-3805606bed12': true,
                    '13074d33-63dd-4fe6-806e-ba7838512ba1': true,
                    'e73021dc-d20a-431d-848a-6369cc8f680b': true,
                    '9ccd5049-83ff-4d92-866f-0bc4b3582592': true,
                    'b37172f3-153a-44bb-bf63-09fb5bd9c7c4': true,
                },
            },
        },
        'recipe': {
            '6c686d17-6b2c-48c0-9bae-9fd14f1600da': {
                'ingredient': {
                    'c470d143-e82f-451e-af8a-5a7082391786': true,
                    '7536817a-7de2-4e89-abcc-64a26fa77b52': true,
                    'e068e5e3-71cf-4030-bcac-6b743d430e4a': true,
                    '048a84ca-5247-403a-b8cc-1f8336336a0e': true,
                    'eb0721d3-1740-4368-a02b-77856c50693c': true,
                    'd762ea8b-f362-4593-9100-fc92ab4fba3e': true,
                    '4e683888-8b25-4d7a-a8c7-d71e4d31e427': true,
                    '3a0c9c69-bab4-4b56-a467-bc967ec73c7c': true,
                    '4bb8c872-4f10-4de8-a8de-ee7da25ebe6d': true,
                    'ea3436d1-324c-4c0c-afee-bef020910612': true,
                    'f3311b41-3fc7-4199-b7c7-6e7623500af6': true,
                },
            },
            '18b30d03-1f3c-4746-9f74-3d7bc2548c8c': {
                'ingredient': {
                    '46822d5d-aa3c-44dc-a39a-b1a71355a4de': true,
                    '9b02b71a-2877-40a8-8f12-bd16ae4fa412': true,
                    'e889169a-6657-4013-9d08-bc1d7b01ccb2': true,
                    'e21cb79a-170d-4b22-8e75-688ce5baeb55': true,
                    '1985bba5-6ebc-4d2b-99f6-2a0c8e683a36': true,
                    '80bbf8ad-0118-4627-b329-ccd06baf1862': true,
                    '6a95949f-ec6d-4293-be4c-b8d4c7dabc1d': true,
                    '98028b76-b2c1-4c8c-b2f9-9e7e84dff4ba': true,
                    'bcea39f1-d15a-487e-8e39-70c609c0f5e7': true,
                    '1bfed7a0-a7a7-4be5-a6f8-0a0842673d45': true,
                    '72c3affe-de30-43ba-9c30-0420ea9a46ca': true,
                    'cd88166a-b53d-494b-abac-2ab878ce0a04': true,
                },
            },
            '483299d8-4f44-4152-931f-91cae83b080d': {
                'ingredient': {
                    '351a2c9e-d965-4def-ba49-fea90bdd827d': true,
                    'e4b0ef10-d3a1-47cf-8d5c-a957ec67f6fd': true,
                    '4f2f0ff8-9d4e-4d0e-b041-db1d9798de16': true,
                    '9bba0087-4384-424d-a689-856202d099b2': true,
                },
            },
            '28beac9a-4e8d-4edc-9b11-ce78160c81bf': {
                'ingredient': {
                    '21afd84f-1d98-48e3-a24d-605289a250e1': true,
                    '95ff67ff-bd91-441a-919a-42b74c3c55a9': true,
                    '96fbc442-7eb6-4a46-8df3-8ce846dda11b': true,
                    'f9fb4acd-4223-4dcc-a7e3-24f0d6fcd75c': true,
                    'cf2e8e0a-3747-43a6-a08f-107a0ef62843': true,
                    'bf3ec30d-0c9b-4bbc-8723-5cc04e93043b': true,
                },
            },
            'c81b20eb-a5ca-4530-88b1-b6cbfc3c5038': {
                'ingredient': {
                    '5b5aea65-6c67-40f2-a773-0d02ea1c5b26': true,
                    'c320633b-c2c1-4446-9e9d-00e047170416': true,
                    '5ee45480-3578-4936-a7a0-e053cf1ebbc8': true,
                    '08118834-c7e8-4b93-89c8-e33084189ccd': true,
                    'be6f7061-4ff3-4482-8306-69ba85d5d625': true,
                    'c5a96209-0386-40a8-bac1-f9b12d56d31b': true,
                },
            },
            '9e8a8d11-3201-41eb-82e5-3805606bed12': {
                'ingredient': {
                    '7245adfd-1fa2-4feb-826f-49db963bbefe': true,
                    'aae041c4-679c-43de-99e0-98920b133ad4': true,
                    '76c7a14f-4fa4-4053-a20b-ba1e48b3c234': true,
                    '1dbfa57f-870d-400d-9f1b-f743bf2d0f73': true,
                    'e8408910-2ca7-427c-b21d-c15e0e20f422': true,
                    '04cf8ac6-6d64-4e2f-ad0c-a6c7d1b93cc3': true,
                    '1e1924f8-dda6-4a5f-81b7-88e81dd8f361': true,
                    'c1b6556e-c3ff-4bbc-905c-199659e4e982': true,
                    'c908817d-048c-4945-9d24-cc6030f81295': true,
                    '8ed6bba4-c509-46e9-90e6-9f0b22dca93f': true,
                },
            },
            '13074d33-63dd-4fe6-806e-ba7838512ba1': {
                'ingredient': {
                    '7c66f4df-c662-4768-a0b2-1e1f5980d8bb': true,
                    '6b3ebcdb-d59b-4898-b308-a86e5a195862': true,
                    'd864afdd-6dd0-417b-893c-4d843ec0a67c': true,
                    '3abb91b5-2c21-4786-b62f-0c0bfee1995b': true,
                    'd588effd-a925-4e41-9076-c3f4c72e5b29': true,
                    '9e00b0e3-b034-4296-90c3-6a3e4022bed7': true,
                    '56ac00f2-eeac-40fa-bd88-44611d5b02e9': true,
                    '9748c948-4d2a-47d6-9292-51023fc47269': true,
                    'a0cb93a4-b157-4272-92fc-8c295fd0c36b': true,
                    'f3f1bd68-5606-4ace-b6ae-aab3c390eb9a': true,
                },
            },
            'e73021dc-d20a-431d-848a-6369cc8f680b': {
                'ingredient': {
                    '86d9b5a7-9b7d-41a9-bd51-667dceab06d5': true,
                    'c2952856-3357-457b-89bd-3be9d8e17f50': true,
                    'f828a43c-90a5-46bd-ae84-c0bf397d0313': true,
                    '8c33ed7a-890f-4133-81b9-ae5460c650d1': true,
                    '57e34d2e-03c9-45c2-af48-2492ecb1c05f': true,
                    '8ac6ef84-2920-4a57-95a0-a4a569ff0579': true,
                    '249039c2-057a-48fa-90f1-d4ca915d4609': true,
                    '487e6e8a-e244-4435-95ef-29b36a394bb8': true,
                    '8958028e-8496-4998-82f6-64e0ec03b378': true,
                },
            },
            '9ccd5049-83ff-4d92-866f-0bc4b3582592': {
                'ingredient': {
                    '9ca53d70-87f4-4a12-96f0-113161daea92': true,
                    'b0073323-3495-43a0-82bb-6467fd333b7a': true,
                    '446c3915-5721-4ade-9e86-cc2ecfebd60a': true,
                    '7113be73-0cba-4feb-913a-f5d31f35d73e': true,
                    '88055e2a-2e63-44a3-9189-3cd4371bc487': true,
                },
            },
            'b37172f3-153a-44bb-bf63-09fb5bd9c7c4': {
                'ingredient': {
                    '79986efa-3b21-410b-867b-291d9b419840': true,
                    '3b5154fa-a860-4aa3-aeaa-55239fe48458': true,
                    '7397e0b9-25db-48fa-9c0f-7ed870d70d3c': true,
                    '8ecf1d70-3dcd-4ed6-99f8-da45e19c0fdd': true,
                    'c7cd563c-dd1d-4968-a587-f2ccc2d0d83f': true,
                    'c5e73d08-1692-45f7-b4b2-197439be7f3b': true,
                    '07730639-03a5-469f-b0b8-5f534712303f': true,
                    'e848c087-80c5-4e38-803e-7628406e3b69': true,
                    '5b9d0e6a-ff71-4672-aaa4-ec2fd3f5361b': true,
                    '3e4299a9-c228-4c52-a7cb-f962a44d0fb5': true,
                    'ac70fd97-12aa-4c7d-b3e6-5de929c65655': true,
                },
            },
        },
    },
    nodes: {
        'recipeAppNode': { id: 'recipeAppNode', label: 'Recipe App' },
        '6c686d17-6b2c-48c0-9bae-9fd14f1600da': {
            id: '6c686d17-6b2c-48c0-9bae-9fd14f1600da',
            label: 'Chicken Vesuvio',
            image:
        'https://www.edamam.com/web-img/e42/e42f9119813e890af34c259785ae1cfb.jpg',
            source: 'Serious Eats',
            url:
        'http://www.seriouseats.com/recipes/2011/12/chicken-vesuvio-recipe.html',
            shareAs:
        'http://www.edamam.com/recipe/chicken-vesuvio-b79327d05b8e5b838ad6cfd9576b30b6/chicken',
            calories: 4228.043058200812,
            totalTime: 60,
        },
        'c470d143-e82f-451e-af8a-5a7082391786': {
            id: 'c470d143-e82f-451e-af8a-5a7082391786',
            text: '1/2 cup olive oil',
            wheight: 108,
            image: null,
        },
        '7536817a-7de2-4e89-abcc-64a26fa77b52': {
            id: '7536817a-7de2-4e89-abcc-64a26fa77b52',
            text: '5 cloves garlic, peeled',
            wheight: 15,
            image:
        'https://www.edamam.com/food-img/6ee/6ee142951f48aaf94f4312409f8d133d.jpg',
        },
        'e068e5e3-71cf-4030-bcac-6b743d430e4a': {
            id: 'e068e5e3-71cf-4030-bcac-6b743d430e4a',
            text: '2 large russet potatoes, peeled and cut into chunks',
            wheight: 738,
            image:
        'https://www.edamam.com/food-img/71b/71b3756ecfd3d1efa075874377038b67.jpg',
        },
        '048a84ca-5247-403a-b8cc-1f8336336a0e': {
            id: '048a84ca-5247-403a-b8cc-1f8336336a0e',
            text: '1 3-4 pound chicken, cut into 8 pieces (or 3 pound chicken legs)',
            wheight: 1587.5732950000001,
            image:
        'https://www.edamam.com/food-img/d33/d338229d774a743f7858f6764e095878.jpg',
        },
        'eb0721d3-1740-4368-a02b-77856c50693c': {
            id: 'eb0721d3-1740-4368-a02b-77856c50693c',
            text: '3/4 cup white wine',
            wheight: 176.39999999999998,
            image:
        'https://www.edamam.com/food-img/a71/a718cf3c52add522128929f1f324d2ab.jpg',
        },
        'd762ea8b-f362-4593-9100-fc92ab4fba3e': {
            id: 'd762ea8b-f362-4593-9100-fc92ab4fba3e',
            text: '3/4 cup chicken stock',
            wheight: 180,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
        '4e683888-8b25-4d7a-a8c7-d71e4d31e427': {
            id: '4e683888-8b25-4d7a-a8c7-d71e4d31e427',
            text: '3 tablespoons chopped parsley',
            wheight: 11.399999999999999,
            image:
        'https://www.edamam.com/food-img/46a/46a132e96626d7989b4d6ed8c91f4da0.jpg',
        },
        '3a0c9c69-bab4-4b56-a467-bc967ec73c7c': {
            id: '3a0c9c69-bab4-4b56-a467-bc967ec73c7c',
            text: '1 tablespoon dried oregano',
            wheight: 2.9999999997971143,
            image:
        'https://www.edamam.com/food-img/1b0/1b0eaffb1c261606e0d82fed8e9747a7.jpg',
        },
        '4bb8c872-4f10-4de8-a8de-ee7da25ebe6d': {
            id: '4bb8c872-4f10-4de8-a8de-ee7da25ebe6d',
            text: 'Salt and pepper',
            wheight: 17.720239769998784,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        'ea3436d1-324c-4c0c-afee-bef020910612': {
            id: 'ea3436d1-324c-4c0c-afee-bef020910612',
            text: 'Salt and pepper',
            wheight: 8.860119884999392,
            image:
        'https://www.edamam.com/food-img/c6e/c6e5c3bd8d3bc15175d9766971a4d1b2.jpg',
        },
        'f3311b41-3fc7-4199-b7c7-6e7623500af6': {
            id: 'f3311b41-3fc7-4199-b7c7-6e7623500af6',
            text: '1 cup frozen peas, thawed',
            wheight: 134,
            image:
        'https://www.edamam.com/food-img/c91/c9130a361d5c5b279bf48c69e2466ec2.jpg',
        },
        '18b30d03-1f3c-4746-9f74-3d7bc2548c8c': {
            id: '18b30d03-1f3c-4746-9f74-3d7bc2548c8c',
            label: 'Chicken Paprikash',
            image:
        'https://www.edamam.com/web-img/e12/e12b8c5581226d7639168f41d126f2ff.jpg',
            source: 'No Recipes',
            url: 'http://norecipes.com/recipe/chicken-paprikash/',
            shareAs:
        'http://www.edamam.com/recipe/chicken-paprikash-8275bb28647abcedef0baaf2dcf34f8b/chicken',
            calories: 3033.2012500008163,
            totalTime: 0,
        },
        '46822d5d-aa3c-44dc-a39a-b1a71355a4de': {
            id: '46822d5d-aa3c-44dc-a39a-b1a71355a4de',
            text:
        '640 grams chicken - drumsticks and thighs ( 3 whole chicken legs cut apart)',
            wheight: 640,
            image:
        'https://www.edamam.com/food-img/491/4916353c22bd1ac381ac81d55597ddbe.jpg',
        },
        '9b02b71a-2877-40a8-8f12-bd16ae4fa412': {
            id: '9b02b71a-2877-40a8-8f12-bd16ae4fa412',
            text:
        '640 grams chicken - drumsticks and thighs ( 3 whole chicken legs cut apart)',
            wheight: 640,
            image:
        'https://www.edamam.com/food-img/007/00792642367e1f55de680762f85cfb3b.jpg',
        },
        'e889169a-6657-4013-9d08-bc1d7b01ccb2': {
            id: 'e889169a-6657-4013-9d08-bc1d7b01ccb2',
            text: '1/2 teaspoon salt',
            wheight: 3,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        'e21cb79a-170d-4b22-8e75-688ce5baeb55': {
            id: 'e21cb79a-170d-4b22-8e75-688ce5baeb55',
            text: '1/4 teaspoon black pepper',
            wheight: 0.725,
            image:
        'https://www.edamam.com/food-img/c6e/c6e5c3bd8d3bc15175d9766971a4d1b2.jpg',
        },
        '1985bba5-6ebc-4d2b-99f6-2a0c8e683a36': {
            id: '1985bba5-6ebc-4d2b-99f6-2a0c8e683a36',
            text: '1 tablespoon butter – cultured unsalted (or olive oil)',
            wheight: 13.5,
            image: null,
        },
        '80bbf8ad-0118-4627-b329-ccd06baf1862': {
            id: '80bbf8ad-0118-4627-b329-ccd06baf1862',
            text: '240 grams onion sliced thin (1 large onion)',
            wheight: 78.125,
            image:
        'https://www.edamam.com/food-img/205/205e6bf2399b85d34741892ef91cc603.jpg',
        },
        '6a95949f-ec6d-4293-be4c-b8d4c7dabc1d': {
            id: '6a95949f-ec6d-4293-be4c-b8d4c7dabc1d',
            text: '70 grams Anaheim pepper chopped (1 large pepper)',
            wheight: 56.25,
            image:
        'https://www.edamam.com/food-img/73f/73ff2eeb21372fe15b0ec51f9ecf368d.jpeg',
        },
        '98028b76-b2c1-4c8c-b2f9-9e7e84dff4ba': {
            id: '98028b76-b2c1-4c8c-b2f9-9e7e84dff4ba',
            text: '25 grams paprika (about 1/4 cup)',
            wheight: 27.200000000459866,
            image:
        'https://www.edamam.com/food-img/474/474d63763b9d8b9da98c5f43a114648c.jpg',
        },
        'bcea39f1-d15a-487e-8e39-70c609c0f5e7': {
            id: 'bcea39f1-d15a-487e-8e39-70c609c0f5e7',
            text: '1 cup chicken stock',
            wheight: 240,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
        '1bfed7a0-a7a7-4be5-a6f8-0a0842673d45': {
            id: '1bfed7a0-a7a7-4be5-a6f8-0a0842673d45',
            text: '1/2 teaspoon salt',
            wheight: 3,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        '72c3affe-de30-43ba-9c30-0420ea9a46ca': {
            id: '72c3affe-de30-43ba-9c30-0420ea9a46ca',
            text: '1/2 cup sour cream',
            wheight: 115,
            image:
        'https://www.edamam.com/food-img/f9d/f9d6183267b041b0aff9a10b89c9c15f.jpg',
        },
        'cd88166a-b53d-494b-abac-2ab878ce0a04': {
            id: 'cd88166a-b53d-494b-abac-2ab878ce0a04',
            text: '1 tablespoon flour – all-purpose',
            wheight: 7.8124999998679145,
            image: null,
        },
        '483299d8-4f44-4152-931f-91cae83b080d': {
            id: '483299d8-4f44-4152-931f-91cae83b080d',
            label: 'Baked Chicken',
            image:
        'https://www.edamam.com/web-img/01c/01cacb70890274fb7b7cebb975a93231.jpg',
            source: 'Martha Stewart',
            url: 'http://www.marthastewart.com/318981/baked-chicken',
            shareAs:
        'http://www.edamam.com/recipe/baked-chicken-be3ba087e212f13672b553ecfa876333/chicken',
            calories: 901.58575,
            totalTime: 0,
        },
        '351a2c9e-d965-4def-ba49-fea90bdd827d': {
            id: '351a2c9e-d965-4def-ba49-fea90bdd827d',
            text:
        '6 bone-in chicken breast halves, or 6 chicken thighs and wings, skin-on',
            wheight: 522,
            image:
        'https://www.edamam.com/food-img/093/093749f4c93e448119fc81976d2c3067.jpg',
        },
        'e4b0ef10-d3a1-47cf-8d5c-a957ec67f6fd': {
            id: 'e4b0ef10-d3a1-47cf-8d5c-a957ec67f6fd',
            text: '1/2 teaspoon coarse salt',
            wheight: 2.4270833334564377,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        '4f2f0ff8-9d4e-4d0e-b041-db1d9798de16': {
            id: '4f2f0ff8-9d4e-4d0e-b041-db1d9798de16',
            text: '1/2 teaspoon Mrs. Dash seasoning',
            wheight: 0.75,
            image:
        'https://www.edamam.com/food-img/c23/c23e20823b442067307aa436969358f1.jpg',
        },
        '9bba0087-4384-424d-a689-856202d099b2': {
            id: '9bba0087-4384-424d-a689-856202d099b2',
            text: '1/4 teaspoon freshly ground black pepper',
            wheight: 0.575,
            image:
        'https://www.edamam.com/food-img/c6e/c6e5c3bd8d3bc15175d9766971a4d1b2.jpg',
        },
        '28beac9a-4e8d-4edc-9b11-ce78160c81bf': {
            id: '28beac9a-4e8d-4edc-9b11-ce78160c81bf',
            label: 'Chicken Feet Stock',
            image:
        'https://www.edamam.com/web-img/ae9/ae96650072cc599967d945c5e9961bb7.JPG',
            source: 'Food52',
            url: 'https://food52.com/recipes/3478-chicken-feet-stock',
            shareAs:
        'http://www.edamam.com/recipe/chicken-feet-stock-11f52d212cfd385f39a377b3f1549a0c/chicken',
            calories: 274.3604599999949,
            totalTime: 371,
        },
        '21afd84f-1d98-48e3-a24d-605289a250e1': {
            id: '21afd84f-1d98-48e3-a24d-605289a250e1',
            text: '1 chicken carcass, from a roasted chicken',
            wheight: 500,
            image:
        'https://www.edamam.com/food-img/1d7/1d785da8ce8dca92df262103058b05d1.jpg',
        },
        '95ff67ff-bd91-441a-919a-42b74c3c55a9': {
            id: '95ff67ff-bd91-441a-919a-42b74c3c55a9',
            text: '2 or more chicken feet',
            wheight: 2400,
            image:
        'https://www.edamam.com/food-img/d33/d338229d774a743f7858f6764e095878.jpg',
        },
        '96fbc442-7eb6-4a46-8df3-8ce846dda11b': {
            id: '96fbc442-7eb6-4a46-8df3-8ce846dda11b',
            text: '1 large yellow onion, quartered',
            wheight: 150,
            image:
        'https://www.edamam.com/food-img/205/205e6bf2399b85d34741892ef91cc603.jpg',
        },
        'f9fb4acd-4223-4dcc-a7e3-24f0d6fcd75c': {
            id: 'f9fb4acd-4223-4dcc-a7e3-24f0d6fcd75c',
            text: '4 green onions or 2 leeks',
            wheight: 60,
            image:
        'https://www.edamam.com/food-img/b89/b89986ed6aa466285bdd99bac34b3c46.jpg',
        },
        'cf2e8e0a-3747-43a6-a08f-107a0ef62843': {
            id: 'cf2e8e0a-3747-43a6-a08f-107a0ef62843',
            text: '1 tablespoon black peppercorns, whole',
            wheight: 6.9,
            image:
        'https://www.edamam.com/food-img/c6e/c6e5c3bd8d3bc15175d9766971a4d1b2.jpg',
        },
        'bf3ec30d-0c9b-4bbc-8723-5cc04e93043b': {
            id: 'bf3ec30d-0c9b-4bbc-8723-5cc04e93043b',
            text: '1 tablespoon crushed red pepper flakes',
            wheight: 2.312499999960903,
            image:
        'https://www.edamam.com/food-img/6cb/6cb8e4510251a322178f6e191b3a7b1b.jpeg',
        },
        'c81b20eb-a5ca-4530-88b1-b6cbfc3c5038': {
            id: 'c81b20eb-a5ca-4530-88b1-b6cbfc3c5038',
            label: 'Catalan Chicken',
            image:
        'https://www.edamam.com/web-img/4d9/4d9084cbc170789caa9e997108b595de.jpg',
            source: 'Bon Appetit',
            url:
        'http://www.bonappetit.com/columns/breadwinner/article/how-to-get-your-kids-to-eat-sauce-let-them-cook-it-themselves',
            shareAs:
        'http://www.edamam.com/recipe/catalan-chicken-2463f2482609d7a471dbbf3b268bd956/chicken',
            calories: 3900.8,
            totalTime: 0,
        },
        '5b5aea65-6c67-40f2-a773-0d02ea1c5b26': {
            id: '5b5aea65-6c67-40f2-a773-0d02ea1c5b26',
            text: '1 whole 4-pound chicken, quartered',
            wheight: 1200,
            image:
        'https://www.edamam.com/food-img/d33/d338229d774a743f7858f6764e095878.jpg',
        },
        'c320633b-c2c1-4446-9e9d-00e047170416': {
            id: 'c320633b-c2c1-4446-9e9d-00e047170416',
            text: '8 slices bacon',
            wheight: 232,
            image:
        'https://www.edamam.com/food-img/d42/d426884a125fa39a70d5a5d7217864ec.jpg',
        },
        '5ee45480-3578-4936-a7a0-e053cf1ebbc8': {
            id: '5ee45480-3578-4936-a7a0-e053cf1ebbc8',
            text: '30 cloves garlic',
            wheight: 90,
            image:
        'https://www.edamam.com/food-img/6ee/6ee142951f48aaf94f4312409f8d133d.jpg',
        },
        '08118834-c7e8-4b93-89c8-e33084189ccd': {
            id: '08118834-c7e8-4b93-89c8-e33084189ccd',
            text: '3 lemons, peeled, rinds thinly sliced and reserved',
            wheight: 174,
            image:
        'https://www.edamam.com/food-img/70a/70acba3d4c734d7c70ef4efeed85dc8f.jpg',
        },
        'be6f7061-4ff3-4482-8306-69ba85d5d625': {
            id: 'be6f7061-4ff3-4482-8306-69ba85d5d625',
            text: '½ cup Banyuls or another fortified dessert wine',
            wheight: 51.5,
            image:
        'https://www.edamam.com/food-img/ea0/ea026d474cb3f9c0b3302eeaa5232151.jpg',
        },
        'c5a96209-0386-40a8-bac1-f9b12d56d31b': {
            id: 'c5a96209-0386-40a8-bac1-f9b12d56d31b',
            text: '1 cup veal or chicken stock',
            wheight: 240,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
        '9e8a8d11-3201-41eb-82e5-3805606bed12': {
            id: '9e8a8d11-3201-41eb-82e5-3805606bed12',
            label: 'Persian Chicken',
            image:
        'https://www.edamam.com/web-img/8f8/8f810dfe198fa3e520d291f3fcf62bbf.jpg',
            source: 'BBC Good Food',
            url: 'http://www.bbcgoodfood.com/recipes/7343/',
            shareAs:
        'http://www.edamam.com/recipe/persian-chicken-4caf01683bf99ddc7c08c35774aae54c/chicken',
            calories: 4115.31372402923,
            totalTime: 0,
        },
        '7245adfd-1fa2-4feb-826f-49db963bbefe': {
            id: '7245adfd-1fa2-4feb-826f-49db963bbefe',
            text: '2 large onions',
            wheight: 300,
            image:
        'https://www.edamam.com/food-img/205/205e6bf2399b85d34741892ef91cc603.jpg',
        },
        'aae041c4-679c-43de-99e0-98920b133ad4': {
            id: 'aae041c4-679c-43de-99e0-98920b133ad4',
            text: '750 g chicken',
            wheight: 750,
            image:
        'https://www.edamam.com/food-img/d33/d338229d774a743f7858f6764e095878.jpg',
        },
        '76c7a14f-4fa4-4053-a20b-ba1e48b3c234': {
            id: '76c7a14f-4fa4-4053-a20b-ba1e48b3c234',
            text: '500 g mushrooms',
            wheight: 500,
            image:
        'https://www.edamam.com/food-img/d63/d639cf4a2afc7407c1d1ce286028136b.jpg',
        },
        '1dbfa57f-870d-400d-9f1b-f743bf2d0f73': {
            id: '1dbfa57f-870d-400d-9f1b-f743bf2d0f73',
            text: '1 cup water',
            wheight: 237,
            image:
        'https://www.edamam.com/food-img/5dd/5dd9d1361847b2ca53c4b19a8f92627e.jpg',
        },
        'e8408910-2ca7-427c-b21d-c15e0e20f422': {
            id: 'e8408910-2ca7-427c-b21d-c15e0e20f422',
            text: '1 cup red wine',
            wheight: 235.2,
            image:
        'https://www.edamam.com/food-img/82b/82b8d0db2d5c38fc7498a657c0afc4ee.JPG',
        },
        '04cf8ac6-6d64-4e2f-ad0c-a6c7d1b93cc3': {
            id: '04cf8ac6-6d64-4e2f-ad0c-a6c7d1b93cc3',
            text: '2 teaspoons chicken stock',
            wheight: 10.00000000050721,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
        '1e1924f8-dda6-4a5f-81b7-88e81dd8f361': {
            id: '1e1924f8-dda6-4a5f-81b7-88e81dd8f361',
            text: '200 ml mayonnaise',
            wheight: 195.2759811031433,
            image: null,
        },
        'c1b6556e-c3ff-4bbc-905c-199659e4e982': {
            id: 'c1b6556e-c3ff-4bbc-905c-199659e4e982',
            text: '200 ml cream',
            wheight: 201.53157530298427,
            image:
        'https://www.edamam.com/food-img/484/4848d71f6a14dd5076083f5e17925420.jpg',
        },
        'c908817d-048c-4945-9d24-cc6030f81295': {
            id: 'c908817d-048c-4945-9d24-cc6030f81295',
            text: 'small bunch of parsley',
            wheight: 45,
            image:
        'https://www.edamam.com/food-img/46a/46a132e96626d7989b4d6ed8c91f4da0.jpg',
        },
        '8ed6bba4-c509-46e9-90e6-9f0b22dca93f': {
            id: '8ed6bba4-c509-46e9-90e6-9f0b22dca93f',
            text: '1 teaspoon curry powder',
            wheight: 2,
            image:
        'https://www.edamam.com/food-img/9ce/9ce02a2887385fd2adaec8dd8adcf9c5.jpg',
        },
        '13074d33-63dd-4fe6-806e-ba7838512ba1': {
            id: '13074d33-63dd-4fe6-806e-ba7838512ba1',
            label: 'Dijon Chicken',
            image:
        'https://www.edamam.com/web-img/448/448099f608ac1c4975d20867334da8ec.jpg',
            source: 'Simply Recipes',
            url: 'http://www.simplyrecipes.com/recipes/dijon_chicken/',
            shareAs:
        'http://www.edamam.com/recipe/dijon-chicken-0ccc7e81c8377e661266b3ac48c30486/chicken',
            calories: 1409.860699501316,
            totalTime: 0,
        },
        '7c66f4df-c662-4768-a0b2-1e1f5980d8bb': {
            id: '7c66f4df-c662-4768-a0b2-1e1f5980d8bb',
            text: '1 to 1 1/4 pound boneless, skinless chicken breasts',
            wheight: 510.29141625,
            image:
        'https://www.edamam.com/food-img/da5/da510379d3650787338ca16fb69f4c94.jpg',
        },
        '6b3ebcdb-d59b-4898-b308-a86e5a195862': {
            id: '6b3ebcdb-d59b-4898-b308-a86e5a195862',
            text: 'Salt',
            wheight: 6.422048497509917,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        'd864afdd-6dd0-417b-893c-4d843ec0a67c': {
            id: 'd864afdd-6dd0-417b-893c-4d843ec0a67c',
            text: '2 Tbsp olive oil + more for lightly coating chicken',
            wheight: 27,
            image: null,
        },
        '3abb91b5-2c21-4786-b62f-0c0bfee1995b': {
            id: '3abb91b5-2c21-4786-b62f-0c0bfee1995b',
            text: '1 Tbsp butter',
            wheight: 14.2,
            image: null,
        },
        'd588effd-a925-4e41-9076-c3f4c72e5b29': {
            id: 'd588effd-a925-4e41-9076-c3f4c72e5b29',
            text: '1 cup sliced shallots',
            wheight: 160.00000000270512,
            image:
        'https://www.edamam.com/food-img/d23/d23e3be21df05e9e16c05eadb12341e7.jpeg',
        },
        '9e00b0e3-b034-4296-90c3-6a3e4022bed7': {
            id: '9e00b0e3-b034-4296-90c3-6a3e4022bed7',
            text: '1/2 cup dry white wine (can sub chicken stock)',
            wheight: 117.6,
            image:
        'https://www.edamam.com/food-img/a71/a718cf3c52add522128929f1f324d2ab.jpg',
        },
        '56ac00f2-eeac-40fa-bd88-44611d5b02e9': {
            id: '56ac00f2-eeac-40fa-bd88-44611d5b02e9',
            text: '1/2 cup water',
            wheight: 118.5,
            image:
        'https://www.edamam.com/food-img/5dd/5dd9d1361847b2ca53c4b19a8f92627e.jpg',
        },
        '9748c948-4d2a-47d6-9292-51023fc47269': {
            id: '9748c948-4d2a-47d6-9292-51023fc47269',
            text: '4 Tbsp Dijon mustard, smooth or whole grain or a mixture of both',
            wheight: 62.2499999989476,
            image:
        'https://www.edamam.com/food-img/e23/e238f2e4cfa6aa1a30f46dc73e7344eb.jpg',
        },
        'a0cb93a4-b157-4272-92fc-8c295fd0c36b': {
            id: 'a0cb93a4-b157-4272-92fc-8c295fd0c36b',
            text: '1 teaspoon dried thyme',
            wheight: 1,
            image:
        'https://www.edamam.com/food-img/89b/89b37a04e46e052671d73addcb84aa51.jpg',
        },
        'f3f1bd68-5606-4ace-b6ae-aab3c390eb9a': {
            id: 'f3f1bd68-5606-4ace-b6ae-aab3c390eb9a',
            text: '1/4 cup heavy cream',
            wheight: 59.5,
            image:
        'https://www.edamam.com/food-img/484/4848d71f6a14dd5076083f5e17925420.jpg',
        },
        'e73021dc-d20a-431d-848a-6369cc8f680b': {
            id: 'e73021dc-d20a-431d-848a-6369cc8f680b',
            label: 'Chicken Liver Pâté',
            image:
        'https://www.edamam.com/web-img/480/480000e79dbdd4648c4acd65630ff654.jpg',
            source: 'Saveur',
            url: 'http://www.saveur.com/article/Recipes/Classic-Chicken-Pate',
            shareAs:
        'http://www.edamam.com/recipe/chicken-liver-p%C3%A2t%C3%A9-9ca0499f2ac7f1e4cae63bdf4671c1b3/chicken',
            calories: 1149.3071579176606,
            totalTime: 0,
        },
        '86d9b5a7-9b7d-41a9-bd51-667dceab06d5': {
            id: '86d9b5a7-9b7d-41a9-bd51-667dceab06d5',
            text: '8 oz. chicken livers, cleaned',
            wheight: 226.796185,
            image:
        'https://www.edamam.com/food-img/9aa/9aa4760ac12b682555a37a1cdc91150b.jpg',
        },
        'c2952856-3357-457b-89bd-3be9d8e17f50': {
            id: 'c2952856-3357-457b-89bd-3be9d8e17f50',
            text: '4 cups chicken stock',
            wheight: 960,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
        'f828a43c-90a5-46bd-ae84-c0bf397d0313': {
            id: 'f828a43c-90a5-46bd-ae84-c0bf397d0313',
            text: '2 tbsp. rendered chicken fat or unsalted butter',
            wheight: 28.2499999995224,
            image: null,
        },
        '8c33ed7a-890f-4133-81b9-ae5460c650d1': {
            id: '8c33ed7a-890f-4133-81b9-ae5460c650d1',
            text: '½ medium yellow onion, minced',
            wheight: 55,
            image:
        'https://www.edamam.com/food-img/205/205e6bf2399b85d34741892ef91cc603.jpg',
        },
        '57e34d2e-03c9-45c2-af48-2492ecb1c05f': {
            id: '57e34d2e-03c9-45c2-af48-2492ecb1c05f',
            text: '1½ tbsp. cognac or brandy',
            wheight: 20.9999999995266,
            image:
        'https://www.edamam.com/food-img/1d7/1d785da8ce8dca92df262103058b05d1.jpg',
        },
        '8ac6ef84-2920-4a57-95a0-a4a569ff0579': {
            id: '8ac6ef84-2920-4a57-95a0-a4a569ff0579',
            text: '2 hard-boiled eggs',
            wheight: 80,
            image:
        'https://www.edamam.com/food-img/e54/e54c012fabed0f9cf211a817d1e23c5c.jpg',
        },
        '249039c2-057a-48fa-90f1-d4ca915d4609': {
            id: '249039c2-057a-48fa-90f1-d4ca915d4609',
            text: 'Kosher salt and freshly ground black pepper, to taste',
            wheight: 8.370277109994293,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        '487e6e8a-e244-4435-95ef-29b36a394bb8': {
            id: '487e6e8a-e244-4435-95ef-29b36a394bb8',
            text: 'Kosher salt and freshly ground black pepper, to taste',
            wheight: 4.185138554997146,
            image:
        'https://www.edamam.com/food-img/c6e/c6e5c3bd8d3bc15175d9766971a4d1b2.jpg',
        },
        '8958028e-8496-4998-82f6-64e0ec03b378': {
            id: '8958028e-8496-4998-82f6-64e0ec03b378',
            text: 'Toast points, for serving',
            wheight: 24,
            image:
        'https://www.edamam.com/food-img/6c0/6c0c1e866e0106b960d1463a9bdc54db.jpg',
        },
        '9ccd5049-83ff-4d92-866f-0bc4b3582592': {
            id: '9ccd5049-83ff-4d92-866f-0bc4b3582592',
            label: 'Roast Chicken',
            image:
        'https://www.edamam.com/web-img/25f/25feccd2eed4722604be4a9ffa1ac768.jpg',
            source: 'San Francisco Gate',
            url: 'http://www.sfgate.com/food/recipes/detail.html?rid=18229&sorig=qs',
            shareAs:
        'http://www.edamam.com/recipe/roast-chicken-690c3797b4f56fc1e119c14096d651c5/chicken',
            calories: 2638.8251983480804,
            totalTime: 0,
        },
        '9ca53d70-87f4-4a12-96f0-113161daea92': {
            id: '9ca53d70-87f4-4a12-96f0-113161daea92',
            text: '1 whole chicken, about 3-4 pounds',
            wheight: 1587.5732950000001,
            image:
        'https://www.edamam.com/food-img/d33/d338229d774a743f7858f6764e095878.jpg',
        },
        'b0073323-3495-43a0-82bb-6467fd333b7a': {
            id: 'b0073323-3495-43a0-82bb-6467fd333b7a',
            text: '-- Salt and fresh-ground pepper, to taste',
            wheight: 9.58843977,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        '446c3915-5721-4ade-9e86-cc2ecfebd60a': {
            id: '446c3915-5721-4ade-9e86-cc2ecfebd60a',
            text: '3 to 4 sprigs thyme, or other herbs',
            wheight: 10.5,
            image:
        'https://www.edamam.com/food-img/3e7/3e7cf3c8d767a90b906447f5e74059f7.jpg',
        },
        '7113be73-0cba-4feb-913a-f5d31f35d73e': {
            id: '7113be73-0cba-4feb-913a-f5d31f35d73e',
            text: '-- Olive oil, to taste',
            wheight: 21.733796812,
            image: null,
        },
        '88055e2a-2e63-44a3-9189-3cd4371bc487': {
            id: '88055e2a-2e63-44a3-9189-3cd4371bc487',
            text: '-- Chicken stock (optional)',
            wheight: 319.6146590000001,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
        'b37172f3-153a-44bb-bf63-09fb5bd9c7c4': {
            id: 'b37172f3-153a-44bb-bf63-09fb5bd9c7c4',
            label: 'Kreplach (Chicken Dumplings)',
            image:
        'https://www.edamam.com/web-img/4dd/4dd1c7a0d8b00e8929bd6babf0968ba2.jpg',
            source: 'Tasting Table',
            url:
        'https://www.tastingtable.com/entry_detail/chefs_recipes/10154/Matzo_balls_watch_your_back.htm',
            shareAs:
        'http://www.edamam.com/recipe/kreplach-chicken-dumplings-1817e7fccea9ae39d09c0e2c7fb86cb2/chicken',
            calories: 4387.196994575062,
            totalTime: 10,
        },
        '79986efa-3b21-410b-867b-291d9b419840': {
            id: '79986efa-3b21-410b-867b-291d9b419840',
            text: '1½ teaspoons canola oil',
            wheight: 6.75,
            image:
        'https://www.edamam.com/food-img/07e/07e106ab3536d57428e5c46d009038f8.jpg',
        },
        '3b5154fa-a860-4aa3-aeaa-55239fe48458': {
            id: '3b5154fa-a860-4aa3-aeaa-55239fe48458',
            text: '½ small shallot, finely chopped',
            wheight: 20,
            image:
        'https://www.edamam.com/food-img/d23/d23e3be21df05e9e16c05eadb12341e7.jpeg',
        },
        '7397e0b9-25db-48fa-9c0f-7ed870d70d3c': {
            id: '7397e0b9-25db-48fa-9c0f-7ed870d70d3c',
            text:
        '1 cup (about ½ pound) raw, boneless chicken meat (preferably from 3 boneless chicken thighs), roughly chopped',
            wheight: 226.796185,
            image: null,
        },
        '8ecf1d70-3dcd-4ed6-99f8-da45e19c0fdd': {
            id: '8ecf1d70-3dcd-4ed6-99f8-da45e19c0fdd',
            text:
        '⅔ cup (about ¼ pound) chicken skin and fat (reserved from the 3 chicken thighs)',
            wheight: 113.3980925,
            image:
        'https://www.edamam.com/food-img/007/00792642367e1f55de680762f85cfb3b.jpg',
        },
        'c7cd563c-dd1d-4968-a587-f2ccc2d0d83f': {
            id: 'c7cd563c-dd1d-4968-a587-f2ccc2d0d83f',
            text: '2 chicken livers (optional)',
            wheight: 88,
            image:
        'https://www.edamam.com/food-img/9aa/9aa4760ac12b682555a37a1cdc91150b.jpg',
        },
        'c5e73d08-1692-45f7-b4b2-197439be7f3b': {
            id: 'c5e73d08-1692-45f7-b4b2-197439be7f3b',
            text: '2 garlic cloves, finely chopped',
            wheight: 6,
            image:
        'https://www.edamam.com/food-img/6ee/6ee142951f48aaf94f4312409f8d133d.jpg',
        },
        '07730639-03a5-469f-b0b8-5f534712303f': {
            id: '07730639-03a5-469f-b0b8-5f534712303f',
            text: '¼ cup finely chopped chives, plus extra for serving',
            wheight: 12.000000000202874,
            image:
        'https://www.edamam.com/food-img/f3f/f3f48643a924ba174b894e979cb48620.jpg',
        },
        'e848c087-80c5-4e38-803e-7628406e3b69': {
            id: 'e848c087-80c5-4e38-803e-7628406e3b69',
            text: '1¼ teaspoons kosher salt',
            wheight: 6.067708333641094,
            image:
        'https://www.edamam.com/food-img/694/6943ea510918c6025795e8dc6e6eaaeb.jpg',
        },
        '5b9d0e6a-ff71-4672-aaa4-ec2fd3f5361b': {
            id: '5b9d0e6a-ff71-4672-aaa4-ec2fd3f5361b',
            text: '¾ teaspoon freshly ground black pepper',
            wheight: 1.7249999999999999,
            image:
        'https://www.edamam.com/food-img/c6e/c6e5c3bd8d3bc15175d9766971a4d1b2.jpg',
        },
        '3e4299a9-c228-4c52-a7cb-f962a44d0fb5': {
            id: '3e4299a9-c228-4c52-a7cb-f962a44d0fb5',
            text: '30 to 34 square wonton wrappers',
            wheight: 1024,
            image:
        'https://www.edamam.com/food-img/64e/64e52edbb86d08069012c6a331738ace.jpg',
        },
        'ac70fd97-12aa-4c7d-b3e6-5de929c65655': {
            id: 'ac70fd97-12aa-4c7d-b3e6-5de929c65655',
            text: '8 cups store-bought or homemade chicken broth',
            wheight: 1920,
            image:
        'https://www.edamam.com/food-img/26a/26a10c4cb4e07bab54d8a687ef5ac7d8.jpg',
        },
    },
};
