import { atom } from './hooks';

export const viewType = {
    id: 'string',
    name: 'string',
    type: 'string',
    label: 'string',
    edge: 'string',
    children: ['view'],
    props: {},
};

export const ViewRootNodeId = atom(undefined);

export const ComponentDragging = atom(false);
export const EdgeTypes = atom([]);
export const EditMode = atom(false);
export const EditPath = atom(null);
export const HoveredNode = atom(false);
export const ViewNodeId = atom(undefined);
export const ViewState = atom({
    name: 'user',
    type: 'container',
    label: 'User',
    children: [
        {
            name: 'firstName',
            type: 'text',
            label: 'First Name',
        },
        {
            name: 'lastName',
            type: 'text',
            label: 'Last Name',
        },
        {
            name: 'friends',
            type: 'list',
            label: 'Friends',
            edge: 'user/friend',
            children: [
                {
                    name: 'firstName',
                    type: 'text',
                    label: 'First Name',
                },
                {
                    name: 'age',
                    type: 'text',
                    label: 'Age',
                },
                {
                    name: 'friends',
                    type: 'list',
                    label: 'Friends',
                    edge: 'user/friend',
                    children: [
                        {
                            name: 'firstName',
                            type: 'text',
                            label: 'First Name',
                        },
                        {
                            name: 'adress',
                            type: 'text',
                            label: 'Adress',
                        },
                    ],
                },
            ],
        },
    ],
});

export const ActiveScope = atom(false);
