import React, { useState } from 'react';
import * as R from 'ramda';
import { useBookmarks, useBookmarksFns } from '../../../hooks/useBookmarks';
import { getUserNodeId } from '../../../hooks/useUserNodeId';
import { newId, waitForConfirm } from '../../../util/util';
import { CollapseIcon, EyeIcon, NodeIcon, SearchIcon } from '../../Screens/ViewScreen/Icons';
import { ViewRootNodeId } from '../../Screens/ViewScreen/state';
import { useViewFns } from '../../../hooks/useViews';

const IconSwitch = ({ type }) => {
    if (type === 'view') {
        return <EyeIcon className="my-auto h-6 w-6" />;
    }
    if (type === 'query') {
        return <SearchIcon className="my-auto h-6 w-6" />;
    }
    if (type === 'node') {
        return <NodeIcon className="my-auto h-6 w-6" />;
    }

    return null;
};

const BookmarkPill = ({ bookmark, onClick, onDelete }) => (
    <div
        onContextMenu={e => {
            e.preventDefault();
            if (onDelete) {
                waitForConfirm(
                    "Are you sure you want to delete this bookmark? It can't be undone.",
                    onDelete,
                );
            }
        }}
        className="flex cursor-pointer select-none flex-row gap-1 rounded-full bg-gray-100 py-1 px-3"
        onClick={onClick}
    >
        <IconSwitch type={bookmark.type} />
        <div id="label-container" className="flex content-center">
            <div id="label" className="my-auto whitespace-nowrap">
                {bookmark.name}
            </div>
        </div>
    </div>
);

const rootNodeBookmark = () => ({
    id: newId(),
    name: 'Root Node',
    type: 'node',
    data: getUserNodeId(),
    static: true,
});

export const BookmarkPills = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [_bookmarks] = useBookmarks();
    const bookmarks = R.prepend(rootNodeBookmark(), isOpen ? _bookmarks : []);
    const { removeBookmark, pushBookmarks } = useBookmarksFns();

    const { selectView } = useViewFns();

    return (
        <div
            id="pills-container"
            className="absolute left-[22rem] z-10 mt-2 ml-4 flex h-12 w-1/2 flex-row gap-2 py-2"
        >
            {bookmarks.map(bookmark => (
                <BookmarkPill
                    key={bookmark.id}
                    bookmark={bookmark}
                    onClick={() => {
                        if (bookmark.type === 'node') {
                            ViewRootNodeId.set(bookmark.data);
                        } else if (bookmark.type === 'view') {
                            console.log(bookmark);
                            selectView(bookmark.data, true);
                        }
                    }}
                    onDelete={
                        !bookmark.static
                            ? () => {
                                  removeBookmark(bookmark.id);
                                  pushBookmarks();
                              }
                            : undefined
                    }
                />
            ))}
            <div
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className="flex flex-row rounded-full bg-gray-100 py-1 px-1"
            >
                <CollapseIcon
                    className={`my-auto h-6 w-6 ${isOpen ? '-rotate-90' : 'rotate-90'}`}
                />
            </div>
        </div>
    );
};
