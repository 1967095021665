import React from 'react';
import { Link } from 'react-router-dom';

/* #region input */

export const ViewLink = ({ value, view }) => (
    <Link className="view-link" to={value}>
        {`${view.label}   `}
        <ion-icon name="open-outline" />
    </Link>
);

/* #endregion */

export const viewLinkComponents = {
    viewLink: ViewLink,
};
