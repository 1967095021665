/* eslint-disable react-hooks/rules-of-hooks */
import * as R from 'ramda';
import React from 'react';
import { useAtom } from '../Screens/ViewScreen/hooks';

export const DropDownBehavior = {
    keyboard:
        ({ onArrowUp, onArrowDown, onEnter, onEscape }) =>
        e => {
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                onArrowUp && onArrowUp(e);
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                onArrowDown && onArrowDown(e);
            } else if (e.key === 'Enter') {
                e.preventDefault();
                onEnter && onEnter(e);
            } else if (e.key === 'Escape') {
                e.stopPropagation();
                e.preventDefault();
                onEscape && onEscape(e);
            }
        },
    filterOptionsIncludes:
        (searchStr = '') =>
        (options = []) =>
            R.compose(
                R.sortBy(R.identity),
                R.filter(str => R.includes(R.toLower(searchStr), R.toLower(str))),
            )(options),
    circulatedIndex:
        (max, offset = 0) =>
        val =>
            ((val + max - offset) % max) + offset,
};

export const PlainDropDownOptions =
    (OptionComponent, SpacerComponent) =>
    ({ className, options, selectedIndex, onSelect, onHover }) =>
        (
            <div
                className={`absolute bottom-0 z-20 flex max-h-20 w-full translate-y-[100%] flex-col overflow-y-scroll ${className}`}
            >
                {SpacerComponent && <SpacerComponent />}
                {options.map((str, i) => (
                    <OptionComponent
                        key={str}
                        selected={i === selectedIndex}
                        onPointerEnter={() => onHover && onHover(i)}
                        onPointerDown={() => onSelect && onSelect(i)}
                    >
                        {str}
                    </OptionComponent>
                ))}
            </div>
        );

export const DropDownOptions =
    (OptionComponent, SpacerComponent) =>
    ({ className, Options, SelectedIndex, onSelect, onHover }) => {
        const selectedIndex = useAtom(SelectedIndex);
        const options = useAtom(Options);
        return (
            <div
                className={`absolute bottom-0 z-20 flex max-h-20 w-full translate-y-[100%] flex-col overflow-y-scroll ${className}`}
            >
                {SpacerComponent && <SpacerComponent />}
                {options.map((str, i) => (
                    <OptionComponent
                        key={str}
                        selected={i === selectedIndex}
                        onPointerEnter={() => onHover && onHover(i)}
                        onPointerDown={() => onSelect && onSelect(i)}
                    >
                        {str}
                    </OptionComponent>
                ))}
            </div>
        );
    };
