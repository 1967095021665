import React, { useEffect, useState } from 'react';
import {
    Route, Routes,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { exampleGraph } from '../../../util/exampleGraph';
import { LandingPageGraph } from '../../Elements/LandingPageGraph';
import { UserInfo } from './UserInfo';

export const LandingPageHeader = ({ loggedIn, logout }) => {
    const dispatch = useDispatch();

    // run on startup
    useEffect(() => {
        dispatch({
            type: 'GOT_VIEW/MERGE_GRAPH',
            fromGraph: exampleGraph,
            toViewId: 'landing-page',
        });
    }, []);
    const [displayHeight] = useState(window.innerHeight);

    const canvasHeight = displayHeight * 0.8;
    return (
        <div className="jumbo" style={{ height: `${canvasHeight}` }}>
            <div className="text no-pointer-events">
                <div className="title">
                    <span className="highlight-1">
                        Got
                    </span>
                    <span className="highlight-2">
                        Hub
                    </span>
                </div>
                <h1 className="eye-catcher">
                    The Database For Everyone
                </h1>
                <Routes>
                    <Route
                        exact
                        path="/home"
                        element={(
                            <p className="explainer">
                                GotHub is the easiest
                                {' '}
                                <span className="highlight-1">database integration</span>
                                {' '}
                                <span className="highlight-2">for</span>
                                {' '}
                                <span className="highlight-1">web apps</span>
                            </p>
                        )}
                    />
                    <Route
                        exact
                        path="/business"
                        element={(
                            <p className="explainer">
                                GotHub is the easiest
                                {' '}
                                <span className="highlight-1">database integration</span>
                                {' '}
                                <span className="highlight-2">for</span>
                                {' '}
                                <span className="highlight-1">web apps</span>
                            </p>
                        )}
                    />
                    <Route
                        exact
                        path="/developer"
                        element={(
                            <p className="explainer">
                                GotHub is an easy to use
                                {' '}
                                <span className="highlight-1">cloud native</span>
                                {' '}
                                <span className="highlight-2">JSON</span>
                                {' '}
                                <span className="highlight-1">graph</span>
                                {' '}
                                <span className="highlight-2">database</span>
                            </p>
                        )}
                    />
                </Routes>
            </div>
            <div />
            <div className="offset-graph">
                <LandingPageGraph
                    height={canvasHeight}
                />
            </div>
            <UserInfo loggedIn={loggedIn} logout={logout} />
        </div>
    );
};
