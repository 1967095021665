// redux states
export const BASE_STATE = 'got2';

// edgetypes
export const VIEWS_VIEW_EDGE = 'views/view';
export const SCOPES_SCOPE_EDGE = 'scopes/scope';
export const SETTINGS_BOOKMARKS_EDGE = 'settings/bookmarks';

// graph names
export const MAIN = 'main';
export const OFFLINE = 'offline';
export const GRAPH = 'graph-screen';
export const VIEW_STANDALONE = 'view-standalone';
export const VIEW_SCREEN = 'view-screen';
export const USER_VIEWS = 'user-views';
export const TEMP = 'temp';

export const VIEWS_MAIN = MAIN; // 'view-main';
export const VIEWS_EDITOR = 'view-editor';

export const SCOPES_MAIN = MAIN; // 'scope-main';
export const SCOPES_EDITOR = 'scope-editor';

// stacks
export const SETTINGS_STACK = ['settings-main', 'settings-editor'];
export const VIEWS_STACK = [VIEWS_MAIN, VIEWS_EDITOR];
export const SCOPES_STACK = [SCOPES_MAIN, SCOPES_EDITOR];

// MODALS
export const BOOKMARK_MODAL = 'bookmark-modal';
export const NODE_DETAIL_MODAL = 'node_detail_modal';
