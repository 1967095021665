import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { createGraph } from '../../got/hooks.config';
import { store } from '../../redux/store';
import { BOOKMARK_MODAL, GRAPH, MAIN, NODE_DETAIL_MODAL } from '../../got/consts';
import { CSS2DObject } from '../../util/GraphRenderer';
import { DeleteIcon, CancelIcon, BookmarkIcon, EditIcon } from '../Screens/ViewScreen/Icons';
import { useOpenModal } from '../../hooks/useModal';
import { waitForConfirm } from '../../util/util';

const selectRawJsonString =
    (nodeId, nodeDisplayKeys) =>
    (node = { id: nodeId }) => {
        const rawData = node; // R.pick(nodeDisplayKeys, node);
        return JSON.stringify(rawData, null, 2);
    };

// const useHighlightRef = () => {
//     const ref = useRef();

//     useEffect(() => {
//         if (ref.current) {
//             hljs.highlightElement(ref.current);
//         }
//     }, [ref.current]);

//     return ref;
// };

const NodeCardContent = React.memo(({ delayedOpen, stack, nodeId, nodeDisplayKeys }) => {
    const { useNode } = createGraph(...stack);
    const rawJSONString = useNode(nodeId, selectRawJsonString(nodeId, nodeDisplayKeys));

    // const highlightRef = useHighlightRef();

    const nameAdd = delayedOpen ? 'opacity-1' : 'opacity-0';

    return (
        <div
            className={`nodeContentContainer z-30 flex h-full w-full flex-row items-center justify-center p-32 transition-opacity duration-[600ms] ${nameAdd}`}
        >
            <div className="h-full w-full rounded-3xl bg-gray-100 shadow-[inset_0_0.25rem_3rem_rgba(0,0,0,0.25)]">
                <pre className="scrollbar flex h-[calc(100%+0.6rem)] w-[calc(100%+0.6rem)] scroll-p-6 overflow-scroll rounded-3xl">
                    <code className="language-json m-6 p-0 text-sm">{rawJSONString}</code>
                </pre>
            </div>
        </div>
    );
}, R.equals);

const NodeControls = React.memo(({ delayedOpen, nodeId, onCancel, onDelete }) => {
    const openBookmarkModal = useOpenModal(BOOKMARK_MODAL);
    const openNodeModal = useOpenModal(NODE_DETAIL_MODAL);

    const nameAdd = delayedOpen ? 'scale-100' : 'scale-0';

    return (
        <div className="space-evenly absolute -top-32 z-30 flex w-full">
            <button
                className={`pointer-events-auto z-30 mx-auto h-36 w-36 cursor-pointer rounded-full bg-gray-100 text-black transition-transform delay-[150ms] ${nameAdd}`}
            >
                <CancelIcon className="mx-auto h-28 w-28" onClick={onCancel} />
            </button>
            <button
                className={`pointer-events-auto z-30 mx-auto h-36 w-36 -translate-y-16 cursor-pointer rounded-full bg-gray-100 text-black transition-transform delay-[300ms] ${nameAdd}`}
            >
                <BookmarkIcon
                    className="mx-auto h-28 w-28"
                    onClick={() => {
                        openBookmarkModal({ type: 'node', data: nodeId });
                    }}
                />
            </button>
            <button
                className={`pointer-events-auto z-30 mx-auto h-36 w-36 -translate-y-16 cursor-pointer rounded-full bg-gray-100 text-black transition-transform delay-[450ms] ${nameAdd}`}
            >
                <EditIcon
                    className="mx-auto h-28 w-28"
                    onClick={() => {
                        openNodeModal(nodeId);
                    }}
                />
            </button>
            <button
                className={`pointer-events-auto z-30 mx-auto h-36 w-36 cursor-pointer rounded-full bg-gray-100 text-black transition-transform delay-[600ms] ${nameAdd}`}
            >
                <DeleteIcon
                    className="mx-auto h-28 w-28"
                    onClick={() => {
                        waitForConfirm(
                            "Are you sure you want to delete this node? It's permanent!",
                            onDelete,
                        );
                    }}
                />
            </button>
        </div>
    );
});

export const NodeCard = ({ nodeId, nodeDisplayKeys, nodeFns = {} }) => {
    const [delayedOpen, setDelayedOpen] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setDelayedOpen(true);
        }, 100);
    }, []);

    return (
        <Provider store={store}>
            <div className="pointer-events-auto relative z-30 h-full w-full rounded-3xl bg-transparent">
                <NodeControls
                    delayedOpen={delayedOpen}
                    stack={[MAIN, GRAPH]}
                    nodeId={nodeId}
                    onCancel={nodeFns.unselect}
                    onDelete={nodeFns.delete}
                />
                <NodeCardContent
                    delayedOpen={delayedOpen}
                    stack={[MAIN, GRAPH]}
                    nodeId={nodeId}
                    nodeDisplayKeys={nodeDisplayKeys}
                />
            </div>
        </Provider>
    );
};

const reference = { nodeId: undefined, css2DObject: undefined };
export const renderNodeCard = (node, nodeDisplayKeys, nodeFns) => {
    if (reference.nodeId === node.id) {
        return reference.css2DObject;
    }

    const nodeContainer = document.createElement('div');
    nodeContainer.className = 'nodeContainer';

    const root = ReactDOM.createRoot(nodeContainer);
    root.render(<NodeCard nodeId={node.id} nodeDisplayKeys={nodeDisplayKeys} nodeFns={nodeFns} />);

    const css2DObject = new CSS2DObject(nodeContainer);

    reference.nodeId = node.id;
    reference.css2DObject = css2DObject;

    return css2DObject;
};
