import { useEffect } from 'react';
import { selectEdgetypesFromGraph, useSelectGraph } from '../../../got/got-adjunct';
import { EdgeTypes } from './state';

const stack = ['main'];
export const EdgeTypeCalculator = () => {
    const edgeTypes = useSelectGraph(stack, selectEdgetypesFromGraph);

    useEffect(() => {
        EdgeTypes.set(edgeTypes);
    }, [edgeTypes]);

    return null;
};
