/* eslint-disable no-shadow */
import * as R from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';
import * as RA from 'ramda-adjunct';
import { GraphControls } from '../Common/GraphControls/GraphControls';
import { GraphView2d } from '../Elements/GraphView2d';
import { FlyOut } from '../Common/FlyOut';
import { GraphNodeModal } from '../Modals/GraphNodeModal';
// import { useQueryColorMap } from '../../util/colormap.util';
import { useUserEmail } from '../../hooks/useUserEmail';
import { BASE_STATE, GRAPH, MAIN, TEMP } from '../../got/consts';
import { createGraph } from '../../got/hooks.config';
import { selectEdgetypesFromGraph, useSelectGraph, viewFromString } from '../../got/got-adjunct';
import { colorizeView, selectViewNodeColoring } from '../../util/colormap.util';
import { useUserNodeId } from '../../hooks/useUserNodeId';
import { GraphView3d } from '../Elements/GraphView3d';

const useNodeProps = graph =>
    useMemo(
        () =>
            R.compose(
                R.uniq,
                R.flatten,
                R.values,
                R.map(R.keys),
                R.pickBy(RA.isTruthy),
                R.propOr({}, 'nodes'),
            )(graph),
        [graph],
    );

const useEdgeTypes = graph => useMemo(() => selectEdgetypesFromGraph(graph), [graph]);

const defaultSettings = {
    renderMode: 'GraphView3d',
    showLinkLabels: true,
    enableForce: true,
    enableBloom: true,
    enableParticles: false,
    labelKey: 'id',
    nodeDisplayKeys: [],
    action: null,
    fromType: '',
    toType: '',
};

const getGraphRenderer = mode => {
    switch (mode) {
        case 'GraphView2d':
            return GraphView2d;
        case 'GraphView3d':
            return GraphView3d;
        default:
            return () => null;
    }
};

export const GraphScreen = ({ stack = [MAIN, GRAPH], queryString, setQueryString, logout }) => {
    const [settings, setSettings] = useState(defaultSettings);
    const { pull, assoc, push, update } = createGraph(...stack);
    const graph = useSelectGraph(stack);
    const userNodeId = useUserNodeId();
    const view = viewFromString(userNodeId, queryString);
    const colorizedView = colorizeView(view);
    const colorMap = useSelector(
        R.compose(selectViewNodeColoring(...stack)(colorizedView), R.propOr({}, BASE_STATE)),
        R.equals,
    );
    useEffect(() => {
        userNodeId && pull(view);
    }, [userNodeId]);

    const nodeProps = useNodeProps(graph);
    const edgeTypes = useEdgeTypes(graph);
    const options = { nodeProps, edgeTypes };
    const userEmail = useUserEmail();
    const [addMode, setAddMode] = useState(false);
    const [connectMode, setConnectMode] = useState(false);
    const [sourceNode, setSourceNode] = useState(undefined);
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    const [flyoutFullscreen, setFlyoutFullscreen] = useState(false);
    const [fitGraph, setFitGraph] = useState(false);
    const graphOffsetAnimation = useSpring({
        transform: flyoutOpen ? 'translateX(-19vw)' : 'translateX(0vw)',
    });

    const GraphRenderer = getGraphRenderer(settings.renderMode);
    return (
        <>
            {userEmail && (
                <div className="user-info">
                    <button
                        onClick={() => {
                            logout && logout();
                        }}
                    >
                        <ion-icon class="graph-icons logout" name="log-out-outline" />
                    </button>
                    {/* <button type="button" onClick={() => setShareVisible(true)}> Share </button> */}
                </div>
            )}
            <GraphControls
                stack={[MAIN, TEMP]}
                settings={settings}
                setSettings={setSettings}
                setQueryString={setQueryString}
                options={options}
            />
            <FlyOut
                stack={stack}
                isOpen={flyoutOpen}
                setIsOpen={setFlyoutOpen}
                fullscreen={flyoutFullscreen}
                setFullscreen={setFlyoutFullscreen}
                userEmail={userEmail}
                logout={logout}
            />
            {graph && (
                <animated.div className="graph-view" style={graphOffsetAnimation}>
                    <GraphRenderer
                        graph={graph}
                        colorMap={colorMap}
                        toggleAdd={() => {
                            setAddMode(R.not);
                            setConnectMode(false);
                        }}
                        toggleConnect={() => {
                            setConnectMode(R.not);
                            setAddMode(false);
                        }}
                        fromType={settings.fromType}
                        toType={settings.toType}
                        settings={settings}
                        setSettings={setSettings}
                        nodeProps={nodeProps}
                        addMode={addMode}
                        connectMode={connectMode}
                        sourceNode={sourceNode}
                        setSourceNode={setSourceNode}
                        labelKey={settings.labelKey}
                        displayMode={settings.showLinkLabels}
                        fitGraph={fitGraph}
                        setFitGraph={setFitGraph}
                        addNode={(node, { fromType, fromId, toType, toId }) => {
                            if (fromType && toType) {
                                assoc(`${fromType}/${toType}`)(fromId)({ id: toId });
                                update(node);
                                push();
                            }
                        }}
                        deleteNode={node => {
                            console.log('Deleting Node not implemented yet', node);
                        }}
                        assoc={({ fromType, fromId, toType, toId }) => {
                            if (fromType && toType) {
                                assoc(`${fromType}/${toType}`)(fromId)({ id: toId });
                                push();
                            }
                        }}
                    />
                </animated.div>
            )}
            <GraphNodeModal stack={stack} />
        </>
    );
};
