import React, { useState } from 'react';
import * as R from 'ramda';
import { HorizontalSpacer } from './HorizontalSpacer';
import { DropDownBehavior, PlainDropDownOptions } from './DropDown';

const useIndex = max => {
    const [selectedIndex, _setSelectedIndex] = useState(0);
    const setSelectedIndex = val => {
        _setSelectedIndex((val + max) % max);
    };

    return [selectedIndex, setSelectedIndex];
};

const filterOptions = (searchStr, options) =>
    R.compose(
        R.sortBy(R.identity),
        R.filter(str => R.includes(searchStr, R.toLower(str))),
    )(options);

const Option = ({ selected, onPointerEnter, onPointerDown, children }) => (
    <div
        className={`${selected ? 'bg-gray-200' : ''} flex cursor-pointer flex-row gap-2 p-2`}
        onPointerEnter={onPointerEnter}
        onPointerDown={onPointerDown}
    >
        <div className="my-auto">{children}</div>
    </div>
);

const SearchInput = ({
    placeholder,
    setFocused,
    selectedIndex,
    setSelectedIndex,
    onEnter,
    onDelete,
    localValue,
    setLocalValue,
    submitSearch,
}) => (
    <div
        className="my-auto"
        onSubmit={e => {
            e.preventDefault();
            submitSearch(localValue);
            setFocused(false);
        }}
    >
        <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyUp={DropDownBehavior.keyboard({
                hasSelection: selectedIndex > 0,
                onArrowUp: () => setSelectedIndex(selectedIndex - 1),
                onArrowDown: () => setSelectedIndex(selectedIndex + 1),
                onEnter,
                onDelete,
                onEscape: () => {
                    setSelectedIndex(0);
                    setFocused(false);
                },
            })}
            value={localValue}
            onChange={e => {
                setFocused(true);
                setSelectedIndex(0);
                setLocalValue(e.target.value);
            }}
            placeholder={placeholder}
            className="my-auto bg-gray-100"
        />
    </div>
);

const AutocompleteOptions = PlainDropDownOptions(Option, HorizontalSpacer);

export const AutocompleteInput = ({
    className = '',
    value = '',
    setValue,
    options,
    placeholder,
}) => {
    const filteredOptions = filterOptions(R.toLower(value), options || []);
    const [selectedIndex, setSelectedIndex] = useIndex(filteredOptions.length + 1);
    const [focused, setFocused] = useState(false);

    const optionsOpen = focused && filteredOptions.length > 0;

    return (
        <div className="relative z-10 flex flex-col content-center">
            <div
                id="query-input"
                className={` flex content-center bg-gray-100 ${
                    optionsOpen
                        ? 'rounded-t-lg border-t border-l border-r border-gray-500'
                        : 'rounded-lg'
                } ${className}`}
            >
                <SearchInput
                    placeholder={placeholder}
                    localValue={value}
                    setLocalValue={str => {
                        setValue(str);
                    }}
                    selectedIndex={selectedIndex}
                    setFocused={setFocused}
                    setSelectedIndex={setSelectedIndex}
                    onEnter={() => {
                        setFocused(false);
                        if (selectedIndex <= 0) return;
                        const str = filteredOptions[selectedIndex - 1];
                        setValue(str);
                    }}
                />
            </div>
            {optionsOpen && (
                <AutocompleteOptions
                    className="rounded-b-lg border-b border-l border-r border-gray-500 bg-gray-100 p-2 pt-0 text-black transition-all"
                    options={filteredOptions}
                    selectedIndex={selectedIndex - 1}
                    onHover={i => setSelectedIndex(i + 1)}
                    onSelect={i => {
                        setValue(filteredOptions[i]);
                        setFocused(false);
                    }}
                />
            )}
        </div>
    );
};
