import React, { useState } from 'react';
import { BOOKMARK_MODAL } from '../../../got/consts';
import { useBookmarksFns } from '../../../hooks/useBookmarks';
import { useModalState } from '../../../hooks/useModal';
import { Modal } from '../../Basics/Modal';
import { useViewFns } from '../../../hooks/useViews';

export const BookmarkModal = () => {
    const [isOpen] = useModalState(BOOKMARK_MODAL);
    if (isOpen) {
        return <Content />;
    }

    return null;
};

const Content = () => {
    const [{ type, data, title, placeholder }, close] = useModalState(BOOKMARK_MODAL);
    const { addBookmark, pushBookmarks } = useBookmarksFns();
    const { addView, pushViews, selectView } = useViewFns();

    const [value, setValue] = useState('');

    return (
        <Modal title={title || 'Add bookmark'} onClose={close}>
            <input
                value={value}
                onChange={e => setValue(e.target.value)}
                placeholder={placeholder || 'Bookmark name'}
            />
            <button
                className="text-md ml-2 rounded-md bg-indigo-500 px-7 py-2 font-semibold text-white"
                onClick={async () => {
                    addBookmark(value, type, data);
                    pushBookmarks();

                    if (type === 'view') {
                        addView(data, value);
                        await pushViews();
                        selectView(data, false);
                    }

                    close();
                }}
            >
                Ok
            </button>
        </Modal>
    );
};
