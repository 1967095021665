/* eslint-disable no-use-before-define,no-param-reassign, no-prototype-builtins */
import * as R from 'ramda';
import React, { useRef, useState } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as uuid from 'uuid';
import '../../Graph.css';
import { useOpenModal } from '../../hooks/useModal';
import { NODE_DETAIL_MODAL } from '../../got/consts';
import { useGraphData } from '../../hooks/useGraphData';

export const GraphView2d = ({
    graph,
    colorMap,
    addMode,
    connectMode,
    toggleAdd,
    toggleConnect,
    fromType,
    toType,
    sourceNode,
    setSourceNode,
    addNode,
    assoc,
    labelKey,
    fitGraph,
    setFitGraph,
    height,
    width,
    viewOnly = false,
    fallBackLabelKey = 'id',
    forceEnabled = true,
}) => {
    const graphData = useGraphData(graph);
    const [displayHeight] = useState(window.innerHeight);
    const [displayWidth] = useState(window.innerWidth);

    const openNodeModal = useOpenModal(NODE_DETAIL_MODAL);
    // states for highlighting
    const [focusNodeIds, setFocusNodeIds] = useState(new Set());
    const [highlightNodes, setHighlightNodes] = useState(new Set());
    const [highlightLinks, setHighlightLinks] = useState(new Set());

    const fgRef = useRef();
    if (fitGraph) {
        setFitGraph(false);
        fgRef.current ? fgRef.current.zoomToFit(300) : console.log('no ref found');
    }
    const handleLinkClick = () => null;
    const handleLinkHover = link => {
        highlightNodes.clear();
        highlightLinks.clear();

        if (link) {
            highlightLinks.add(link);
            highlightNodes.add(link.source);
            highlightNodes.add(link.target);
        }
        setHighlightNodes(highlightNodes);
        setHighlightLinks(highlightLinks);
    };
    const handleLinkPositionUpdate = (linkObject, { start, end }) => {
        if (linkObject) {
            if (linkObject.children[0]) {
                const parentChildLabel = linkObject.children[0];
                const childParentLabel = linkObject.children[1];
                if (end.x < start.x) {
                    const { material } = childParentLabel;
                    material.rotation = Math.atan2(end.y - start.y, start.x - end.x);
                    Object.assign(childParentLabel.material, material);
                    childParentLabel.visible = true;
                    parentChildLabel.visible = false;
                } else {
                    const { material } = parentChildLabel;
                    material.rotation = Math.atan2(start.y - end.y, end.x - start.x);
                    Object.assign(parentChildLabel.material, material);
                    childParentLabel.visible = false;
                    parentChildLabel.visible = true;
                }
            }

            const middlePos = Object.assign(
                ...['x', 'y', 'z'].map(c => ({
                    [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
                })),
            );
            // middlePos.z += 1;
            Object.assign(linkObject.position, middlePos);
        }
    };
    /* #endregion Link */

    /* #region Node */

    const drawCircle = (position, radius, color, ctx) => {
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(position.x, position.y, radius, 0, 2 * Math.PI, false);
        ctx.fill();
    };

    function generateNodeCanvasObject(node, ctx) {
        let color = R.propOr('#1553B7', node.id, colorMap);
        if (sourceNode && sourceNode.id === node.id) color = '#fde863';
        let borderColor = '#0f0f0f';
        if (highlightNodes.has(node)) borderColor = '#ffffff';
        drawCircle(node, 5, borderColor, ctx);
        drawCircle(node, 4.5, color, ctx);
    }

    const handleNodeClick = (node, event) => {
        if (addMode) {
            const id = uuid.v4();
            addNode({ id }, { fromType, fromId: node.id, toType });
            toggleAdd();
        } else if (connectMode) {
            if (!sourceNode) {
                // selecting sourceNode
                setSourceNode(node);
            } else if (sourceNode === node) setSourceNode(undefined);
            else {
                setSourceNode(undefined);
                assoc({
                    fromType,
                    fromId: sourceNode.id,
                    toType,
                    toId: node.id,
                });
                toggleConnect();
            }
        } else {
            const selectedNodes = focusNodeIds;
            if (event.ctrlKey || event.shiftKey || event.altKey) {
                selectedNodes.has(node.id)
                    ? selectedNodes.delete(node.id)
                    : selectedNodes.add(node.id);
                setFocusNodeIds(selectedNodes);
            } else {
                if (!selectedNodes.has(node.id)) {
                    selectedNodes.add(node.id);
                    setFocusNodeIds(selectedNodes);
                }
                // zoomToNode(node);
                openNodeModal(node.id);
            }
        }
    };
    // const zoomToNode = node => {
    //     fgRef.current.centerAt(node.x, node.y, 500);
    //     fgRef.current.zoom(25, 500);
    // };
    /* #endregion */

    /* #region helpers  */

    /* #endregion */

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <ForceGraph2D
            height={height || displayHeight}
            width={width || displayWidth}
            autoPauseRedraw={false}
            ref={fgRef}
            graphData={graphData}
            nodeLabel={node =>
                R.pathOr(
                    R.pathOr('', ['nodes', node.id, fallBackLabelKey], graph),
                    ['nodes', node.id, labelKey],
                    graph,
                )
            }
            backgroundColor="#171b21"
            cooldownTicks={forceEnabled ? Infinity : 0}
            // link
            linkWidth={8}
            linkColor={link => (highlightLinks.has(link) ? '#ffffff' : 'rgba(200,200,200,0.6')}
            linkPositionUpdate={
                !viewOnly
                    ? (linkObject, { start, end }) =>
                          handleLinkPositionUpdate(linkObject, {
                              start,
                              end,
                          })
                    : null
            }
            linkLabel={link => `${link.fromType} / ${link.toType}`}
            onLinkHover={link => handleLinkHover(link)}
            onLinkClick={
                !viewOnly
                    ? (link, { source, target }) => handleLinkClick(link, { source, target })
                    : null
            }
            // linkCanvasObject={(link, ctx, globalScale) => generateLinkCanvasObject(link, ctx, globalScale)}
            // node
            nodeCanvasObject={(node, ctx, globalScale) =>
                generateNodeCanvasObject(node, ctx, globalScale)
            }
            onNodeClick={!viewOnly ? (node, event) => handleNodeClick(node, event) : null}
            // onEngineStop={fgRef.current ? fgRef.current.zoomToFit(400) : console.log('no ref')}
            showNavInfo={!viewOnly}
            enableNodeDrag={!viewOnly}
        />
    );
};
