import React, { useEffect, useState, useCallback } from 'react';
import * as R from 'ramda';
import { useEdgeIds, useFiles, useNode } from './hooks';
import { newMsDate } from '../../../util/util';
import {
    AddCircleIcon,
    ArrowRightIcon,
    CancelIcon,
    CheckIcon,
    CollapseIcon,
    DownloadIcon,
    PaperclipIcon,
    RemoveCircleIcon,
} from './Icons';
import { Toggle as ToggleSwitch } from '../../Basics/Toggle';
import { ActiveScope, HoveredNode } from './state';

export const Header = ({ nodeId, view: { name, initial }, editMode, editView }) => {
    const { result } = useNode(nodeId, useCallback(R.prop(name), [name]));
    const readValue = editMode ? initial || '' : result || '';
    return (
        <div
            className="block min-h-10 pl-0.5 text-4xl focus:outline-none"
            contentEditable={editMode}
            suppressContentEditableWarning
            onBlur={e => editView && editView(['initial'], e.currentTarget.textContent)}
        >
            {readValue || initial}
        </div>
    );
};

export const Text = ({ nodeId, view: { name, label, readonly, initial }, editMode, editView }) => {
    const { push, update, result } = useNode(nodeId, useCallback(R.prop(name), [name]));
    const readValue = editMode ? initial || '' : result || '';
    return !readonly ? (
        <input
            type="text"
            id={name}
            className="m-0 block w-full rounded-lg p-2.5 text-base focus:outline-none dark:bg-got-dark-grey dark:text-got-light dark:placeholder-got-light-grey dark:focus:bg-got-dark"
            placeholder={editMode ? 'Initial Value' : label}
            value={readValue}
            onChange={e =>
                editMode
                    ? editView && editView(['initial'], e.currentTarget.value)
                    : update({ [name]: e.currentTarget.value })
            }
            onBlur={push}
        />
    ) : (
        <div
            className="m-0 box-content block w-full pl-1 text-base leading-normal focus:outline-none dark:text-got-light"
            contentEditable={editMode}
            suppressContentEditableWarning
            onBlur={e => editView && editView(['initial'], e.currentTarget.textContent)}
        >
            {readValue || initial}
        </div>
    );
};

export const TextArea = ({ nodeId, view: { name, label } }) => {
    const { push, update, result } = useNode(nodeId, useCallback(R.prop(name), [name]));
    return (
        <textarea
            id={name}
            className="m-0 block w-full rounded-lg p-2.5 focus:outline-none dark:border-got-grey dark:bg-got-dark-grey dark:text-got-light dark:placeholder-got-light-grey dark:focus:bg-got-dark"
            placeholder={label}
            value={result || ''}
            onChange={e => update({ [name]: e.currentTarget.value })}
            onBlur={push}
        />
    );
};

export const NumberField = ({ nodeId, view: { name, label } }) => {
    const { push, update, result } = useNode(nodeId, useCallback(R.prop(name), [name]));
    return (
        <input
            type="number"
            id={name}
            className="m-0 block w-full rounded-lg p-2.5 focus:outline-none dark:border-got-grey dark:bg-got-dark-grey dark:text-got-light dark:placeholder-got-light-grey dark:focus:bg-got-dark"
            placeholder={label}
            value={result || ''}
            onChange={e => update({ [name]: e.currentTarget.value })}
            onBlur={push}
        />
    );
};

export const Toggle = ({ nodeId, view: { name, label } }) => {
    const { push, update, result } = useNode(nodeId, useCallback(R.prop(name), [name]));
    return (
        <ToggleSwitch
            label={label}
            setValue={val => {
                update({ [name]: val });
                push();
            }}
            value={!!result}
        />
    );
};

const pickFile = async accept => {
    const input = document.getElementById('file-input') || document.createElement('input');
    input.id = 'file-input';
    input.type = 'file';
    input.hidden = true;
    input.value = '';
    input.accept = accept;

    document.body.appendChild(input);
    input.click();
    return new Promise(resolve => {
        input.addEventListener('change', () => {
            resolve(input.files[0]);
        });
    });
};

export const UploadField = ({ nodeId, view: { name, label } }) => {
    const { pull, pushAndUpload, setFile, result } = useFiles(nodeId);

    const hasFile = result && result[name];

    // TODO move pull of files to top level, based on if file components are in the view
    useEffect(() => {
        pull();
    }, [nodeId]);

    return (
        <div className="m-0 flex w-full text-sm">
            <div
                className="m-0 flex cursor-pointer flex-row justify-center rounded-lg p-2.5 text-center dark:border-got-grey dark:bg-got-dark-grey dark:text-got-light dark:placeholder-got-light-grey dark:focus:bg-got-dark"
                onClick={async () => {
                    const file = await pickFile();
                    if (file) {
                        setFile(name, file.name, file);
                        pushAndUpload();
                    }
                }}
            >
                <PaperclipIcon className="mr-1 h-5 w-5" />
                {hasFile ? `Upload new ${label}` : `Upload ${label}`}
            </div>
            {hasFile && (
                <>
                    <a
                        className="flex flex-row p-2.5 text-center"
                        href={result[name].url}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <DownloadIcon className="mr-1 h-5 w-5" />
                        {`Download ${label}`}
                    </a>
                    <div className="p-2.5 text-center">{`uploaded ${result[name].modifiedDate}`}</div>
                </>
            )}
        </div>
    );
};

const AddButton = ({ addAndPush, editMode }) => {
    const [add, setAdd] = useState(false);
    const [id, setId] = useState('');

    return (
        <div
            onClick={() => {
                if (add) {
                    setAdd(false);
                    setId('');
                } else {
                    setAdd(true);
                    const scope = ActiveScope.get() || '';
                    setId(scope);
                }
            }}
            className={`relative ml-[9px] h-fit w-fit -translate-x-1/2 cursor-pointer ${
                editMode
                    ? 'text-got-highlight-second-light'
                    : 'text-got-highlight-second-shaded-intense'
            }`}
        >
            <div className="has-tooltip -translate-y-1">
                <span className="tooltip left rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                    Add Item
                </span>
                {!add && <AddCircleIcon className="h-8 w-8 stroke-[1]" />}
                {add && <CancelIcon className="h-8 w-8 stroke-[1]" />}
            </div>
            {add && (
                <div
                    className="absolute right-0 top-0 flex translate-x-full -translate-y-1 flex-row gap-2 rounded-lg dark:bg-got-dark-grey dark:text-got-light"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <input
                        type="text"
                        className="m-0 block h-8 w-96 rounded-lg p-2.5 text-sm focus:outline-none dark:bg-got-dark-grey dark:text-got-light dark:placeholder-got-light-grey dark:focus:bg-got-dark"
                        placeholder="Node ID"
                        value={id}
                        onChange={e => setId(e.target.value)}
                    />
                    <button
                        className="border-none bg-transparent p-0"
                        onClick={() => {
                            if (id && id !== ActiveScope.get()) {
                                addAndPush({ id }, { order: newMsDate() });
                                setAdd(false);
                                setId('');
                            }
                        }}
                    >
                        <CheckIcon className="h-6 w-6 stroke-[1]" />
                    </button>
                </div>
            )}
        </div>
    );
};

export const List = ({ nodeId, edge, renderItem, view: { readonly }, editMode }) => {
    const { toIds, addAndPush, removeAndPush } = useEdgeIds(nodeId, edge);
    const [open, setOpen] = useState(true);

    return (
        <>
            <div className="relative mt-2 flex flex-col justify-start">
                <div
                    className="absolute -left-[0.225rem] -top-1 z-10 h-6 -translate-y-1/2 cursor-pointer"
                    onClick={e => {
                        e.stopPropagation();
                        setOpen(!open);
                    }}
                >
                    <div className="has-tooltip">
                        <span className="tooltip left rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                            {open ? 'Collapse List' : 'Expand List'}
                        </span>
                        <CollapseIcon
                            className={`h-6 w-6 select-none ${
                                editMode
                                    ? 'text-got-highlight-second-light'
                                    : 'text-got-highlight-second-shaded-intense'
                            } transition-transform ${open ? 'rotate-180' : 'rotate-90'}`}
                        />
                    </div>
                </div>
                {open &&
                    R.map(toId => (
                        <React.Fragment key={toId}>
                            <div
                                onMouseEnter={() => HoveredNode.set(toId)}
                                onMouseLeave={() => HoveredNode.set(nodeId)}
                                className={`relative ml-2 border-l ${
                                    editMode
                                        ? 'border-got-highlight-second-light'
                                        : 'border-got-highlight-second-shaded-intense'
                                } pl-14`}
                            >
                                {!readonly && (
                                    <div
                                        className={`absolute top-[0.25rem] -left-10 h-0 w-fit cursor-pointer ${
                                            editMode
                                                ? 'text-got-highlight-second-light'
                                                : 'text-got-highlight-second-shaded-intense'
                                        }`}
                                        onClick={() => {
                                            removeAndPush({ id: toId });
                                        }}
                                    >
                                        <div className="has-tooltip">
                                            <span className="tooltip left rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                                                Delete Item
                                            </span>
                                            <RemoveCircleIcon className="h-8 w-8 stroke-[1]" />
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`absolute top-[1.25rem] ${
                                        !readonly ? '-left-3' : 'left-0'
                                    } flex h-0 w-fit items-center ${
                                        editMode
                                            ? 'text-got-highlight-second-light'
                                            : 'text-got-highlight-second-shaded-intense'
                                    }`}
                                >
                                    <div
                                        className={`h-[1px] ${!readonly ? 'w-9' : 'w-6'} ${
                                            editMode
                                                ? 'bg-got-highlight-second-light'
                                                : 'bg-got-highlight-second-shaded-intense'
                                        }`}
                                    />
                                    <ArrowRightIcon className="h-6 w-6 -translate-x-5 stroke-[1]" />
                                </div>
                                {renderItem(toId)}
                            </div>
                        </React.Fragment>
                    ))(toIds)}
                {R.length(toIds) === 0 && open && (
                    <div
                        className={`relative ml-2 h-8 border-l ${
                            editMode
                                ? 'border-got-highlight-second-light'
                                : 'border-got-highlight-second-shaded-intense'
                        } pt-1 pl-3 text-sm text-got-light-grey`}
                    >
                        {renderItem()}
                    </div>
                )}
            </div>
            {open && !readonly && <AddButton addAndPush={addAndPush} editMode={editMode} />}
        </>
    );
};
