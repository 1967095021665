import React from 'react';
import {
    Route, Routes,
} from 'react-router-dom';
import { Navigate } from 'react-router';
import { LandingPageFooter } from './LandingPageFooter';
import { LandingPageHeader } from './LandingPageHeader';
import { B2BContent } from './B2BContent';
import { DevContent } from './DevContent';
import { HomeContent } from './HomeContent';
import './LandingPageScreen.css';

export const LandingPagesScreen = ({ loggedIn, logout }) => (
    <div className="landing-page">
        <LandingPageHeader loggedIn={loggedIn} logout={logout} />
        <div className="sections">
            <Routes>
                <Route
                    exact
                    path="/home"
                    element={(
                        <HomeContent />
                    )}
                />
                <Route
                    exact
                    path="/business"
                    element={(
                        <B2BContent />
                    )}
                />
                <Route
                    exact
                    path="/developer"
                    element={(
                        <DevContent />
                    )}
                />
                <Route path="/*" element={<Navigate to="/home" replace />} />
            </Routes>
            <LandingPageFooter />
        </div>
    </div>
);
