/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import * as R from 'ramda';
import * as uuid from 'uuid';

export const newId = uuid.v4;
export const newShortId = () => uuid.v4().substring(0, 13);

export const newMsDate = () => Date.now();

export const areArrayItemsEqual = array =>
    !array.map((el, i, a) => (a[i - 1] ? R.equals(el, a[i - 1]) : true)).some(equal => !equal);

/*
export const open = elementId => () => document.getElementById(elementId).open();

export const close = elementId => () => document.getElementById(elementId).close();
*/

// eslint-disable-next-line no-promise-executor-return
export const setTimeoutPromise = async ms => new Promise(resolve => setTimeout(resolve, ms));

export const lCompose = R.compose;

export const lensGreaterThan = (lens1, lens2) => R.converge(R.gt, [R.view(lens1), R.view(lens2)]);

export const overNil = (lens, fn) =>
    R.pipe(R.when(R.pipe(R.view(lens), R.complement(R.identity)), R.over(lens, fn)));

export const toHash = prop =>
    R.compose(R.mergeAll, R.map(R.converge(R.objOf, [R.prop(prop), R.identity])));

export const splitBy = pred => list => {
    const result = [[], []];
    list.forEach(element => {
        pred(element) ? result[0].push(element) : result[1].push(element);
    });
    return result;
};

export const bool = fn => (fn instanceof Function ? (...args) => !!fn(...args) : !!fn);

export const GetAllEdgeTypes = data => {
    const output = {};
    for (const edge of data.edges) {
        if (!output[edge.fromType]) {
            output[edge.fromType] = edge.fromType;
        }
        if (!output[edge.toType]) {
            output[edge.toType] = edge.toType;
        }
    }

    return output;
};

export const FindInNodes = (searchProp, nodes) => {
    const output = {};

    for (const nodeId of Object.keys(nodes)) {
        for (const prop of Object.values(nodes[nodeId])) {
            if (prop === searchProp && !output[nodeId]) {
                output[nodeId] = nodeId;
            }
        }
    }

    return output;
};

export const FindNodeWithProp = (propKey, nodes) => {
    const output = {};

    for (const nodeId of Object.keys(nodes)) {
        if (nodes[nodeId][propKey] && !output[nodeId]) {
            output[nodeId] = nodeId;
        }
    }

    return output;
};

export const getBackendqueryFromString = (rootNodeId, queryString) => ({
    [rootNodeId]: {
        ...recursiveGetBackendQuery(queryString),
    },
});

const getCloseBracket = (string, indexOfOpen) => {
    let bracketCounter = 1;
    for (let i = indexOfOpen + 1; i < string.length; i++) {
        if (string.charAt(i) === '(') {
            bracketCounter++;
        } else if (string.charAt(i) === ')') {
            bracketCounter--;
            if (bracketCounter === 0) {
                return i;
            }
        }
    }

    return -1;
};

const recursiveGetBackendQuery = inputString => {
    let output = {};
    let currentObjectStartIndex = 0;
    let string = inputString;

    if (string.charAt(0) === '(' && string.charAt(string.length - 1) === ')') {
        string = string.substring(1, string.length - 1);
    }

    for (let i = 0; i < string.length; i++) {
        if (string.charAt(i) === '(') {
            const closeBracketIndex = getCloseBracket(string, i);
            i = closeBracketIndex;
        } else if (string.charAt(i) === '/') {
            output = {
                ...output,
                [string.substring(currentObjectStartIndex, i)]: recursiveGetBackendQuery(
                    string.substring(i + 1, string.length),
                ),
            };
            break;
        } else if (string.charAt(i) === '|') {
            output = {
                ...output,
                ...recursiveGetBackendQuery(string.substring(currentObjectStartIndex, i)),
            };
            currentObjectStartIndex = i + 1;
        } else if (i === string.length - 1) {
            output = {
                ...output,
                [string.substring(currentObjectStartIndex, string.length)]: {},
            };
        }
    }
    return output;
};

export const sortByNumber = selector =>
    R.sortBy(R.compose(Number, R.defaultTo(Number.MAX_SAFE_INTEGER), selector));

export const sortEnvelopesBy = propName => envelopes => sortByNumber(R.prop(propName))(envelopes);

export const queryToPointer = queryArr => {
    if (queryArr.length > 1) {
        return {
            [queryArr[1]]: [queryArr[0], queryArr[1], queryToPointer(R.tail(queryArr))],
        };
    }
    return {};
};

/**
 * Intakes a Hashmap or Array of nodeBags and returns a sorted array by their metadatas order prop
 */
export const sortBagsByOrder = R.compose(
    R.sortBy(R.compose(Number, R.pathOr(Number.MAX_SAFE_INTEGER, ['metadata', 'order']))),
    R.defaultTo([]),
    R.when(R.is(Object), R.values),
);

export const waitForConfirm = async (message, onConfirm = () => {}, onCancel = () => {}) =>
    showConfirm(message).then(onConfirm).catch(onCancel);

export const showConfirm = async message =>
    new Promise((resolve, reject) => {
        if (window.confirm(message)) {
            resolve();
        } else {
            reject();
        }
    });
