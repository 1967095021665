import * as R from 'ramda';

export const appendToPath = path => el => R.over(R.lensPath(path), R.append(el));
export const insertAtPath = (path, index) => el =>
    R.over(R.lensPath(path), R.compose(R.insert(index, el), R.defaultTo([])));
export const removeFromPath = path => i => value =>
    path ? R.over(R.lensPath(path), R.remove(i, 1), value) : value;
// const moveInPath = path => (i, steps) =>
//     R.over(R.lensPath(path), R.move(i, i + steps >= 0 ? i + steps : i));

export const initComponent = init => ({
    name: 'propertyName',
    label: 'Property Name',
    ...init,
});

export const movePaths = (sp, tp) => obj => {
    if (R.startsWith(sp, tp)) {
        return obj;
    }

    const value = R.path(sp, obj);

    if (R.startsWith(R.dropLast(1, sp), tp)) {
        if (sp[sp.length - 1] < tp[sp.length - 1]) {
            // add, remove, return
            return R.compose(
                R.dissocPath(sp),
                insertAtPath(R.dropLast(1, tp), R.last(tp))(value),
            )(obj);
        }
    }

    // remove, add, return
    return R.compose(insertAtPath(R.dropLast(1, tp), R.last(tp))(value), R.dissocPath(sp))(obj);
};
