import React from 'react';
import { AutocompleteInput } from '../../Basics/AutocompleteInput';
import { VerticalSpacer } from '../../Basics/VerticalSpacer';
import { Tab } from '../../Basics/Tab';
import { ActiveScope } from '../../Screens/ViewScreen/state';
import { useAtom } from '../../Screens/ViewScreen/hooks';

export const HotBar = ({ settings, setSettings, edgeTypes }) => {
    const scopeId = useAtom(ActiveScope);

    return (
        <div
            id="hotbar"
            className="absolute left-0 right-0 bottom-4 z-10 mx-auto flex h-fit w-fit flex-col rounded-xl bg-gray-100 "
        >
            {(settings.action === 'add' || settings.action === 'connect') && (
                <div id="hotbar-input" className="z-10 mx-4 my-2 flex h-8 w-full flex-row">
                    <div id="fromtype" className="flex content-center">
                        <AutocompleteInput
                            className="my-auto w-fit bg-transparent"
                            placeholder="Edge from type"
                            value={settings.fromType}
                            setValue={value => setSettings({ ...settings, fromType: value })}
                            options={edgeTypes}
                        />
                    </div>
                    <div id="types-spacer" className="flex content-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="my-auto h-6 w-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            />
                        </svg>
                    </div>
                    <div id="totype" className="flex content-center">
                        <AutocompleteInput
                            className="my-auto w-fit bg-transparent"
                            placeholder="Edge to type"
                            value={settings.toType}
                            setValue={value => setSettings({ ...settings, toType: value })}
                            options={edgeTypes}
                        />
                    </div>
                    {settings.action === 'add' && (
                        <>
                            <VerticalSpacer />
                            <div id="totype" className="flex content-center">
                                <AutocompleteInput
                                    className="my-auto w-fit bg-transparent"
                                    placeholder="Node ID"
                                    value={settings.nodeId}
                                    setValue={value => setSettings({ ...settings, nodeId: value })}
                                    options={[]}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            <div className="flex h-14 max-h-14 w-full flex-row px-2">
                <div className=" flex space-x-1">
                    <Tab
                        className="w-12"
                        selected={false}
                        onClick={() => setSettings({ ...settings, fitGraph: true })}
                        tooltip="Zoom to fit (R)"
                    >
                        <div className="mx-auto flex content-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="mx-auto h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                />
                            </svg>
                        </div>
                    </Tab>
                    <VerticalSpacer />
                    <Tab
                        className="my-1  w-24"
                        selected={settings.action === 'add'}
                        onClick={() => {
                            const scope = ActiveScope.get() || '';

                            setSettings({
                                ...settings,
                                action: 'add',
                                nodeId: scope,
                            });
                        }}
                    >
                        <div className="mx-auto text-center">Add Node</div>
                    </Tab>
                    <VerticalSpacer />
                    <Tab
                        className="my-1 w-24"
                        selected={settings.action === 'connect'}
                        onClick={() => setSettings({ ...settings, action: 'connect' })}
                    >
                        <div className="mx-auto text-center">Draw Edge</div>
                    </Tab>
                    <VerticalSpacer />
                    <Tab
                        className="my-1 w-24"
                        selected={settings.action === 'scope'}
                        onClick={() => setSettings({ ...settings, action: 'scope' })}
                    >
                        <div className="mx-auto text-center">Add Scope</div>
                    </Tab>
                    {settings.action && (
                        <>
                            <VerticalSpacer />
                            <div className="w-60 self-center p-2 text-xs">
                                {settings.action === 'add' &&
                                    'Select a node to add a new node with the selected edge types.'}
                                {settings.action === 'connect' &&
                                    'Select two nodes to connect them with the selected edge types.'}
                                {settings.action === 'scope' && scopeId
                                    ? `Adding scope "${scopeId}"`
                                    : 'Please enter a valid scope in the search bar'}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
