import React from 'react';

export const OpenSourceCard = () => (
    <>
        <h1>got is Open Source</h1>
        <div className="container">
            <div className="badges horizontal">
                <div className="badge silent small">
                    <ion-icon name="ellipse-outline" />
                    <div>
                        <code>got</code>
                        {' '}
                        protocol and all client side / frontend tools are open source
                    </div>
                </div>
                <div className="badge silent small">
                    <ion-icon name="ellipse-outline" />
                    <div>
                        Mainly driven by the GotHub Team
                    </div>
                </div>
                <div className="badge silent small">
                    <ion-icon name="ellipse-outline" />
                    <div>
                        GotHub and our work is funded by operating the GotHub.io platform and other alongside services
                    </div>
                </div>
                <div className="badge silent small">
                    <ion-icon name="ellipse-outline" />
                    <div>
                        GotHub.io is a hosted Got-Provider
                    </div>
                </div>
                <div className="badge silent small">
                    <ion-icon name="ellipse-outline" />
                    <div>
                        <a href="https://github.com/gothub-team/got" target="_blank" rel="noreferrer">
                            <code>got</code>
                            {' '}
                            SDK GitHub
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
);
