import React from 'react';

const CloseIcon = () => (
    <div id="icon" className="flex content-center">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="my-auto h-6 w-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
);

export const Modal = ({ title, onClose, children, error = null }) => (
    <div className="absolute top-0 right-0 bottom-0 left-0 z-20 flex items-center justify-center bg-slate-800 bg-opacity-50">
        <div className="flex flex-col rounded-md bg-gray-100 px-12 py-8 text-center">
            <div className="flex flex-row justify-between">
                <h1 className="mb-4 text-xl font-bold text-slate-500">{title}</h1>
                <div onClick={onClose} className="cursor-pointer">
                    <CloseIcon />
                </div>
            </div>
            {error ? <h1 className="mb-4 text-lg font-bold text-red-500">{error}</h1> : ''}

            {children}
        </div>
    </div>
);
