import { useEffect, useState } from 'react';
import { gotApi } from '../got/hooks.config';

export const useUserEmail = loggedIn => {
    const [userEmail, setUserEmail] = useState('');
    useEffect(() => {
        try {
            const { email } = gotApi.getCurrentUser();
            setUserEmail(email);
        } catch (error) {
            setUserEmail('');
        }
    }, [loggedIn]);

    return userEmail;
};
