import { useEffect, useState } from 'react';
import { sha3_224 as sha } from 'js-sha3';
import { gotApi } from '../got/hooks.config';

export const getUserNodeId = () => {
    const { email } = gotApi.getCurrentUser();
    return sha(email);
};

export const useUserNodeId = () => {
    const [userNodeId, setUserNodeId] = useState('');

    useEffect(() => {
        setUserNodeId(getUserNodeId());
    }, []);

    return userNodeId;
};

const createPrefixIdFns = prefix => {
    const getId = () => `${getUserNodeId()}-${prefix}`;

    const useId = () => {
        const [id, setId] = useState('');

        useEffect(() => {
            setId(getId());
        }, []);

        return id;
    };

    return [getId, useId];
};

export const [getUserSettingsId, useUserSettingsId] = createPrefixIdFns('settings');
export const [getUserViewsId, useUserViewsId] = createPrefixIdFns('views');
export const [getUserScopesId, useUserScopesId] = createPrefixIdFns('scopes');
