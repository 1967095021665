import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import * as RA from 'ramda-adjunct';
import { BASE_STATE } from '../../got/consts';
import { createGraph, gotStore } from '../../got/hooks.config';
import { newId } from '../../util/util';

// Intakes a view and renders a component based on type and props for every element in the views components list
export const GotView = ({
    stack, view, viewId, nodeId, getComponent, collapsed,
}) => (
    <div className={(collapsed ? 'got-view collapsed' : 'got-view')}>
        {R.compose(
            R.map(
                component => R.cond([
                    // render prop component if view has 'prop' property
                    [R.propOr(false, 'prop'), R.always(
                        <PropComponent
                            stack={stack}
                            key={component.prop}
                            view={component}
                            viewId={R.propOr(viewId, 'viewId')(view)}
                            nodeId={R.propOr(nodeId, 'nodeId')(view)}
                            Component={getComponent(component.type)}
                        />,
                    )],
                    // render file component if view has 'fileProp' property
                    [R.propOr(false, 'fileProp'), R.always(
                        <FileComponent
                            stack={stack}
                            key={`${R.propOr(nodeId, 'nodeId')(view)}/${component.prop}`}
                            view={component}
                            viewId={R.propOr(viewId, 'viewId')(view)}
                            nodeId={R.propOr(nodeId, 'nodeId')(view)}
                            Component={getComponent(component.type)}
                        />,
                    )],
                    // render edge component if view has 'edge' property
                    [R.propOr(false, 'edge'), R.always(
                        <EdgeComponent
                            stack={stack}
                            key={component.edge}
                            view={component}
                            viewId={R.propOr(viewId, 'viewId')(view)}
                            nodeId={R.propOr(nodeId, 'nodeId')(view)}
                            Component={getComponent(component.type)}
                            getComponent={getComponent}
                        />,
                    )],
                    // render viewLink component if view has 'viewNodeId' property
                    [R.propOr(false, 'viewNodeId'), R.always(
                        <ViewLinkComponent
                            key={component.viewNodeId}
                            view={component}
                            viewId={R.propOr(viewId, 'viewId')(view)}
                            nodeId={R.propOr(nodeId, 'nodeId')(view)}
                            Component={getComponent(component.type)}
                            getComponent={getComponent}
                        />,
                    )],
                    // render null if view doesnt satisfy any of those conditions
                    [R.T, R.always(null)],
                ])(component),
            ),
            R.propOr([], 'components'),
        )(view)}
    </div>
);

// Wrapper for any components that are supposed to display/modify a property of a node, implementing all the necessary hooks to get write/read functions
const PropComponent = ({
    stack, view, viewId, nodeId, Component,
}) => {
    const { update } = createGraph(...stack);
    const value = useSelector(R.compose(
        R.propOr(null, view.prop),
        gotStore.selectNode(...stack)(nodeId),
        R.propOr({}, BASE_STATE),
    ), R.equals);

    if (Component) {
        return (
            <Component
                view={view}
                viewId={viewId}
                nodeId={nodeId}
                value={value}
                setValue={val => update({ id: nodeId, [view.prop]: val })}
            />
        );
    }

    return null;
};

const constructNodeFilesView = nodeId => nodeId
    ? {
        [nodeId]: {
            as: 'node',
            include: {
                files: true,
            },
        },
    }
    : {};

// Wrapper for any components that are supposed to display/upload a file to a node, implementing all the necessary hooks to get download/upload functions
const FileComponent = ({
    stack, view, viewId, nodeId, Component,
}) => {
    const filesView = constructNodeFilesView(nodeId);
    const { useView, setFile, pull } = createGraph(...stack);
    const url = useView(
        filesView,
        R.path(['node', 'files', view.fileProp, 'url']),
    );
    const setFileOnProp = useCallback((filename, file) => setFile(nodeId)(view.fileProp, filename, file), [nodeId, view.fileProp]);
    useEffect(() => {
        !view.ignoreQuery && RA.isNotNilOrEmpty(filesView) && pull(filesView);
    }, [nodeId]);

    if (Component) {
        return (
            <Component
                view={view}
                viewId={viewId}
                nodeId={nodeId}
                url={url}
                setFile={setFileOnProp}
            />
        );
    }

    return null;
};

const constructEdgeView = (nodeId, edgeTypes) => nodeId && edgeTypes
    ? {
        [nodeId]: {
            as: 'parent',
            edges: {
                [edgeTypes]: {
                    as: 'children',
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                },
            },
        },
    }
    : {};

// Wrapper for any components that are supposed to display/modify a relationship from a specified node, implementing all the necessary hooks to get read/add/remove functions
const EdgeComponent = ({
    stack, view, viewId, nodeId, Component, getComponent,
}) => {
    const {
        pull, useView, add, remove,
    } = createGraph(...stack);

    const edgeView = constructEdgeView(nodeId, view.edge);
    useEffect(() => {
        !view.ignoreQuery && RA.isNotNilOrEmpty(edgeView) && pull(edgeView);
    }, [nodeId, view.edge]);
    const nodes = useView(
        edgeView,
        R.compose(
            R.values,
            R.pathOr({}, ['parent', 'children']),
        ),
    );

    // eslint-disable-next-line react/no-unstable-nested-components
    const ItemComponent = ({ node, collapsed }) => (
        <GotView
            stack={stack}
            view={view.items}
            viewId={viewId}
            nodeId={node.id}
            getComponent={getComponent}
            collapsed={collapsed}
        />
    );

    if (Component) {
        return (
            <Component
                view={view}
                viewId={viewId}
                nodeId={R.propOr(nodeId, 'nodeId')(view)}
                nodes={nodes}
                addNode={node => node
                    ? add(view.edge)(nodeId)(node)
                    : add(view.edge)(nodeId)({ id: newId() })}
                deleteNode={remove(view.edge)(nodeId)}
                Item={ItemComponent}
            />
        );
    }

    return null;
};

const ViewLinkComponent = ({
    view, viewId, nodeId, Component,
}) => {
    const link = `/view/${view.viewNodeId}/${view.rootNodeId ? view.rootNodeId : nodeId}`;

    if (Component) {
        return (
            <Component
                view={view}
                viewId={viewId}
                nodeId={nodeId}
                value={link}
            />
        );
    }

    return null;
};
