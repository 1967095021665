import React, { useCallback, useEffect } from 'react';
import * as R from 'ramda';
import { assocPathMutate, useResult } from '@gothub-team/got-util';
import { SideBar } from './ViewScreenComponents';
import { ViewEditor } from './ViewEditor';
import {
    HeadingIcon,
    ListIcon,
    NumberIcon,
    TextAreaIcon,
    TextIcon,
    ToggleIcon,
    UploadIcon,
} from './Icons';
import { EditMode, ViewRootNodeId, ViewState } from './state';
import { useUserNodeId } from '../../../hooks/useUserNodeId';
import { createGraph } from '../../../got/hooks.config';
import { MAIN } from '../../../got/consts';
import { useAtom } from './hooks';
import { EdgeTypeCalculator } from './EdgeTypeCalculator';

const selectPullView = rootNodeId => viewState => {
    if (!rootNodeId) return undefined;
    const [getResult, , overResult] = useResult({
        [rootNodeId]: {
            include: {
                node: true,
            },
        },
    });
    const innerRecursion = (view, path) => {
        let newPath = path;
        if (view.edge) {
            newPath = [...path, 'edges', view.edge];
            overResult(
                assocPathMutate([...newPath, 'include'], {
                    node: true,
                    edges: true,
                    metadata: true,
                }),
            );
        }
        const children = R.propOr([], 'children', view);
        children.forEach(childView => innerRecursion(childView, newPath));
    };
    innerRecursion(viewState, [rootNodeId]);
    return getResult();
};

const components = [
    {
        label: 'Header',
        Icon: HeadingIcon,
        init: { type: 'header', label: undefined, name: undefined, initial: 'My Header' },
    },
    { label: 'Text', Icon: TextIcon, init: { type: 'text' } },
    { label: 'Text Area', Icon: TextAreaIcon, init: { type: 'textarea' } },
    { label: 'Number', Icon: NumberIcon, init: { type: 'numberField' } },
    { label: 'Toggle', Icon: ToggleIcon, init: { type: 'toggle' } },
    { label: 'Upload', Icon: UploadIcon, init: { type: 'uploadField' } },
    { label: 'List', Icon: ListIcon, init: { type: 'list', edge: 'from/to', label: 'List Name' } },
];

export const ViewScreen = () => {
    const { pull } = createGraph(MAIN);
    const userNodeId = useUserNodeId();
    const viewRootNodeId = useAtom(ViewRootNodeId);

    useEffect(() => {
        if (!ViewRootNodeId.get() && userNodeId) {
            ViewRootNodeId.set(userNodeId);
        }
    }, [userNodeId]);

    const pullView = useAtom(
        ViewState,
        useCallback(selectPullView(viewRootNodeId), [viewRootNodeId]),
    );

    const editMode = useAtom(EditMode);
    useEffect(() => {
        if (pullView) {
            pull(pullView);
        }
    }, [pullView]);

    return (
        <div className="flex h-full w-full dark:bg-got-grey dark:text-white">
            <SideBar editMode={editMode} components={components} />
            <div className="scrollbar flex flex-1 flex-col items-center overflow-y-scroll">
                <div className="box-border w-full max-w-3xl flex-1 shrink-0 py-4 px-8 sm:pr-4 md:w-full lg:py-8 lg:pr-6">
                    {viewRootNodeId && <ViewEditor nodeId={viewRootNodeId} />}
                </div>
            </div>
            <EdgeTypeCalculator />
        </div>
    );
};
