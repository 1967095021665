import * as R from 'ramda';

export const toastsReducer = (state = {}, action = {}) => {
    switch (action.type) {
        case 'SHOW_TOAST':
            return R.assoc(action.payload.name, action.payload, state);
        case 'HIDE_TOAST':
            return R.assoc(action.payload.name, false, state);
        default:
            return state;
    }
};
