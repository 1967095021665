import React, { useRef, useState } from 'react';

const SelectionItem = ({ background, label, selected, onClick }) => (
    <div className="flex flex-col" onClick={onClick}>
        <img
            className={`mx-auto h-14 w-14 rounded-lg ${
                selected ? 'border-r-lg border-4 border-fuchsia-500' : ''
            }`}
            src={background}
            alt={label}
        />
        <div className="layer-option">{label}</div>
    </div>
);

export const LayerSelection = ({ settings, setSettings }) => {
    const [isOpen, setIsOpen] = useState(false);
    const timerRef = useRef();

    return (
        <div
            onPointerEnter={() => {
                timerRef.current && clearTimeout(timerRef.current);
                setIsOpen(true);
            }}
            onPointerLeave={() => {
                timerRef.current = setTimeout(() => {
                    setIsOpen(false);
                }, 500);
            }}
            id="layers"
            className="absolute left-4 bottom-4 z-10 flex flex-row gap-4"
        >
            <div
                id="current-layer"
                className="border-r-lg h-24 w-24 rounded-lg border-2 border-white p-1 "
            >
                <div
                    className={`${
                        settings.renderMode === 'GraphView3d'
                            ? 'bg-[url("../public/threeDimensions.png")]'
                            : 'bg-[url("../public/twoDimensions.png")]'
                    } flex h-full w-full items-end bg-contain text-center align-middle text-sm text-white`}
                >
                    <div className="w-full">Layers</div>
                </div>
            </div>
            {isOpen && (
                <div
                    id="layer-options"
                    className=" border-r-lg z-10 flex h-24 flex-row gap-4 rounded-lg border-white bg-white px-4 pt-4 pb-2 text-xs"
                >
                    <SelectionItem
                        background="/threeDimensions.png"
                        label="3D-Graph"
                        selected={settings.renderMode === 'GraphView3d'}
                        onClick={() => setSettings({ ...settings, renderMode: 'GraphView3d' })}
                    />
                    <SelectionItem
                        background="/twoDimensions.png"
                        label="2D-Graph"
                        selected={settings.renderMode === 'GraphView2d'}
                        onClick={() => setSettings({ ...settings, renderMode: 'GraphView2d' })}
                    />
                </div>
            )}
        </div>
    );
};
