/* eslint-disable react/jsx-indent */
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/clouds_midnight';
import React, { useEffect, useState } from 'react';
import { JsonEditor } from 'jsoneditor-react';
import * as R from 'ramda';
import { createGraph, gotApi } from '../../got/hooks.config';
import { useLocalValue } from '../../hooks/useLocalValue';
import 'jsoneditor-react/es/editor.min.css';
import './GraphNodeModal.css';
import { useModalState } from '../../hooks/useModal';
import { NODE_DETAIL_MODAL } from '../../got/consts';
import { Modal } from '../Basics/Modal';
import { VerticalSpacer } from '../Basics/VerticalSpacer';
import { Tab } from '../Basics/Tab';
import { Toggle } from '../Basics/Toggle';

export const GraphNodeModal = ({ stack }) => {
    const [isOpen] = useModalState(NODE_DETAIL_MODAL);
    if (isOpen) {
        return <Content stack={stack} />;
    }

    return null;
};

export const isValidJSON = jsonString => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        console.log(e);

        return false;
    }
    return true;
};
const FileContent = ({ nodeId, stack }) => {
    const { pull, useFiles } = createGraph(...stack);
    const fileView = { [nodeId]: { include: { files: true } } };
    useEffect(() => {
        nodeId && pull(fileView);
    }, [nodeId]);
    const files = useFiles(nodeId);
    return <div>{files ? <div>Files</div> : 'No Files attached'}</div>;
};
const isAdmin = (email, rights) => R.pathOr(false, ['user', email, 'admin'], rights);

const RightsContent = ({ nodeId, stack, setError }) => {
    const { email } = gotApi.getCurrentUser();
    const [adminRights, setAdminRights] = useState(false);

    const { pull, useRights } = createGraph(...stack);
    const rightView = { [nodeId]: { include: { rights: true } } };

    useEffect(() => {
        nodeId && pull(rightView);
    }, [nodeId]);
    const rights = useRights(nodeId);

    useEffect(() => {
        setAdminRights(isAdmin(email, rights));
    }, [email, rights]);

    if (!adminRights) setError('No Admin rights');
    else setError('');

    return (
        <table className="w-full table-auto text-center">
            <thead>
                <tr>
                    <th>e-mail</th>
                    <th>Admin</th>
                    <th>Read</th>
                    <th>Write</th>
                </tr>
            </thead>
            <tbody>
                {rights
                    ? Object.entries(rights.user).map(([key, val]) => (
                          <tr>
                              <td className="flex flex-row text-left">
                                  {key === email ? (
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth={1.5}
                                          stroke="currentColor"
                                          className="my-auto mr-2 h-4 w-4"
                                      >
                                          <title>
                                              {!adminRights
                                                  ? 'Rights only editable as Admin'
                                                  : 'Own Rights not changeable'}
                                          </title>
                                          <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                          />
                                      </svg>
                                  ) : (
                                      ''
                                  )}
                                  {key}
                              </td>
                              <td>
                                  <Toggle
                                      disabled={key === email || !adminRights}
                                      value={val.admin}
                                      setValue={() => {}}
                                  />
                              </td>
                              <td>
                                  <Toggle
                                      disabled={key === email || !adminRights}
                                      value={val.read}
                                      setValue={() => {}}
                                  />
                              </td>
                              <td>
                                  <Toggle
                                      disabled={key === email || !adminRights}
                                      value={val.write}
                                      setValue={() => {}}
                                  />
                              </td>
                          </tr>
                      ))
                    : 'pls wait'}
            </tbody>
        </table>
    );
};
const Content = ({ stack }) => {
    const [selectedTab, setSelectedTab] = useState('code');
    const [error, setError] = useState('');
    const { useNode, update, push } = createGraph(...stack);
    const [nodeId, close] = useModalState(NODE_DETAIL_MODAL);
    const node = useNode(nodeId);
    const [localNode, setLocalNode] = useLocalValue(node);

    // TODO button to load files
    // TODO safe only clickable when changed
    // TODO button to load rights (see -> gothub org, providerConfigManager)
    // TODO bottom to delete node?

    return (
        <Modal error={error} title={`Node id: ${nodeId}`} onClose={close}>
            <div className="flex h-[70vh] w-[80vw] flex-col">
                <div className="flex flex-row">
                    <Tab
                        className="my-1 w-24"
                        selected={selectedTab === 'code'}
                        onClick={() => setSelectedTab('code')}
                    >
                        <div className="mx-auto text-center">Code</div>
                    </Tab>
                    <VerticalSpacer />
                    <Tab
                        className="my-1 w-24"
                        selected={selectedTab === 'tree'}
                        onClick={() => setSelectedTab('tree')}
                    >
                        <div className="mx-auto text-center">Tree</div>
                    </Tab>
                    <VerticalSpacer />
                    <Tab
                        className="my-1 w-24"
                        selected={selectedTab === 'files'}
                        onClick={() => setSelectedTab('files')}
                    >
                        <div className="mx-auto text-center">Files</div>
                    </Tab>
                    <VerticalSpacer />
                    <Tab
                        className="my-1 w-24"
                        selected={selectedTab === 'rights'}
                        onClick={() => setSelectedTab('rights')}
                    >
                        <div className="mx-auto text-center">Rights</div>
                    </Tab>
                </div>
                <div className="h-full">
                    {selectedTab === 'code' ? (
                        <JsonEditor
                            statusBar={false}
                            mode="code"
                            ace={ace}
                            value={localNode}
                            theme="ace/theme/clouds_midnight"
                            onChange={newNode => {
                                setLocalNode({ id: nodeId, ...newNode });
                            }}
                        />
                    ) : (
                        ''
                    )}
                    {selectedTab === 'tree' ? (
                        <JsonEditor
                            statusBar={false}
                            value={localNode}
                            theme="ace/theme/clouds_midnight"
                            onChange={newNode => {
                                setLocalNode({ id: nodeId, ...newNode });
                            }}
                        />
                    ) : (
                        ''
                    )}

                    {selectedTab === 'files' ? <FileContent stack={stack} nodeId={nodeId} /> : ''}

                    {selectedTab === 'rights' ? (
                        <RightsContent setError={setError} stack={stack} nodeId={nodeId} />
                    ) : (
                        ''
                    )}
                </div>
            </div>

            <div className="flex flex-row">
                <button
                    onClick={() => {
                        update(localNode);
                        push();
                    }}
                >
                    Save
                </button>
                <button className="border-red-300 bg-red-500 text-white" onClick={() => {}}>
                    Delete
                </button>
            </div>
        </Modal>
    );
};
