import React from 'react';

export const Tab = ({ className, selected, onClick, tooltip, children }) => (
    <div
        className={`${
            selected ? 'bg-white shadow' : ' hover:bg-white/[0.12] hover:text-blue-400'
        } flex cursor-pointer items-center rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${className}`}
        onClick={onClick}
        tooltip={tooltip}
    >
        {children}
    </div>
);
