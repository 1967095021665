import * as R from 'ramda';

export const modalsReducer = (state = {}, action = {}) => {
    switch (action.type) {
        case 'OPEN_MODAL':
            return R.assoc(action.name, action.payload || true, state);
        case 'CLOSE_MODAL':
            return R.assoc(action.name, false, state);
        default:
            return state;
    }
};
