import React, { useState } from 'react';
import * as R from 'ramda';

export const AuthScreen = ({ authActions, defaultEmailAdress, defaultState }) => {
    const [task, setTask] = useState({
        name: defaultState || 'login',
        email: defaultEmailAdress || '',
        password: '',
    });

    const [error, setError] = useState(null);

    const login = (email, password) =>
        authActions.login(email, password).catch(err => {
            switch (err.code || err.name) {
                case 'UserNotConfirmedException':
                case 'UserNotVerifiedError':
                    setTask({ name: 'activate', email });
                    break;
                case 'InvalidPasswordException':
                case 'UserNotFoundException':
                case 'InvalidEmailError':
                case 'LoginVerifyError':
                case 'UserNotFoundError':
                    setError('Username or password are not correct');
                    break;
                default:
                    setError(err);
                    break;
            }
        });
    const register = (email, password) =>
        authActions.register(email, password).catch(err => {
            switch (err.code || err.name) {
                case 'UsernameExistsException':
                case 'UserExistsError':
                    setError('Username is taken');
                    break;
                case 'InvalidEmailError':
                    setError(
                        'The email must be valid and must not contain upper case letters or spaces.',
                    );
                    break;
                case 'InvalidPasswordError':
                    setError(
                        'The password must contain at least 6 characters and at least 1 number.',
                    );
                    break;
                default:
                    setError(err);
                    break;
            }
        });
    const activate = code =>
        authActions
            .activate(task.email, code)
            .then(() => login(task.email, task.password))
            .catch(err => {
                switch (err.code || err.name) {
                    case 'CodeMismatchException':
                    case 'VerificationCodeMismatchError':
                        setError('The verification code does not match.');
                        break;
                    case 'VerificationCodeExpiredError':
                        setError('The verification code has exipired.');
                        break;
                    default:
                        setError(err);
                        break;
                }
            });
    const resendActivationCode = () => authActions.resendActivationCode(task.email);
    const forgotPassword = email => authActions.forgotPassword(email);
    const confirmPassword = (code, password) =>
        authActions.confirmPassword(task.email, code, password).catch(err => {
            switch (err.code || err.name) {
                case 'VerificationCodeMismatchError':
                    setError('The verification code does not match.');
                    break;
                case 'VerificationCodeExpiredError':
                    setError('The verification code has exipired.');
                    break;
                default:
                    setError(err);
                    break;
            }
        });

    return (
        <div className="centerContainer">
            <div className="centerBox">
                <div className="logo">
                    <span className="highlight-1">Got</span>
                    <span className="highlight-2">Hub</span>
                </div>
                <div
                    style={{
                        marginLeft: '15%',
                        marginRight: '15%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {task.name === 'login' && (
                        <Login
                            task={task}
                            error={error}
                            setTask={setTask}
                            login={login}
                            forgotPassword={forgotPassword}
                        />
                    )}
                    {task.name === 'register' && (
                        <Register task={task} error={error} setTask={setTask} register={register} />
                    )}
                    {task.name === 'forgotPassword' && (
                        <ForgotPassword
                            setTask={setTask}
                            error={error}
                            forgotPassword={forgotPassword}
                            confirmPassword={confirmPassword}
                        />
                    )}
                    {task.name === 'activate' && (
                        <Activate
                            setTask={setTask}
                            error={error}
                            resendCode={resendActivationCode}
                            activate={activate}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const Login = ({ task, error, login, forgotPassword, setTask }) => {
    const [email, setEmail] = useState(task.email || '');
    const [password, setPassword] = useState('');
    return (
        <>
            <h2 className="authscreen-title">Sign in</h2>
            {error && <h5 className="auth-error">{R.is(String, error) ? error : error.code}</h5>}
            <form
                className="loginForm"
                onSubmit={e => {
                    login(email, password);
                    e.preventDefault();
                }}
            >
                <input
                    placeholder="e-mail"
                    name="username"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="auth-input"
                />
                <input
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="auth-input"
                />
                <button
                    type="submit"
                    style={{ width: '70%', marginLeft: '0' }}
                    className="button button-blue"
                >
                    Sign in
                </button>
            </form>

            <button
                onClick={() =>
                    email && forgotPassword(email) && setTask({ name: 'forgotPassword', email })
                }
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                Forgot password
            </button>
            <button
                onClick={() => setTask({ name: 'register', email, password })}
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                Sign up
            </button>
        </>
    );
};

const Register = ({ task, error, setTask, register }) => {
    const [email, setEmail] = useState(task.email || '');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    return (
        <>
            <h2 className="authscreen-title">Sign up</h2>
            {error && <h5 className="auth-error">{R.is(String, error) ? error : error.code}</h5>}
            <form
                className="loginForm"
                onSubmit={e => {
                    password === passwordConfirm &&
                        register(email, password) &&
                        setTask({ name: 'activate', email, password });
                    e.preventDefault();
                }}
            >
                <input
                    placeholder="e-mail"
                    name="username"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    className="auth-input"
                />
                <input
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="auth-input"
                />
                <input
                    type="password"
                    name="password-confirm"
                    placeholder="confirm password"
                    value={passwordConfirm}
                    onChange={e => setPasswordConfirm(e.target.value)}
                    className="auth-input"
                />
                <button
                    type="submit"
                    style={{ width: '70%', marginLeft: '0' }}
                    className="button-blue"
                >
                    Sign up
                </button>
            </form>
            <button
                onClick={() => setTask({ name: 'login', email })}
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                Back to sign in
            </button>
        </>
    );
};

const ForgotPassword = ({ setTask, error, confirmPassword }) => {
    const [validationCode, setValidationCode] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    return (
        <>
            <h2 className="authscreen-title">Forgot password</h2>
            {error && <h5 className="auth-error">{R.is(String, error) ? error : error.code}</h5>}
            <form
                className="loginForm"
                onSubmit={e => {
                    password === passwordConfirm &&
                        confirmPassword(validationCode, password) &&
                        setTask({ name: 'login' });
                    e.preventDefault();
                }}
            >
                <input
                    placeholder="validation code"
                    name="validation-code"
                    value={validationCode}
                    onChange={e => setValidationCode(e.target.value)}
                    className="auth-input"
                />
                <input
                    type="password"
                    name="password"
                    placeholder="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="auth-input"
                />
                <input
                    type="password"
                    name="password-confirm"
                    placeholder="confirm password"
                    value={passwordConfirm}
                    onChange={e => setPasswordConfirm(e.target.value)}
                    className="auth-input"
                />
                <button
                    type="submit"
                    style={{ width: '70%', marginLeft: '0' }}
                    className="button-blue"
                >
                    Set new password
                </button>
            </form>
            <button
                onClick={() => setTask({ name: 'login' })}
                style={{ width: '70%', marginLeft: '0' }}
                className="button-quiet"
            >
                back to sign in
            </button>
        </>
    );
};

const Activate = ({ setTask, error, activate, resendCode }) => {
    const [activationCode, setActivationCode] = useState('');
    return (
        <>
            <h2 className="authscreen-title">Activate</h2>
            {error && <h5 className="auth-error">{R.is(String, error) ? error : error.code}</h5>}
            <form
                className="loginForm"
                onSubmit={e => {
                    setTask({ name: 'login' });
                    e.preventDefault();
                }}
            >
                <input
                    placeholder="activation code"
                    name="activation-code"
                    value={activationCode}
                    onChange={e => setActivationCode(e.target.value)}
                    className="auth-input"
                />
                <button
                    onClick={() => activationCode && activate(activationCode)}
                    style={{ width: '70%', marginLeft: '0' }}
                    className="button-blue"
                >
                    Activate
                </button>
                <button
                    onClick={() => resendCode()}
                    style={{ width: '70%', marginLeft: '0' }}
                    className="button-quiet"
                >
                    Resend code
                </button>
                <button
                    type="submit"
                    style={{ width: '70%', marginLeft: '0' }}
                    className="button-quiet"
                >
                    Back to sign in
                </button>
            </form>
        </>
    );
};
