// New implementation for view nodes

import * as R from 'ramda';
import { VIEWS_STACK, VIEWS_VIEW_EDGE } from '../got/consts';
import { createGraph } from '../got/hooks.config';
import { useUserViewsId } from './useUserNodeId';
import { ViewNodeId, ViewState } from '../Components/Screens/ViewScreen/state';

export const useViewFns = () => {
    const { update, add, push, pull, node } = createGraph(...VIEWS_STACK);
    const userViewsIds = useUserViewsId();

    const updateView = async () => {
        const id = ViewNodeId.get();
        if (!id) return;

        const view = ViewState.get() || {};
        update({ id, view });
        await push();
    };

    const addView = (id, name) => {
        add(VIEWS_VIEW_EDGE)(userViewsIds)({ id, name, view: {} });
    };

    const selectView = async (nodeId, doPull = true) => {
        if (!nodeId || !R.is(String, nodeId)) return;

        let viewNode = node(nodeId);
        if (doPull && (!viewNode || R.isEmpty(viewNode))) {
            await pull({
                [nodeId]: {
                    include: { node: true },
                },
            });
            viewNode = node(nodeId);
        }

        if (!viewNode || R.isEmpty(node)) return;

        ViewNodeId.set(nodeId);
        ViewState.set(viewNode.view || {});
    };

    return {
        updateView,
        addView,
        pushViews: push,
        selectView,
    };
};
