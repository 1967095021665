/* eslint-disable no-shadow */
import React from 'react';
import InView from 'react-intersection-observer';
import setup from './Assets/setup.png';
import gotViews from './Assets/got-views.png';
import gotViewResult from './Assets/got-view-result.png';
import gotFunction from './Assets/got-functions.png';
import { OpenSourceCard } from './OpenSourceCard';

export const DevContent = () => (
    <>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Everything your app needs</h1>
                        <div className="badges">
                            <div className="badge">
                                <ion-icon name="git-network-outline" />
                                <div>Database</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="key-outline" />
                                <div>Authentification</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="lock-open-outline" />
                                <div>Permission Management</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="attach-outline" />
                                <div>File Hosting</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="logo-react" />
                                <div>React Integration</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>Setup in one simple step</h1>
                        <div className="container parkett">
                            <div className="container horizontal">
                                <img src={setup} alt="Setup of Auth and API" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Setup hostname of
                                            {' '}
                                            <code>got</code>
                                            {' '}
                                            provider
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Connect to redux store
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Export
                                            {' '}
                                            <code>api</code>
                                            {' '}
                                            for authentication and direct API access
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Leverage
                                            {' '}
                                            <code>useGraph</code>
                                            {' '}
                                            React hook
                                            {' '}
                                            for efficient graph selection and mutation
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Easy to learn</h1>
                        <div className="container parkett">
                            <div className="container horizontal">
                                <img src={gotViews} alt="Example for views" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Intuitive query language using
                                            &nbsp;
                                            <code>views</code>
                                                    &nbsp;
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Use
                                            &nbsp;
                                            <code>as</code>
                                            &nbsp;
                                            alias to be able to better navigate the view results
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Only the data set to
                                            &nbsp;
                                            <code>include</code>
                                            &nbsp;
                                            in the view above will be loaded by the selector
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <img src={gotViewResult} alt="Example for view results" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Aliases for nodes and edges are used as keys in view results
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Easy access to a nodes children by iterating through
                                            &nbsp;
                                            <code>todoElements</code>
                                                    &nbsp;
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            The
                                            &nbsp;
                                            <code>bags</code>
                                            &nbsp;
                                            contain all relevant information of the node, such as the
                                            &nbsp;
                                            <code>node</code>
                                            , edge
                                            &nbsp;
                                            <code>metadata</code>
                                            ,
                                            &nbsp;
                                            <code>rights</code>
                                            &nbsp;
                                            or
                                            &nbsp;
                                            <code>files</code>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <img src={gotFunction} alt="Example for use of got functions" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Easy to use functions for all necessary graph operations
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Update nodes, add edges, set rights or attach files
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Use currying to configure functions
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>Stack system</h1>
                        <div className="container">
                            <div className="badges horizontal">
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>
                                        Complete datasets are managed in the
                                        {' '}
                                        <code>main</code>
                                        {' '}
                                        graph in the stack
                                    </div>
                                </div>
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>Layer additional graphs to manage changesets of editor components</div>
                                </div>
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>Changesets can be pushed or discarded</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Performance oriented</h1>
                        <div className="container">
                            <div className="badges horizontal">
                                <div className="badge large silent">
                                    <ion-icon name="search-outline" />
                                    <div>Select exactly which data you want to load from the database</div>
                                </div>
                                <div className="badge large silent">
                                    <ion-icon name="timer-outline" />
                                    <div>Hooks to avoid unnecessary rerenders on unchanged data</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <OpenSourceCard />
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Fair Pricing</h1>
                        <div className="pricing">
                            <div className="box">
                                <ul>
                                    <li>
                                        <ion-icon name="checkmark" />
                                        <span>Only pay what you use</span>
                                    </li>
                                    <li>
                                        <ion-icon name="checkmark" />
                                        <span>Cancel any time</span>
                                    </li>
                                    <li>
                                        <ion-icon name="checkmark" />
                                        <span>Automatic downgrade</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>Free Package</h2>
                                <h3>
                                    <span className="success">0 €</span>
                                    &nbsp;
                                    per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        250 MB of storage
                                    </li>
                                    <li>
                                        <ion-icon name="push-outline" />
                                        1000 push actions per month
                                    </li>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        10000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>Additional Package</h2>
                                <h3>
                                    <span className="success">1 €</span>
                                    &nbsp;
                                    per package per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        250 MB of storage
                                    </li>
                                    <li>
                                        <ion-icon name="push-outline" />
                                        1000 push actions per month
                                    </li>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        10000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
    </>
);
