import { doViewGraph } from '@gothub-team/got-core';
import { useResult } from '@gothub-team/got-util';
import * as R from 'ramda';
import { gotStore } from '../got/hooks.config';

const defaultColors = ['#0d326e', '#19b348', '#f6e314', '#ef7a20', '#e00d0d', '#b20d70', '#8215c1'];

const COLORING_KEY = 'coloring';

export const colorizeView = (view, colors = defaultColors) => {
    const _INNER_RECURSION = (viewObj, index) =>
        R.compose(
            R.when(
                R.has('edges'),
                R.over(
                    R.lensProp('edges'),
                    R.when(
                        R.identity,
                        R.map(edgeViewObj => _INNER_RECURSION(edgeViewObj, index + 1)),
                    ),
                ),
            ),
            R.assoc(COLORING_KEY, R.nth(index, colors)),
        )(viewObj);

    return R.map(viewObj => _INNER_RECURSION(viewObj, 0))(view);
};

export const selectViewNodeColoring =
    (...stack) =>
    view =>
    state => {
        const [getNodeColoring, setNodeColoring] = useResult({});
        const stackGetEdgeToIds = (fromType, nodeId, toType, { reverse } = {}) =>
            reverse
                ? gotStore.selectReverseEdge(...stack)(`${fromType}/${toType}`)(nodeId)(state)
                : gotStore.selectEdge(...stack)(`${fromType}/${toType}`)(nodeId)(state);
        doViewGraph(
            {
                nodes: (queryObj, nodeId) => {
                    setNodeColoring(R.assoc(nodeId, R.prop(COLORING_KEY, queryObj)));
                },
            },
            view,
            stackGetEdgeToIds,
        );

        return getNodeColoring();
    };
