import * as R from 'ramda';
import React, { useCallback, useMemo } from 'react';
import { ViewLabel, ViewEditorChildButtons, DropZone } from './ViewEditorComponents';
import { Header, List, NumberField, Text, TextArea, Toggle, UploadField } from './ViewComponents';
import { EditMode, EditPath, ViewState } from './state';
import { useAtom } from './hooks';

const selectViewChildrenNames = childrenPath =>
    R.pipe(R.pathOr([], childrenPath), R.map(R.prop('name')));

export const ViewEditor = ({ nodeId, className, path = [] }) => {
    const childrenPath = useMemo(() => R.append('children', path), [path]);
    const childrenNames = useAtom(
        ViewState,
        useCallback(selectViewChildrenNames(childrenPath), [childrenPath]),
    );
    const editMode = useAtom(EditMode);

    const childPath = i => R.append(i, childrenPath);

    return (
        <div className={`${className} border border-transparent`}>
            {R.length(childrenNames) === 0 && (
                <div className="relative mt-2 flex flex-col gap-1">
                    <div className="text-sm font-medium">
                        <div className="pl-1 pr-1">No Components</div>
                    </div>
                    <div className="m-0 w-full rounded-lg p-2.5 text-base dark:bg-got-dark-grey dark:text-got-light">
                        Drop components here.
                    </div>
                </div>
            )}
            <DropZone path={childrenPath} index={0} />
            {childrenNames.map((name, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${i}_${name}`} className="relative mt-2 flex flex-col gap-1">
                    <div className="flex flex-row items-center justify-between">
                        <ViewLabel path={childPath(i)} />
                    </div>
                    <div className="right absolute -top-0.5 -left-[30px] flex cursor-pointer select-none flex-col gap-3">
                        {editMode && <ViewEditorChildButtons path={childPath(i)} />}
                    </div>
                    <div>
                        <ViewSwitch
                            path={childPath(i)}
                            nodeId={nodeId}
                            renderItem={_nodeId =>
                                _nodeId ? (
                                    <ViewEditor
                                        key={_nodeId}
                                        nodeId={_nodeId}
                                        path={childPath(i)}
                                    />
                                ) : editMode ? (
                                    'Add list item to insert components here.'
                                ) : (
                                    'No items.'
                                )
                            }
                        />
                    </div>
                    <DropZone path={childrenPath} index={i + 1} />
                </div>
            ))}
        </div>
    );
};

const ViewSwitch = ({
    path,
    nodeId,
    components = {
        header: Header,
        text: Text,
        textarea: TextArea,
        numberField: NumberField,
        uploadField: UploadField,
        toggle: Toggle,
        list: List,
    },
    renderItem,
}) => {
    const view = useAtom(ViewState, useCallback(R.pathOr({}, path), [path]));
    const editPath = useAtom(EditPath);
    const globalEditMode = useAtom(EditMode);
    const editMode = globalEditMode && R.equals(path, editPath);
    const assocViewPath = (_path, value) => R.assocPath(R.concat(path, _path), value);

    const Component = components[view.type];
    if (Component) {
        return (
            <Component
                view={view}
                edge={view.edge}
                nodeId={nodeId}
                editMode={editMode}
                editView={(_path, value) => ViewState.set(assocViewPath(_path, value))}
                renderItem={view.edge ? renderItem : undefined}
            />
        );
    }
    return null;
};
