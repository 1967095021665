import './App.css';
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from './common/useAuth';
import { AuthScreen } from './Components/Screens/AuthScreen';
import { GraphScreen } from './Components/Screens/GraphScreen';
import { GotViewScreenStandalone } from './Components/Screens/GotViewScreen';
import { useUserEmail } from './hooks/useUserEmail';
import { FootNotes } from './Components/Elements/FootNotes';
import PrivacyPage from './Components/Screens/PrivacyPage';
import ImprintPage from './Components/Screens/ImprintPage';
import { LandingPagesScreen } from './Components/Screens/Landingpages/LandingPagesScreen';
import { ViewScreen } from './Components/Screens/ViewScreen/ViewScreen';

const App = () => {
    const [initializing, loggedIn, authActions] = useAuth();
    const userEmail = useUserEmail(loggedIn);
    const logout = async () => {
        authActions.logout().then(() => {
            setEmailAdress('');
            setAuthState(null);
        });
    };
    const [queryString, setQueryString] = useState('debug-data/child');
    const [emailAdress, setEmailAdress] = useState('');
    const [authState, setAuthState] = useState(null);

    if (initializing) {
        // display empty page while still checking if logged in
        return <div className="app" />;
    }

    return (
        <div className="app">
            <Routes>
                <Route exact path="/privacy" element={<PrivacyPage />} />
                <Route exact path="/imprint" element={<ImprintPage />} />
                <Route
                    exact
                    path="/signin"
                    element={
                        <AuthScreen
                            authActions={authActions}
                            defaultEmailAdress={emailAdress}
                            defaultState={authState}
                        />
                    }
                />
                <Route
                    path="/*"
                    element={<LandingPagesScreen loggedIn={loggedIn} logout={logout} />}
                />
                {loggedIn && (
                    <>
                        <Route
                            exact
                            path="/view"
                            element={
                                <ViewScreen
                                    queryString={queryString}
                                    logout={logout}
                                    setQueryString={setQueryString}
                                />
                            }
                        />
                        <Route
                            exact
                            path="/graph"
                            element={
                                <GraphScreen
                                    queryString={queryString}
                                    logout={logout}
                                    setQueryString={setQueryString}
                                />
                            }
                        />
                        <Route exact path="/view" element={<ViewScreen />} />
                        <Route
                            path="/view/:viewNodeId/:rootNodeId"
                            element={
                                <>
                                    <GotViewScreenStandalone singleView />
                                    <FootNotes
                                        loggedIn={loggedIn}
                                        login={() => setAuthState('login')}
                                        logout={logout}
                                        userEmail={userEmail}
                                    />
                                </>
                            }
                        />
                    </>
                )}
            </Routes>
        </div>
    );
};

export default App;
