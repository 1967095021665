import * as R from 'ramda';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import { MAIN } from '../../../got/consts';
import { createGraph } from '../../../got/hooks.config';
import { sortBagsByOrder } from '../../../util/util';

export const useNode = (nodeId, selector) => {
    const { pull, useNode: useGotNode, push, update } = createGraph(MAIN, nodeId);
    const view = {
        [nodeId]: {
            as: 'node',
            include: { node: true },
        },
    };
    const result = useGotNode(nodeId, selector);
    return {
        pull: () => pull(view),
        update: nodePatch => update({ ...nodePatch, id: nodeId }),
        push,
        result,
        view,
    };
};

const createEdgeView = (nodeId, edge) =>
    nodeId && edge
        ? {
              [nodeId]: {
                  as: 'from',
                  edges: {
                      [edge]: {
                          as: 'to',
                          include: {
                              edges: true,
                              metadata: true,
                          },
                      },
                  },
              },
          }
        : {};

const selectToIds = R.compose(
    R.map(R.prop('nodeId')),
    sortBagsByOrder,
    R.pathOr({}, ['from', 'to']),
);

export const useEdgeIds = (nodeId, edge) => {
    const { useView, add: addRaw, push, remove: removeRaw } = createGraph(MAIN, nodeId);
    const view = useMemo(() => createEdgeView(nodeId, edge), [nodeId, edge]);

    const toIds = useView(view, selectToIds);

    const add = addRaw(edge)(nodeId);
    const addAndPush = (node, metadata) => {
        add(node, metadata);
        return push();
    };
    const remove = removeRaw(edge)(nodeId);
    const removeAndPush = node => {
        remove(node);
        return push();
    };

    return {
        push,
        toIds,
        add,
        addAndPush,
        remove,
        removeAndPush,
    };
};

const createFilesView = nodeId =>
    nodeId
        ? {
              [nodeId]: {
                  as: 'node',
                  include: {
                      files: true,
                  },
              },
          }
        : {};

// TODO reenable selector
export const useFiles = nodeId => {
    const {
        useFiles: useGotFiles,
        pull: pullRaw,
        push,
        setFile: setFileRaw,
    } = createGraph(MAIN, nodeId);
    const pull = async () => pullRaw(createFilesView(nodeId));

    const result = useGotFiles(nodeId);

    const setFile = setFileRaw(nodeId);

    const pushAndUpload = async () => {
        const { uploads } = await push();
        await uploads.start();
        return pull();
    };

    return {
        pull,
        push,
        pushAndUpload,
        setFile,
        result,
    };
};

export const atom = initialValue => {
    const value = { current: initialValue };
    const subscribers = [];
    return {
        value,
        get: () => value.current,
        set: valueOrFnValue => {
            value.current =
                typeof valueOrFnValue === 'function'
                    ? valueOrFnValue(value.current)
                    : valueOrFnValue;
            subscribers.forEach(
                subscriber =>
                    typeof subscriber.next === 'function' && subscriber.next(value.current),
            );
        },
        subscribe: subscriber => subscribers.push(subscriber),
        unsubscribe: subscriber => subscribers.splice(subscribers.indexOf(subscriber), 1),
    };
};

export const useCreateAtom = initialValue => useMemo(() => atom(initialValue), []);

export const useAtom = ({ value, subscribe, unsubscribe }, selector = s => s) => {
    const localValue = useRef(selector(value.current));
    const [, forceUpdate] = useReducer(() => ({}), {});

    const selectorRef = useRef();
    if (selector !== selectorRef.current) {
        selectorRef.current = selector;
        const updatedValue = selector(value.current);
        if (!R.equals(localValue.current, updatedValue)) {
            localValue.current = updatedValue;
        }
    }

    useEffect(() => {
        const subscriber = {
            next: newValue => {
                const updatedValue = selectorRef.current(newValue);
                if (!R.equals(localValue.current, updatedValue)) {
                    localValue.current = updatedValue;
                    forceUpdate();
                }
            },
        };
        subscribe(subscriber);
        return () => unsubscribe(subscriber);
    }, []);

    return localValue.current;
};
