import React from 'react';
import { animated, useSpring } from 'react-spring';
import { ViewScreen } from '../Screens/ViewScreen/ViewScreen';

export const FlyOut = ({ isOpen, setIsOpen, fullscreen, setFullscreen }) => {
    const flyoutAnimation = useSpring({
        width: fullscreen ? '100vw' : '40vw',
        right: isOpen || fullscreen ? '0vw' : '-40vw',
    });

    return (
        <div className="flyout">
            <animated.div className="flyout-container max-h-[100vh]" style={flyoutAnimation}>
                <div className="container-border" style={{ border: fullscreen && 'none' }}>
                    <div className="flex max-h-full">
                        <div
                            className="flyout-bar"
                            style={{
                                left:
                                    !isOpen && !fullscreen ? -41 : !isOpen && fullscreen ? 0 : -39,
                            }}
                        >
                            <div className="open-close-buttons">
                                {isOpen && !fullscreen ? (
                                    <div className="fullscreen">
                                        <button
                                            onClick={() => {
                                                setIsOpen(false);
                                            }}
                                        >
                                            <ion-icon
                                                class="graph-icons"
                                                name="chevron-forward-outline"
                                            />
                                        </button>
                                    </div>
                                ) : fullscreen && !isOpen ? (
                                    <button
                                        onClick={() => {
                                            setIsOpen(true);
                                            setFullscreen(false);
                                        }}
                                    >
                                        <ion-icon
                                            class="graph-icons"
                                            name="chevron-forward-outline"
                                        />
                                    </button>
                                ) : (
                                    <button
                                        className="open-button"
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}
                                    >
                                        <ion-icon class="graph-icons" name="chevron-back-outline" />
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="flyout-area">
                            <ViewScreen />
                        </div>
                    </div>
                </div>
            </animated.div>
        </div>
    );
};
