import { isMetadataPath, mergeGraphsLeft, selectPathFromStack } from '@gothub-team/got-core';
import { reduceObj } from '@gothub-team/got-util';
import * as R from 'ramda';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BASE_STATE } from './consts';

export const selectGraph =
    (...stack) =>
    state =>
        selectPathFromStack(['graph'], stack, mergeGraphsLeft, state);

export const useSelectGraph = (stack, selector = R.identity) => {
    const _selector = useCallback(
        R.compose(selector, selectGraph(...stack), R.propOr({}, BASE_STATE)),
        [stack, selector],
    );

    return useSelector(_selector, R.equals);
};

// TODO use assocMutate
export const selectEdgetypesFromGraph = R.compose(
    R.uniq,
    R.append('from'),
    R.append('to'),
    R.keys,
    R.head,
    reduceObj([
        [
            (val, path) => isMetadataPath(path),
            (val, [, fromType, , toType]) =>
                R.compose(R.assoc(toType, true), R.assoc(fromType, true)),
        ],
    ]),
);

export const areGraphInsidesEmpty = R.and(
    R.isEmpty(
        R.prop('nodes'),
        R.isEmpty(R.prop('edges')),
        R.isEmpty(R.prop('rights')),
        R.isEmpty(R.prop('files')),
    ),
);
export const isGraphEmpty = R.or(R.isEmpty, areGraphInsidesEmpty);

export const viewFromString = (rootId, queryString) => {
    if (!rootId) {
        return {};
    }

    const _INNER_RECURSION = arr => {
        if (R.length(arr) >= 2) {
            return {
                [`${R.nth(0, arr)}/${R.nth(1, arr)}`]: {
                    include: {
                        node: true,
                        edges: true,
                        metadata: true,
                    },
                    edges: _INNER_RECURSION(R.tail(arr)),
                },
            };
        }
        return undefined;
    };

    const queryArray = R.split('/', queryString);
    return {
        [rootId]: {
            include: {
                node: true,
            },
            edges: _INNER_RECURSION(queryArray),
        },
    };
};
