/* eslint-disable no-shadow */
import React from 'react';
import InView from 'react-intersection-observer';
import graphEditorPng from './Assets/graph-editor.png';
import jsonNodePng from './Assets/json-node.png';
import stringEdgesPng from './Assets/string-edges.png';
import todoApp from './Assets/todo-app.png';
import todoListEdge from './Assets/todo-list-edge.png';
import todoListProp from './Assets/todo-list-prop.png';
import { OpenSourceCard } from './OpenSourceCard';

export const HomeContent = () => (
    <>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="technology">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Visual Graph Editor</h1>
                        <div className="container parkett">
                            <div className="container horizontal">
                                <img src={graphEditorPng} alt="Visual Graph Editor" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Edit raw data directly
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Create
                                            {' '}
                                            <span className="highlight-1">nodes</span>
                                            {' '}
                                            and connect them with
                                            {' '}
                                            <span className="highlight-1">edges</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <img src={jsonNodePng} alt="Visual Graph Editor" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Node contents are nothing more than
                                            {' '}
                                            <span className="highlight-1">JSON objects</span>
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Nodes have
                                            {' '}
                                            <span className="highlight-1">no static data type</span>
                                            . You can put any JSON object as long it has an
                                            {' '}
                                            <code>id</code>
                                            {' '}
                                            property
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <img src={stringEdgesPng} alt="Visual Graph Editor" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Edges have types in form of
                                            {' '}
                                            <span className="highlight-1">string keys</span>
                                            {' '}
                                            which define the role of two nodes in their relationship
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Edges are directed
                                            {' '}
                                            <span className="highlight-1">from</span>
                                            {' '}
                                            one node
                                            {' '}
                                            <span className="highlight-1">to</span>
                                            {' '}
                                            another
                                        </div>
                                    </div>
                                    {/* <div className="badge silent small">
                                            <ion-icon name="ellipse-outline" />
                                            <div>
                                                Upload and attach files to your nodes
                                            </div>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>View Editor</h1>
                        <div className="container parkett">
                            <div className="container horizontal">
                                <img src={todoApp} alt="Visual Graph Editor" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Define a
                                            &nbsp;
                                            <code>todo</code>
                                            &nbsp;
                                            view
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Add an edge component
                                            &nbsp;
                                            <code>todo-app/todo-list</code>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <img src={todoListEdge} alt="Visual Graph Editor" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Nodes created with this component are automatically associated with a
                                            &nbsp;
                                            <code>todo-app -&gt; todo-list</code>
                                            &nbsp;
                                            edge from the
                                            &nbsp;
                                            <code>todo-app</code>
                                            &nbsp;
                                            node
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            This represents a
                                            &nbsp;
                                            <code>1-n</code>
                                            &nbsp;
                                            relationship
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Edge components act as a container for a sub view
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <img src={todoListProp} alt="Visual Graph Editor" />
                                <div className="badges horizontal">
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Add a property component
                                            &nbsp;
                                            <code>name</code>
                                            &nbsp;
                                            to the edge component we created earlier
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            This component gives potential read and write access to the
                                            &nbsp;
                                            <code>{'{"name": ...}'}</code>
                                            &nbsp;
                                            property of each
                                            &nbsp;
                                            <code>todo-list</code>
                                            &nbsp;
                                            node added or associated along the edge component
                                        </div>
                                    </div>
                                    <div className="badge silent small">
                                        <ion-icon name="ellipse-outline" />
                                        <div>
                                            Remember that the
                                            &nbsp;
                                            <code>todo-list</code>
                                            &nbsp;
                                            semantic of the node is not known by the node itself but only by the edge that points to it
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Own Your Data</h1>
                        <div className="container">
                            <div className="badges horizontal">
                                <div className="badge large silent">
                                    <ion-icon name="share-social-outline" />
                                    <div>You have full control over the integrity and quality of your data</div>
                                </div>
                                <div className="badge large silent">
                                    <ion-icon name="people-outline" />
                                    <div>You control who who can read, write or manage your nodes</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>Use Views in Multiple Diverse Contexts</h1>
                        <div className="badges">
                            <div className="badge">
                                <ion-icon name="browsers-outline" />
                                <div>Web Browser</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="phone-portrait-outline" />
                                <div>Mobile Devices</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="terminal-outline" />
                                <div>Command Line Interface (CLI)</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="logo-react" />
                                <div>Custom React Apps</div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <OpenSourceCard />
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>Fair Pricing</h1>
                        <div className="pricing">
                            <div className="box">
                                <ul>
                                    <li>
                                        <ion-icon name="checkmark" />
                                        <span>Only pay what you use</span>
                                    </li>
                                    <li>
                                        <ion-icon name="checkmark" />
                                        <span>Cancel any time</span>
                                    </li>
                                    <li>
                                        <ion-icon name="checkmark" />
                                        <span>Automatic downgrade</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>Free Package</h2>
                                <h3>
                                    <span className="success">0 €</span>
                                    &nbsp;
                                    per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        250 MB of storage
                                    </li>
                                    <li>
                                        <ion-icon name="push-outline" />
                                        1000 push actions per month
                                    </li>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        10000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>Additional Package</h2>
                                <h3>
                                    <span className="success">1 €</span>
                                    &nbsp;
                                    per package per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        250 MB of storage
                                    </li>
                                    <li>
                                        <ion-icon name="push-outline" />
                                        1000 push actions per month
                                    </li>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        10000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
    </>
);
