import React, { useState } from 'react';
import { colorizeView, selectViewNodeColoring } from '../../util/colormap.util';
import { exampleGraph } from '../../util/exampleGraph';
import { GraphView3d } from './GraphView3d';

const view = {
    recipeAppNode: {
        include: {
            node: true,
        },
        edges: {
            'recipeApp/recipe': {
                include: {
                    edge: true,
                    node: true,
                },
                edges: {
                    'recipe/ingredient': {
                        include: {
                            edge: true,
                            node: true,
                        },
                    },
                },
            },
        },
    },
};
const colorizedView = colorizeView(view);
const colorMap = selectViewNodeColoring('exampleGraph')(colorizedView)({
    exampleGraph: { graph: exampleGraph },
});

export const _LandingPageGraph = ({ height }) => {
    const [displayWidth] = useState(window.innerWidth);
    const canvasWidth = displayWidth * 1.5;

    return (
        <GraphView3d
            graph={exampleGraph}
            colorMap={colorMap}
            settings={{
                showLinkLabels: false,
                enableForce: true,
                enableBloom: true,
                enableParticles: true,
                labelKey: 'label',
            }}
            height={height}
            width={canvasWidth}
            viewOnly
            defaultCameraPosition={{ x: 200, y: 200, z: 200 }}
            defaultLookAtPosition={{ x: 0, y: 0, z: 0 }}
        />
    );
};

export const LandingPageGraph = React.memo(_LandingPageGraph);
