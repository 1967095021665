import React from 'react';
import { LandingPageFooter } from './Landingpages/LandingPageFooter';

const ImprintPage = () => (
    <div className="landing-page">
        <div className="container">
            <div className="content">
                <h1>GotHub – Impressum</h1>
                <p>Angaben gemäß § 5 TMG</p>
                <p>GotHub UG (haftungsbeschränkt) </p>
                <p>Geschäftsführer: Samuel Vincenz Koop</p>
                <p>Adresse: Dunckerstraße 33, 10439 Berlin </p>
                <p>Telefon: +49 176 62656439</p>
                <p>
                    E-Mail:
                    &nbsp;
                    <a href="mailto:info@gothub.io">info@gothub.io</a>
                </p>
                <h2>Vertreten durch:</h2>
                <p>Samuel Vincenz Koop</p>
                <p>Telefon: +49 176 62656439</p>
                <p>
                    E-Mail:
                    &nbsp;
                    <a href="mailto:vincenz.koop@gothub.io">vincenz.koop@gothub.io</a>
                </p>
                <h3>Handelsregister:</h3>
                <p>AG Charlottenburg, HRB 199616</p>
                <h3>Umsatzsteuer-ID:</h3>
                <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE320512794</p>
                <h2>Haftungsausschluss:</h2>
                <h3>Haftung für Inhalte</h3>
                <p>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
                    Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1
                    TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
                    wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
                    überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                    Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                    möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.

                </p>
                <h3>Haftung für Links</h3>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb
                    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                    stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                    Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
                    der
                    Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                    konkrete
                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Links umgehend entfernen.

                </p>
                <h2>Urheberrecht</h2>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                    Grenzen
                    des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads
                    und
                    Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
                    auf
                    dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                    werden
                    Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                    werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Inhalte umgehend entfernen.

                </p>
            </div>
        </div>
        <LandingPageFooter />
    </div>
);

export default ImprintPage;
