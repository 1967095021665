import React from 'react';
import { Switch } from '@headlessui/react';

export const Toggle = ({ label, setValue, value, disabled = false }) => (
    <div className="flex flex-row gap-2">
        <Switch
            checked={value}
            onChange={!disabled ? setValue : () => {}}
            className={`${
                value && !disabled ? 'bg-blue-600' : 'bg-gray-200'
            } p-0 m-0 relative inline-flex h-6 w-11 items-center rounded-full`}
        >
            <span
                className={`${
                    value ? 'translate-x-6' : 'translate-x-1'
                } ${!disabled ? 'bg-white' : 'bg-gray-400'} inline-block h-4 w-4 transform rounded-full  transition`}
            />
        </Switch>
        <div className="my-auto text-center text-sm">{label}</div>
    </div>

);
