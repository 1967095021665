import React, { useState } from 'react';
import * as R from 'ramda';
import { useLocalValue } from '../../../hooks/useLocalValue';
import { useUserSettings } from '../../../hooks/useUserNode';
import { VerticalSpacer } from '../../Basics/VerticalSpacer';
import { Settings } from './Settings';
import { viewFromString } from '../../../got/got-adjunct';
import { useUserNodeId } from '../../../hooks/useUserNodeId';
import { createGraph, gotApi, gotStore } from '../../../got/hooks.config';
import { HorizontalSpacer } from '../../Basics/HorizontalSpacer';
import { useOpenModal } from '../../../hooks/useModal';
import { BOOKMARK_MODAL, MAIN } from '../../../got/consts';
import { BookmarkIcon, SearchIcon, SettingsIcon } from '../../Screens/ViewScreen/Icons';
import { ActiveScope } from '../../Screens/ViewScreen/state';

const isValidScopeString = str =>
    str && R.length(str) > 1 && R.endsWith('.', str) && !R.includes('/', str);
const isValidSearchString = R.compose(R.all(R.identity), R.split('/'));

const createScopeView = scope => ({
    [`${scope}*`]: {
        include: {
            node: true,
        },
        edges: {
            '*': {
                include: {
                    node: true,
                    edges: true,
                    metadata: true,
                },
            },
        },
    },
});

const Option = ({ selected, onPointerEnter, onPointerDown, children }) => (
    <div
        className={`${selected ? 'bg-gray-200' : ''} flex cursor-pointer flex-row gap-2 p-2`}
        onPointerEnter={onPointerEnter}
        onPointerDown={onPointerDown}
    >
        <SearchIcon className="my-auto h-6 w-6" />
        <div className="my-auto">{children}</div>
    </div>
);

const SearchInput = ({
    setFocused,
    selectedIndex,
    setSelectedIndex,
    onEnter,
    onDelete,
    localValue,
    setLocalValue,
    submitSearch,
}) => (
    <form
        className="my-auto"
        onSubmit={e => {
            e.preventDefault();
            submitSearch(localValue);
            setFocused(false);
        }}
    >
        <input
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={e => {
                if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setSelectedIndex(selectedIndex - 1);
                } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setSelectedIndex(selectedIndex + 1);
                } else if (e.key === 'Enter' && selectedIndex > 0) {
                    e.preventDefault();
                    onEnter();
                } else if (e.key === 'Delete' && selectedIndex > 0) {
                    e.preventDefault();
                    onDelete();
                } else if (e.key === 'Escape') {
                    e.preventDefault();
                    setSelectedIndex(0);
                    setFocused(false);
                }
            }}
            value={localValue}
            onChange={e => {
                setFocused(true);
                setSelectedIndex(0);
                setLocalValue(e.target.value);
                isValidScopeString(e.target.value)
                    ? ActiveScope.set(e.target.value)
                    : ActiveScope.set(false);
            }}
            placeholder="Search GotHub"
            className="my-auto bg-gray-100"
        />
    </form>
);

const useIndex = max => {
    const [selectedIndex, _setSelectedIndex] = useState(0);
    const setSelectedIndex = val => {
        _setSelectedIndex((val + max) % max);
    };

    return [selectedIndex, setSelectedIndex];
};

const filterOptions = (searchStr, options) =>
    R.compose(R.sortBy(R.identity), R.filter(R.includes(searchStr)))(options);

const Bar = ({ stack, settingsOpen, setSettingsOpen, children }) => {
    const { pull } = createGraph(...stack);

    // TODO this returns undefined on initialize, likely error in got
    const [searchHistory] = useUserSettings(R.propOr([], 'searchHistory'));
    const [queryString, patchSettings] = useUserSettings(R.propOr('', 'queryString'));
    // TODO replace with custom root id
    const userNodeId = useUserNodeId();

    const [localValue, setLocalValue] = useLocalValue(queryString);
    const filteredHistory = filterOptions(localValue, searchHistory || []);
    const [selectedIndex, setSelectedIndex] = useIndex(filteredHistory.length + 1);
    const [focused, setFocused] = useState(false);

    const submitSearch = async str => {
        if (isValidScopeString(str)) {
            const view = createScopeView(str);
            const graph = await gotApi.pull(view);
            gotStore.mergeOverwriteGraph(graph, MAIN);
        } else if (userNodeId && isValidSearchString(str)) {
            const view = viewFromString(userNodeId, str);
            const newSearchHistory = R.compose(R.uniq, R.take(50), R.prepend(str))(searchHistory);
            patchSettings({ queryString: str, searchHistory: newSearchHistory });
            pull(view);
        }
    };

    const openBookmarkModal = useOpenModal(BOOKMARK_MODAL);

    return (
        <div className="relative z-10 flex flex-col content-center rounded-lg bg-gray-100 px-2">
            <div className="flex h-12 flex-row gap-2">
                <div
                    id="settings-button"
                    className="flex content-center"
                    onClick={() => setSettingsOpen(!settingsOpen)}
                >
                    <SettingsIcon className="my-auto h-6 w-6" />
                </div>
                <div id="query-input" className="flex content-center">
                    <SearchInput
                        localValue={localValue}
                        setLocalValue={setLocalValue}
                        selectedIndex={selectedIndex}
                        setFocused={setFocused}
                        setSelectedIndex={setSelectedIndex}
                        submitSearch={submitSearch}
                        onEnter={() => {
                            const str = filteredHistory[selectedIndex - 1];
                            setLocalValue(str);
                            submitSearch(str);
                            setFocused(false);
                        }}
                        onDelete={() => {
                            const str = filteredHistory[selectedIndex - 1];
                            const newSearchHistory = R.compose(R.without([str]))(searchHistory);
                            patchSettings({ searchHistory: newSearchHistory });
                        }}
                    />
                </div>
                <div
                    id="search-button"
                    className="flex content-center"
                    onClick={() => {
                        submitSearch(localValue);
                        setFocused(false);
                    }}
                >
                    <SearchIcon className="my-auto h-6 w-6" />
                </div>
                <VerticalSpacer />
                <div
                    id="bookmark-button"
                    className="flex content-center"
                    tooltip="Bookmark this query"
                    flow="down"
                    onClick={() => {
                        openBookmarkModal({ type: 'query', data: localValue });
                    }}
                >
                    <BookmarkIcon className="my-auto h-6 w-6" />
                </div>
            </div>
            {focused && filteredHistory.length > 0 ? (
                <>
                    <HorizontalSpacer />
                    <div className="flex max-h-80 flex-col overflow-y-scroll p-2 pt-0 transition-all">
                        {filteredHistory.map((str, i) => (
                            <Option
                                key={str}
                                selected={i + 1 === selectedIndex}
                                onPointerEnter={() => setSelectedIndex(i + 1)}
                                onPointerDown={() => {
                                    setLocalValue(str);
                                    setFocused(false);
                                    submitSearch(str);
                                }}
                            >
                                {str}
                            </Option>
                        ))}
                    </div>
                </>
            ) : (
                children
            )}
        </div>
    );
};

export const SearchBar = ({ stack, settings, setSettings, options }) => {
    const [settingsOpen, setSettingsOpen] = useState(false);
    return (
        <div id="search-bar" className="absolute mt-2 ml-2">
            <Bar stack={stack} settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen}>
                <Settings
                    settingsOpen={settingsOpen}
                    close={() => setSettingsOpen(false)}
                    settings={settings}
                    setSettings={setSettings}
                    options={options}
                />
            </Bar>
        </div>
    );
};
