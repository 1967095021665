import { isMetadataPath, isNodePath } from '@gothub-team/got-core';
import { reduceObj } from '@gothub-team/got-util';
import * as R from 'ramda';
import { useMemo, useRef } from 'react';

export const useGraphData = graph => {
    const simpleGraphRef = useRef({});
    const simpleGraph = useMemo(
        () =>
            R.over(
                R.lensProp('nodes'),
                R.map(node => (node ? { id: node.id } : false)),
            )(graph),
        [graph],
    );

    if (!R.equals(simpleGraphRef.current, simpleGraph)) {
        simpleGraphRef.current = simpleGraph;
    }

    const graphData = useMemo(
        () =>
            R.compose(
                R.mergeRight({ nodes: [], links: [] }),
                R.mergeAll,
                reduceObj([
                    [
                        (val, path) => val && isNodePath(path),
                        val =>
                            R.when(
                                R.always(val),
                                R.over(
                                    R.lensProp('nodes'),
                                    R.compose(R.append({ id: val.id }), R.defaultTo([])),
                                ),
                            ),
                    ],
                    [
                        (val, path) => val && isMetadataPath(path),
                        (val, [, fromType, source, toType, target]) =>
                            R.over(
                                R.lensProp('links'),
                                R.compose(
                                    R.append({
                                        fromType,
                                        source,
                                        toType,
                                        target,
                                    }),
                                    R.defaultTo([]),
                                ),
                            ),
                    ],
                ]),
            )(simpleGraphRef.current),
        [simpleGraphRef.current],
    );

    return graphData;
};
