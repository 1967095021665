import React from 'react';
import { Toggle } from '../../Basics/Toggle';
import { ComboBox } from '../../Basics/ComboBox';
import { SearchSelectBox } from '../../Basics/SearchSelectBox';
import { HorizontalSpacer } from '../../Basics/HorizontalSpacer';

export const Settings = ({ settingsOpen, close, setSettings, settings = {}, options = {} }) => (
    <div
        id="settings"
        className={`${settingsOpen ? '' : 'hidden'} flex flex-col gap-2 p-2 pt-0 transition-all`}
    >
        <HorizontalSpacer />
        <div className="">
            <Toggle
                value={settings.showLinkLabels}
                setValue={value => {
                    setSettings({ ...settings, showLinkLabels: value });
                }}
                label="Show types on edges"
            />
        </div>
        <div className="">
            <Toggle
                value={settings.enableForce}
                setValue={value => {
                    setSettings({ ...settings, enableForce: value });
                }}
                label="Enable force"
            />
        </div>
        <div className="">
            <Toggle
                value={settings.enableBloom}
                setValue={value => {
                    setSettings({ ...settings, enableBloom: value });
                }}
                label="Enable bloom"
            />
        </div>
        <div className="">
            <Toggle
                value={settings.enableParticles}
                setValue={value => {
                    setSettings({ ...settings, enableParticles: value });
                }}
                label="Enable particles"
            />
        </div>
        <div className="">
            <Toggle
                value={settings.enableFog}
                setValue={value => {
                    setSettings({ ...settings, enableFog: value });
                }}
                label="Enable fog"
            />
        </div>
        <div className="flex flex-col">
            <div>Node Label</div>
            <ComboBox
                value={settings.labelKey}
                setValue={option => setSettings({ ...settings, labelKey: option })}
                options={options.nodeProps}
            />
        </div>
        {/* <div className="flex flex-col">
            <div>Displayed data on Nodes</div>
            <SearchSelectBox
                value={settings.nodeDisplayKeys}
                setValue={option => setSettings({ ...settings, nodeDisplayKeys: option })}
                options={options.nodeProps}
            />
        </div> */}
        <div className="mb-0 w-full" onClick={close}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mx-auto h-4 w-4"
            >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
        </div>
    </div>
);
