import React from 'react';
import { Link } from 'react-router-dom';

export const LandingPageFooter = () => (
    <div className="footer">
        <div className="content">
            <div>
                <div className="section">
                    <h1>Developers</h1>
                    <p>
                        <a href="https://github.com/gothub-team/got" target="_blank" rel="noreferrer">
                            <code>got</code>
                            {' '}
                            SDK GitHub
                        </a>
                    </p>
                    <p>
                        <a href="https://api.gothub.io/api" target="_blank" rel="noreferrer">
                            <code>got</code>
                            {' '}
                            HTTP API
                        </a>
                    </p>
                </div>
            </div>
            <div>
                <div className="section">
                    <h1>Enterprise</h1>
                    <p><a href="./business">Software Development</a></p>
                    <p><a href="./business">Enterprise ERP Operations</a></p>
                    <p><a href="./business">Pricing</a></p>
                    {/* Links With better Navigation but dont scroll */}
                    {/* <p><Link to="./business">Software Development</Link></p>
                    <p><Link to="./business">Enterprise ERP Operations</Link></p>
                    <p><Link to="./business">Pricing</Link></p> */}
                </div>
            </div>
            <div>
                <div className="section">
                    <h1>Information</h1>
                    <p><Link to="./imprint">Imprint</Link></p>
                    <p><Link to="./privacy">Privacy</Link></p>
                    <p><a href="mailto:contact@gothub.io">Contact</a></p>
                </div>
            </div>
        </div>
    </div>
);
