/* eslint-disable no-shadow */
import React from 'react';
import InView from 'react-intersection-observer';
import procubionLogo from './Assets/pclogo.png';
import coachemLogo from './Assets/logo-coachem-club.png';
import { OpenSourceCard } from './OpenSourceCard';

export const B2BContent = () => (
    <>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>got is an all in one solution for</h1>
                        <div className="badges">
                            <div className="badge">
                                <ion-icon name="git-network-outline" />
                                <div>Database</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="key-outline" />
                                <div>Authentification</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="lock-open-outline" />
                                <div>Permissions System</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="attach-outline" />
                                <div>File Management</div>
                            </div>
                            <div className="badge">
                                <ion-icon name="logo-react" />
                                <div>React integration</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>Services</h1>
                        <div className="container">
                            <div className="badges horizontal">
                                <div className="badge large silent">
                                    <ion-icon name="server-outline" />
                                    <div>Hosting and maintenance of got instances</div>
                                </div>
                                <div className="badge large silent">
                                    <ion-icon name="code-slash-outline" />
                                    <div>Development of got based apps and systems</div>
                                </div>
                                <div className="badge large silent">
                                    <ion-icon name="people-outline" />
                                    <div>Consulting concerning got integration and development</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Why choose got?</h1>
                        <div className="container">
                            <div className="badges horizontal">
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>Quick prototyping and high agility</div>
                                </div>
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>Easy to use and learn</div>
                                </div>
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>Cutting edge frontend development</div>
                                </div>
                                <div className="badge small silent">
                                    <ion-icon name="ellipse-outline" />
                                    <div>Performance oriented React integration</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1>Projects powered by got</h1>
                        <div className="container parkett">
                            <div className="container horizontal">
                                <a href="https://www.coachem.club/" target="_blank" rel="noreferrer">
                                    <img src={coachemLogo} alt="CoachEm Logo" style={{ width: 275, border: 'none', padding: 10 }} />
                                </a>
                                <div className="badges horizontal">
                                    <div className="badge small silent">
                                        <div>
                                            CoachEm is a mobile and web app for physiotherapists and personal trainers to manage and create exercise regiments for their trainees.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <a href="https://www.procubion.com/" target="_blank" rel="noreferrer">
                                    <img src={procubionLogo} alt="procubion logo" style={{ width: 350, border: 'none' }} />
                                </a>
                                <div className="badges horizontal">
                                    <div className="badge small silent">
                                        <div>
                                            Procubion is a mobile and web app designed to simplify
                                            the process of managing projects and appointments.
                                            <br />
                                            <br />
                                            The tool allows users to easily log responsibles for tasks and export meeting minutes to pdf documents.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container horizontal">
                                <a href="/" target="_blank" rel="noreferrer">
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{
                                            fontSize: 75,
                                            fontWeight: 400,
                                            color: '#48439d',
                                            textAlign: 'center',
                                            cursor: 'default',
                                        }}
                                        >
                                            Bau
                                        </div>
                                        <div style={{
                                            fontSize: 75,
                                            fontWeight: 400,
                                            marginLeft: 12,
                                            color: '#00A6A6',
                                            textAlign: 'center',
                                            cursor: 'default',
                                        }}
                                        >
                                            IT
                                        </div>
                                    </div>
                                </a>
                                <div className="badges horizontal">
                                    <div className="badge small silent">
                                        <div>
                                            BauIT is a mobile and web app for companies in property and construction,
                                            designed to simplify the process of creating offers, invoices,
                                            partial invoices and the related marterial allowances.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <OpenSourceCard />
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card inverted appear' : 'card inverted'}>
                        <h1 id="pricing">Hosting Pricing</h1>
                        <div className="pricing">
                            {/* <div className="box">
                                        <ul>
                                            <li>
                                                <ion-icon name="checkmark" />
                                                <span>Only pay what you use</span>
                                            </li>
                                            <li>
                                                <ion-icon name="checkmark" />
                                                <span>Cancel any time</span>
                                            </li>
                                            <li>
                                                <ion-icon name="checkmark" />
                                                <span>Automatic downgrade</span>
                                            </li>
                                        </ul>
                                    </div> */}
                            <div className="box package">
                                <h2>Read User</h2>
                                <h3>
                                    <span className="success">1 €</span>
                                    &nbsp;
                                    per package per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        10000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>Write User</h2>
                                <h3>
                                    <span className="success">5 €</span>
                                    &nbsp;
                                    per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        1000 MB of storage
                                    </li>
                                    <li>
                                        <ion-icon name="push-outline" />
                                        4000 push actions per month
                                    </li>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        40000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>Additional Package</h2>
                                <h3>
                                    <span className="success">1 €</span>
                                    &nbsp;
                                    per package per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        250 MB of storage
                                    </li>
                                    <li>
                                        <ion-icon name="push-outline" />
                                        1000 push actions per month
                                    </li>
                                    <li>
                                        <ion-icon name="download-outline" />
                                        10000 pull actions per month
                                    </li>
                                </ul>
                            </div>
                            <div className="box package">
                                <h2>PDF Package</h2>
                                <h3>
                                    <span className="success">1 €</span>
                                    &nbsp;
                                    per package per month
                                </h3>
                                <ul>
                                    <li>
                                        <ion-icon name="server-outline" />
                                        100 Generated PDFs
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
        <InView threshold={0}>
            {({ inView, ref }) => (
                <div ref={ref} className="section" id="developers">
                    <div className={inView ? 'card appear' : 'card'}>
                        <h1>Service Pricing</h1>
                        <div className="pricing">
                            <div className="box package">
                                <h2>Development</h2>
                                <h3>
                                    <span className="success">80 €</span>
                                    &nbsp;
                                    per hour
                                </h3>
                            </div>
                            <div className="box package">
                                <h2>Consulting</h2>
                                <h3>
                                    <span className="success">100 €</span>
                                    &nbsp;
                                    per hour
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </InView>
    </>
);
