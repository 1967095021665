import { useEffect, useMemo } from 'react';
import * as R from 'ramda';
import { SETTINGS_BOOKMARKS_EDGE, SETTINGS_STACK } from '../got/consts';
import { createGraph } from '../got/hooks.config';
import { newId, newMsDate, sortBagsByOrder } from '../util/util';
import { useUserSettingsId } from './useUserNodeId';

const createBookmarksView = settingsId =>
    settingsId
        ? {
              [settingsId]: {
                  as: 'settings',
                  edges: {
                      [SETTINGS_BOOKMARKS_EDGE]: {
                          as: 'bookmarks',
                          include: {
                              edges: true,
                              metadata: true,
                              node: true,
                          },
                      },
                  },
              },
          }
        : {};

const selectBookmarks = R.compose(
    R.map(R.prop('node')),
    sortBagsByOrder,
    R.pathOr({}, ['settings', 'bookmarks']),
);

export const useBookmarks = () => {
    const { useView, pull } = createGraph(...SETTINGS_STACK);
    const userSettingsId = useUserSettingsId();
    const view = useMemo(() => createBookmarksView(userSettingsId), [userSettingsId]);
    const bookmarks = useView(view, selectBookmarks);

    const refresh = () => userSettingsId && pull(view);

    useEffect(() => {
        refresh();
    }, [userSettingsId]);

    return [bookmarks, refresh];
};

export const useBookmarksFns = () => {
    const { add, update, push, remove } = createGraph(...SETTINGS_STACK);
    const userSettingsId = useUserSettingsId();

    const addBookmark = (name, type, data) => {
        add(SETTINGS_BOOKMARKS_EDGE)(userSettingsId)(
            { id: newId(), name, type, data },
            { oder: newMsDate() },
        );
    };

    const removeBookmark = id => {
        remove(SETTINGS_BOOKMARKS_EDGE)(userSettingsId)({ id });
    };

    return {
        addBookmark,
        removeBookmark,
        updateBookmark: update,
        pushBookmarks: push,
    };
};
