/* eslint-disable curly */
/* eslint-disable nonblock-statement-body-position */
/* eslint-disable max-classes-per-file, no-plusplus */
import { Matrix4, Object3D, Vector3 } from 'three';

class CSS2DObject extends Object3D {
    constructor(element) {
        super();

        this.element = element || document.createElement('div');

        this.element.style.position = 'absolute';

        this.addEventListener('removed', () => {
            this.traverse(object => {
                if (object.element instanceof Element && object.element.parentNode !== null) {
                    object.element.parentNode.removeChild(object.element);
                }
            });
        });
    }

    copy(source, recursive) {
        super.copy(source, recursive);

        this.element = source.element.cloneNode(true);

        return this;
    }
}

CSS2DObject.prototype.isCSS2DObject = true;

//

const _vector = new Vector3();
const _viewMatrix = new Matrix4();
const _viewProjectionMatrix = new Matrix4();
const _a = new Vector3();
const _b = new Vector3();

class CSS2DRenderer {
    constructor() {
        const _this = this;

        let _width;
        let _height;
        let _widthHalf;
        let _heightHalf;
        const cache = {
            objects: new WeakMap(),
        };

        const domElement = document.createElement('div');
        domElement.style.overflow = 'hidden';

        this.domElement = domElement;

        this.getSize = () => ({
            width: _width,
            height: _height,
        });

        this.render = (scene, camera) => {
            if (scene.matrixWorldAutoUpdate === true) scene.updateMatrixWorld();
            if (camera.parent === null) camera.updateMatrixWorld();

            _viewMatrix.copy(camera.matrixWorldInverse);
            _viewProjectionMatrix.multiplyMatrices(camera.projectionMatrix, _viewMatrix);

            renderObject(scene, scene, camera);
            zOrder(scene);
        };

        this.setSize = (width, height) => {
            _width = width;
            _height = height;

            _widthHalf = _width / 2;
            _heightHalf = _height / 2;

            domElement.style.width = `${width}px`;
            domElement.style.height = `${height}px`;
        };

        function renderObject(object, scene, camera) {
            if (object.isCSS2DObject) {
                const nodePosition = new Vector3();
                nodePosition.setFromMatrixPosition(object.matrixWorld);
                object.onBeforeRender(_this, scene, camera);

                _vector.setFromMatrixPosition(object.matrixWorld);
                _vector.applyMatrix4(_viewProjectionMatrix);
                const zoom = Math.abs(12 / camera.position.distanceTo(nodePosition));

                const { element } = object;
                if (/apple/i.test(navigator.vendor)) {
                    // https://github.com/mrdoob/three.js/issues/21415
                    element.style.transform = `translate(-50%,-50%) translate(${Math.round(
                        _vector.x * _widthHalf + _widthHalf,
                    )}px,${Math.round(
                        -_vector.y * _heightHalf + _heightHalf,
                    )}px) scale(${zoom},${zoom})`;
                } else {
                    element.style.transform = `translate(-50%,-50%) translate(${
                        _vector.x * _widthHalf + _widthHalf
                    }px,${-_vector.y * _heightHalf + _heightHalf}px) scale(${zoom},${zoom})`;
                }
                if (zoom < 0.4)
                    element.style.filter = `blur(${parseFloat((0.5 - zoom) * 10).toFixed(1)}px)`;
                else element.style.filter = '';
                element.style.display =
                    object.visible && _vector.z >= -1 && _vector.z <= 1 ? '' : 'none';
                const objectData = {
                    distanceToCameraSquared: getDistanceToSquared(camera, object),
                };

                cache.objects.set(object, objectData);

                if (element.parentNode !== domElement) {
                    domElement.appendChild(element);
                }

                object.onAfterRender(_this, scene, camera);
            }

            for (let i = 0, l = object.children.length; i < l; i++) {
                renderObject(object.children[i], scene, camera);
            }
        }

        function getDistanceToSquared(object1, object2) {
            _a.setFromMatrixPosition(object1.matrixWorld);
            _b.setFromMatrixPosition(object2.matrixWorld);

            return _a.distanceToSquared(_b);
        }

        function filterAndFlatten(scene) {
            const result = [];

            scene.traverse(object => {
                if (object.isCSS2DObject) result.push(object);
            });

            return result;
        }

        function zOrder(scene) {
            const sorted = filterAndFlatten(scene).sort((a, b) => {
                const distanceA = cache.objects.get(a).distanceToCameraSquared;
                const distanceB = cache.objects.get(b).distanceToCameraSquared;

                return distanceA - distanceB;
            });

            const zMax = sorted.length;

            for (let i = 0, l = sorted.length; i < l; i++) {
                sorted[i].element.style.zIndex = zMax - i + 20;
            }
        }
    }
}

export { CSS2DObject, CSS2DRenderer };
