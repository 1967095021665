import React from 'react';
import { LandingPageFooter } from './Landingpages/LandingPageFooter';

const PrivacyPage = () => (
    <div className="landing-page">
        <div className="container">
            <div className="content">
                <h1>GotHub – Datenschutzerklärung</h1>
                <h2>Benennung der verantwortlichen Stelle</h2>
                <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                <p>GotHub UG (haftungsbeschränkt) </p>
                <p>Geschäftsführer: Samuel Vincenz Koop</p>
                <p>Adresse: Dunckerstraße 33, 10439 Berlin </p>
                <p>Telefon: +49 176 43862669</p>
                <p>
                    E-Mail:
                    <a href="mailto:info@gothub.io">info@gothub.io</a>
                </p>
                <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
                <h2>Kontoinformationen</h2>
                <p> Wir erheben Daten (wie Ihren Namen und Ihre E-Mail-Adresse, Telefonnummer, Zahlungsdaten und Postanschrift), die Sie uns zur Verfügung stellen, wenn Sie sich beispielsweise für ein Abonnement registrieren, ein Upgrade auf ein zahlungspflichtiges Paket vornehmen oder die Zwei-Faktor-Authentifizierung einrichten, und verknüpfen diese Daten mit Ihrem Konto.</p>
                <p>Je nach Ihrer Nutzung der GotHub-Angebote stellen Sie uns gegebenenfalls weitere Daten wie die Folgenden zur Verfügung:</p>
                <ul>
                    <li>ihren Namen, ihre E-Mail-Adresse, Anschrift, Telefonnummer und andere ähnliche Kontaktinformationen</li>
                    <li>Zahlungsinformationen, einschließlich Angaben zu Kreditkarte und Bankkonto</li>
                    <li>Informationen über Ihren Standort</li>
                    <li>
                        Angaben über Ihre Organisation und Ihre Kontakte, wie beispielsweise Kollegen oder
                        Mitarbeiter

                    </li>
                    <li>Ihrer Organisation</li>
                    <li>
                        Benutzernamen, Aliase, Rollen und andere Informationen zu Authentifizierungs- und
                        Sicherheitsanmeldeinformationen

                    </li>
                    <li>
                        Inhalte von Feedbacks, Stellungnahmen, Anfragen oder Supportanforderungen sowie
                        Telefongesprächen, Chat-Sessions oder E-Mails von uns oder an uns

                    </li>
                    <li>
                        Ihr Bild (unbewegtes Bild, Video oder in einigen Fällen 3-D-Bild), Ihre Stimme oder
                        sonstige persönliche Kennzeichen, wenn Sie an einer GotHub-Veranstaltung teilnehmen oder bestimmte GotHub-Angebote nutzen

                    </li>
                    <li>Angaben zu Ihrer Identität, einschließlich staatlicher Identifizierungsdokumente, Unternehmens- und Finanzinformationen und Umsatzsteuernummern und sonstige steuerlichen Kennungen</li>
                </ul>
                <h2>Ihre Dateien</h2>
                <p>Mit unserem Dienst stellen wir Ihnen die Möglichkeit zur Verfügung, Ihre Dateien aufzubewahren, mit anderen zusammenzuarbeiten und über mehrere Geräte hinweg zu arbeiten. Dabei bemühen wir uns, den Prozess so persönlich auf Ihre Bedürfnisse abzustimmen wie möglich. Zu diesem Zweck werden Ihre Inhalte sowie zugehörige Informationen von uns gespeichert, verarbeitet und übermittelt. Bei diesen zugehörigen Informationen handelt es sich unter anderem um ihre Profilinformationen, die Ihnen die Zusammenarbeit mit anderen Nutzern und das Teilen Ihrer Inhalte erleichtern, sowie um Informationen wie die Dateigröße, den Zeitpunkt, an dem eine Datei hochgeladen wurde, wer an Ihren Inhalten mitgearbeitet hat, und Nutzungsaktivitäten.</p>
                <h2>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
                <p>
                    Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
                    {' '}
                    <a href="https://www.bfdi.bund.de/DE/InfothekAnschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/InfothekAnschriften_Links/anschriften_links-node.html</a>
                    .
                </p>
                <h2>Recht auf Datenübertragbarkeit</h2>
                <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                <h2>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h2>
                <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
                <h2>SSL- bzw. TLS-Verschlüsselung</h2>
                <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS- Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
                <h2>Cookies</h2>
                <p>Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Einige Cookies sind “Session- Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen. Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben. Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste. Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser Datenschutzerklärung separat behandelt.</p>
                <h2>Server-Log-Dateien</h2>
                <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind: </p>
                <ul>
                    <li>Besuchte Seite auf unserer Domain </li>
                    <li>Datum und Uhrzeit der Serveranfrage </li>
                    <li>Browsertyp und Browserversion </li>
                    <li>Verwendetes Betriebssystem </li>
                    <li>Referrer URL </li>
                    <li>Hostname des zugreifenden Rechners </li>
                    <li>IP-Adresse </li>
                </ul>
                <p>Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
                <h2>Kontaktformular</h2>
                <p>Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt. Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
                <h2>Recht und Ordnung sowie öffentliches Interesse</h2>
                <p>Wir können Ihre Daten auch Dritten offenlegen, wenn dies nach unserem Ermessen sinnvoll und notwendig scheint, um (a) geltenden Gesetzen, Vorschriften, rechtlichen Verfahren oder angemessenen Anfragen von Behörden Folge zu leisten, (b) einen Menschen vor dem Tod oder schwerer körperlicher Verletzung zu schützen, (c) GotHub oder unsere Nutzer vor Betrug oder Missbrauch zu schützen, (d) die Rechte, das Eigentum, die Sicherheit oder die Interessen von GotHub zu schützen oder (e) Aufgaben auszuführen, die im öffentlichen Interesse liegen.</p>
                <p>Wir nehmen den Schutz Ihrer Daten sehr ernst und stellen uns der damit einhergehenden Verantwortung. Wir glauben, dass Ihre Daten, die in unseren Diensten gespeichert sind, genau denselben rechtlichen Schutz erhalten sollen wie diejenigen, die auf Ihrer Festplatte zu Hause gespeichert sind. Deshalb richten wir uns bei der Entgegennahme, Prüfung und Beantwortung behördlicher Anfragen (einschließlich Anfragen im Zusammenhang mit der nationalen Sicherheit) bezüglich Ihrer Daten nach den folgenden Richtlinien zu behördlichen Anfragen:</p>
                <ul>
                    <li>Transparent sein</li>
                    <li>Pauschale Anfragen abwehren</li>
                    <li>Alle unserer Nutzer schützen, und</li>
                    <li>Vertrauenswürdiger Dienste bereitstellen</li>
                </ul>
                <p>Als Teil unserer Bestrebungen, Sie darüber zu informieren, wann und wie Behörden Informationen von uns verlangen, veröffentlichen wir einen Transparenzbericht. Dieser Bericht führt die Art und Anzahl der Anfragen auf, die wir von den Strafverfolgungsbehörden und sonstigen öffentlichen Stellen erhalten.</p>
                <h2>Kontrollfunktionen für Nutzer</h2>
                <p>Sie können auf Ihre persönlichen Informationen zugreifen, sie bearbeiten, herunterladen und löschen, indem Sie sich bei Ihrem GotHub-Konto anmelden und die Seite mit Ihren Kontoeinstellungen aufrufen.</p>
                <h2>Aufbewahrung</h2>
                <p>Wenn Sie sich für ein Konto bei uns registrieren, bewahren wir Informationen, die Sie auf unseren Diensten speichern, solange auf, wie Ihr Konto existiert oder wir sie brauchen, um Ihnen die Dienste bereitstellen zu können. Wenn Sie Ihr Konto löschen, leiten wir die Löschung dieser Informationen nach 30 Tagen ein. Hier erfahren Sie mehr. Bitte beachten Sie: (1) Es kann etwas dauern, bis diese Informationen von unseren Servern und aus unserem Backup gelöscht sind und (2) eventuell bewahren wir diese Informationen auf, soweit dies erforderlich ist, um unsere rechtlichen Verpflichtungen zu erfüllen, Streitigkeiten beizulegen oder unsere Verträge durchzusetzen.</p>
                <h2>Änderungen</h2>
                <p>Ihre Daten können im Rahmen einer eventuellen Umstrukturierung, eine Fusion, einer Übernahme oder eines Verkaufs unserer Vermögenswerte übertragen werden. In diesem Fall wird GotHub Sie davon zum Beispiel per E-Mail an die Adresse, die mit Ihrem Konto verknüpft ist, in Kenntnis setzen und all Ihre Optionen erläutern. Wir behalten uns das Recht vor, diese Datenschutzerklärung von Zeit zu Zeit zu ändern. Die aktuelle Version ist auf unserer Webseite abrufbar. Sollte eine Änderung Ihre Rechte wesentlich einschränken, werden wir Sie benachrichtigen.</p>
                <h2>Kontaktaufnahme</h2>
                <p>Haben Sie Fragen zu GotHub, zu unseren Diensten und zum Datenschutz? Schreiben Sie unserem Datenschutzbeauftragten unter privacy-berndy@gothub.io. Wenn Ihre Frage nicht beantwortet werden kann, sind Sie berechtigt, sich an Ihre lokale Datenschutzaufsichtsbehörde zu wenden.</p>

            </div>
        </div>
        <LandingPageFooter />
    </div>
);

export default PrivacyPage;
