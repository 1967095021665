import { useEffect, useRef, useState } from 'react';

export const useDrag = (fnEvalDragEvent = () => true) => {
    const ref = useRef();
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        if (ref.current) {
            const timerRef = { current: undefined };

            const onDragOver = event => {
                const isValidDragEvent = fnEvalDragEvent(event);
                if (isValidDragEvent) {
                    setDragging(true);
                } else if (!timerRef.current) {
                    setDragging(false);
                }
            };

            const onDragExit = () => {
                setDragging(false);
            };

            const onPointerLeave = () => {
                setDragging(false);
            };

            const element = ref.current;
            element.addEventListener('dragover', onDragOver);
            element.addEventListener('dragleave', onDragExit);
            element.addEventListener('drop', onDragExit);
            element.addEventListener('pointerleave', onPointerLeave);

            return () => {
                element.removeEventListener('dragover', onDragOver);
                element.removeEventListener('dragleave', onDragExit);
                element.removeEventListener('drop', onDragExit);
                element.removeEventListener('pointerleave', onPointerLeave);
            };
        }

        return () => {};
    }, [ref]);

    return [ref, dragging];
};
