import { createGraph, gotStore } from '../got/hooks.config';
import {
    getUserNodeId,
    getUserScopesId,
    getUserSettingsId,
    getUserViewsId,
    useUserNodeId,
    useUserSettingsId,
} from './useUserNodeId';
import {
    MAIN,
    SCOPES_SCOPE_EDGE,
    SCOPES_STACK,
    SETTINGS_STACK,
    TEMP,
    VIEWS_STACK,
    VIEWS_VIEW_EDGE,
} from '../got/consts';

export const initNode = async (stack, nodeId, edge, initialNode = {}) => {
    try {
        if (nodeId) {
            const { pull, update, push } = createGraph(...stack);

            await pull({
                [nodeId]: {
                    include: { node: true },
                    ...(edge
                        ? {
                              edges: { [edge]: { include: { node: true, edges: true } } },
                          }
                        : {}),
                },
            });

            if (!gotStore.getNode(...stack)(nodeId)) {
                update({ ...initialNode, id: nodeId });
                await push();
            }
            return true;
        }

        throw new Error('nodeId is undefined');
    } catch (err) {
        console.error(err);
        return false;
    }
};

export const initUserNode = () => initNode([MAIN, TEMP], getUserNodeId());
export const useUserNode = (stack, selector) => {
    const { useNode, update, push } = createGraph(...stack);

    const userNodeId = useUserNodeId();

    const fragment = useNode(userNodeId, selector);

    const patchNode = patch => {
        update({ ...patch, id: userNodeId });
        push();
    };

    return [fragment, patchNode];
};

export const initUserSettings = () => initNode(SETTINGS_STACK, getUserSettingsId());

export const useUserSettings = selector => {
    const { useNode, update, push } = createGraph(...SETTINGS_STACK);

    const userSettingsId = useUserSettingsId();

    const fragment = useNode(userSettingsId, selector);

    const patchNode = patch => {
        update({ ...patch, id: userSettingsId });
        push();
    };

    return [fragment, patchNode];
};

export const initUserViews = () => initNode(VIEWS_STACK, getUserViewsId(), VIEWS_VIEW_EDGE);
export const initUserScopes = () => initNode(SCOPES_STACK, getUserScopesId(), SCOPES_SCOPE_EDGE);

export const initUserNodes = () =>
    Promise.all([initUserNode(), initUserSettings(), initUserViews(), initUserScopes()]);
