import * as R from 'ramda';
import React, { useState, useRef } from 'react';
import { ComponentDragging, EdgeTypes, EditMode, EditPath, ViewState } from './state';
import { CancelIcon, DeleteIcon, EditIcon, ReadIcon } from './Icons';
import { insertAtPath, movePaths, removeFromPath } from './common';
import { useDrag } from '../../../hooks/useDrag';
import { useAtom, useCreateAtom } from './hooks';
import { DropDownBehavior, DropDownOptions } from '../../Basics/DropDown';

export const ViewLabel = ({ path }) => {
    const editPath = useAtom(EditPath);
    const viewState = useAtom(ViewState);
    const editMode = useAtom(EditMode) && R.equals(path, editPath);

    const assocPropPath = (prop, value) => R.assocPath(R.append(prop, path), value);
    const propPath = prop => R.pathOr('', R.append(prop, path), viewState);

    if (editMode) {
        const [fromType, toType] = propPath('edge').split('/');
        const setFromType = _fromType =>
            ViewState.set(assocPropPath('edge', `${_fromType}/${toType}`));
        const setToType = _toType => ViewState.set(assocPropPath('edge', `${fromType}/${_toType}`));
        return (
            <div className="flex flex-1 flex-row items-center justify-between text-sm font-medium">
                <div className="flex flex-row gap-2">
                    <div className="flex flex-row">
                        <div
                            className="highlight-input flex w-auto min-w-[4rem] rounded-md pl-1 pr-1"
                            onBlur={e =>
                                ViewState.set(assocPropPath('label', e.currentTarget.textContent))
                            }
                            contentEditable="plaintext-only"
                            suppressContentEditableWarning
                            tabIndex="0"
                        >
                            {propPath('label')}
                        </div>
                        <CancelIcon
                            className="h-5 w-5 cursor-pointer stroke-[1.5] text-got-highlight-second opacity-40"
                            onClick={() => ViewState.set(assocPropPath('label', undefined))}
                        />
                    </div>
                    {propPath('edge') ? (
                        <div className="highlight-input flex w-auto cursor-default flex-row gap-1 rounded-md">
                            <EdgeTypeDropDown
                                className={`highlight-input-nested ${
                                    fromType ? '' : 'min-w-[4rem]'
                                } flex w-auto rounded-md pl-1 pr-1`}
                                edgeType={fromType}
                                setEdgeType={setFromType}
                            />
                            <span className="cursor-default">{'>'}</span>
                            <EdgeTypeDropDown
                                className={`highlight-input-nested ${
                                    fromType ? '' : 'min-w-[4rem]'
                                } flex w-auto rounded-md pl-1 pr-1`}
                                edgeType={toType}
                                setEdgeType={setToType}
                            />
                        </div>
                    ) : (
                        <div className="flex flex-row">
                            <div
                                className="highlight-input flex w-auto min-w-[4rem] rounded-md pl-1 pr-1"
                                onBlur={e =>
                                    ViewState.set(
                                        assocPropPath('name', e.currentTarget.textContent),
                                    )
                                }
                                contentEditable="plaintext-only"
                                suppressContentEditableWarning
                                tabIndex="0"
                            >
                                {propPath('name')}
                            </div>
                            <CancelIcon
                                className="h-5 w-5 cursor-pointer stroke-[1.5] text-got-highlight-second opacity-40"
                                onClick={() => ViewState.set(assocPropPath('name', undefined))}
                            />
                        </div>
                    )}
                </div>
                <div className="flex flex-row gap-2">
                    <ViewReadonlyToggle path={path} />
                    <ViewDeleteButton path={path} />
                    <ViewDragHandle path={path} />
                </div>
            </div>
        );
    }
    return (
        <div
            className={`flex ${
                propPath('label') ? 'h-5' : 'h-0'
            } w-full flex-1 flex-row items-center justify-between text-sm font-medium`}
            onClick={() => EditPath.set(path)}
        >
            <label htmlFor={propPath('name')} className="pl-1 pr-1">
                {`${propPath('label')}`}
            </label>
        </div>
    );
};

const ViewReadonlyToggle = ({ path }) => {
    const viewState = useAtom(ViewState);

    const assocPropPath = (prop, value) => R.assocPath(R.append(prop, path), value);
    const propPath = prop => R.pathOr('', R.append(prop, path), viewState);
    return (
        <div
            className="highlight-input flex w-auto cursor-pointer rounded-md pl-1 pr-1"
            onClick={() => ViewState.set(assocPropPath('readonly', !propPath('readonly')))}
        >
            <div className="has-tooltip">
                <span className="tooltip -top-0.5 rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                    Allow read only
                </span>
                <ReadIcon
                    className={`mr-1 h-5 w-5 stroke-[1.5] text-got-highlight-second-light ${
                        !propPath('readonly') && 'opacity-40'
                    }`}
                />
            </div>
            <div className="has-tooltip">
                <span className="tooltip -top-0.5 rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                    Allow write
                </span>
                <EditIcon
                    className={`ml-1 h-5 w-5 stroke-[1.5] text-got-highlight-second-light ${
                        propPath('readonly') && 'opacity-40'
                    }`}
                />
            </div>
        </div>
    );
};

const ViewDeleteButton = ({ path }) => {
    const parentPath = R.dropLast(1, path);
    const index = R.last(path);
    return (
        <div className="has-tooltip">
            <span className="tooltip -top-0.5 rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                Delete Component
            </span>
            <div className="highlight-input flex w-auto cursor-pointer select-none rounded-md pl-1 pr-1">
                <DeleteIcon
                    onClick={() => ViewState.set(removeFromPath(parentPath)(index))}
                    className="h-5 w-5 stroke-[1.5] opacity-40 hover:opacity-70"
                />
            </div>
        </div>
    );
};

const ViewDragHandle = ({ path }) => (
    <div className="has-tooltip">
        <span className="tooltip right -top-0.5 rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
            Move Component
        </span>
        <div
            className="highlight-input flex w-auto cursor-grab select-none rounded-md pl-1 pr-1"
            onDragStart={e => {
                ComponentDragging.set(true);
                e.dataTransfer.setData('path', JSON.stringify(path));
                e.stopPropagation();
            }}
            onDragEnd={() => ComponentDragging.set(false)}
            draggable
        >
            ⁖⁖⁖
        </div>
    </div>
);

const EdgeTypeOptions = DropDownOptions(({ children, selected, onPointerEnter, onPointerDown }) => (
    <div
        className={`${
            selected ? 'dark:bg-got-highlight-second-shaded-intense' : ''
        } flex cursor-pointer flex-row gap-2 p-1`}
        onPointerEnter={onPointerEnter}
        onPointerDown={onPointerDown}
    >
        {children}
    </div>
));
export const EdgeTypeDropDown = ({ className, edgeType, setEdgeType }) => {
    const inputDiv = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const FilteredOptions = useCreateAtom(EdgeTypes.get());
    const SelectedIndex = useCreateAtom(-1);
    const setSelectedIndex = i =>
        SelectedIndex.set(
            DropDownBehavior.circulatedIndex(FilteredOptions.get().length + 1, -1)(i),
        );
    const filterOptions = str => {
        FilteredOptions.set(DropDownBehavior.filterOptionsIncludes(str)(EdgeTypes.get()));
    };

    return (
        <div className="relative">
            <div
                ref={inputDiv}
                className={`${className}`}
                contentEditable="plaintext-only"
                suppressContentEditableWarning
                tabIndex="0"
                onFocus={() => setIsOpen(true)}
                onInput={e => {
                    filterOptions(e.currentTarget.textContent);
                    setSelectedIndex(-1);
                }}
                onBlur={e => {
                    setIsOpen(false);
                    setSelectedIndex(-1);
                    filterOptions(e.currentTarget.textContent);
                    setEdgeType && setEdgeType(e.currentTarget.textContent);
                }}
                onKeyDown={DropDownBehavior.keyboard({
                    onArrowUp: () => setSelectedIndex(SelectedIndex.get() - 1),
                    onArrowDown: () => setSelectedIndex(SelectedIndex.get() + 1),
                    onEnter: e => {
                        const selectedIndex = SelectedIndex.get();
                        if (selectedIndex >= 0) {
                            e.currentTarget.textContent = FilteredOptions.get()[selectedIndex];
                        }
                        e.currentTarget.blur();
                    },
                    onEscape: e => {
                        e.currentTarget.blur();
                    },
                })}
            >
                {edgeType}
            </div>
            {isOpen && (
                <EdgeTypeOptions
                    className="highlight-input -bottom-1 mt-2 flex max-h-40 w-auto cursor-default flex-row gap-1 rounded-md"
                    Options={FilteredOptions}
                    SelectedIndex={SelectedIndex}
                    onHover={i => setSelectedIndex(i)}
                    onSelect={i => {
                        inputDiv.current.textContent = FilteredOptions.get()[i];
                        inputDiv.current.blur();
                    }}
                />
            )}
        </div>
    );
};

export const ViewEditorChildButtons = ({ path }) => {
    const editPath = useAtom(EditPath);
    const editMode = R.equals(path, editPath);

    return editMode ? (
        <CancelIcon
            onClick={() => EditPath.set(null)}
            className="h-6 w-6 stroke-[1] opacity-40 hover:opacity-70 dark:stroke-got-light"
        />
    ) : (
        <div className="has-tooltip">
            <span className="tooltip left -top-0.5 rounded-lg bg-got-dark-grey p-2 text-sm text-got-light">
                Edit Component
            </span>
            <EditIcon
                onClick={() => EditPath.set(path)}
                className="h-6 w-6 opacity-40 hover:opacity-70 dark:stroke-got-light"
            />
        </div>
    );
};

const isComponentDragEvent = event =>
    event.dataTransfer.types.includes('component') || event.dataTransfer.types.includes('path');

export const DropZone = ({ path: targetPath, index }) => {
    const [ref, draggedOver] = useDrag(isComponentDragEvent);
    const componentDragging = useAtom(ComponentDragging);

    const onDrop = e => {
        const sourcePath = JSON.parse(e.dataTransfer.getData('path') || 'null');

        if (sourcePath) {
            ViewState.set(R.compose(movePaths(sourcePath, [...targetPath, index])));
            EditPath.set(null);
        } else {
            const component = JSON.parse(e.dataTransfer.getData('component') || 'null');
            ViewState.set(insertAtPath(targetPath, index)(component));
            EditPath.set([...targetPath, index]);
        }
    };

    return (
        <div id="drop-zone" className="relative w-full">
            <div
                className={`absolute w-full rounded-lg ${
                    draggedOver ? 'h-1 bg-got-highlight-second' : 'h-0 bg-none'
                }`}
            />
            <div
                ref={ref}
                className={`${
                    !componentDragging && 'pointer-events-none'
                } absolute z-20 h-8 w-full -translate-y-1/2`}
                onDragOver={e => e.preventDefault()}
                onDrop={e => onDrop(e)}
            />
        </div>
    );
};
